import React, { FC, useContext } from 'react';
import { Context } from '@/store/context';
import { Button, ButtonIcon, Li, List } from './styles';

export const CoinBuy: FC = () => {
  const { buy } = useContext(Context);

  return (
    <List aria-label="Buy LFI">
      {buy.items.map((item) => (
        <Li key={item.name}>
          <Button
            tag="a"
            href={item.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonIcon src={item.icon} alt={item.name} />
            {item.name}
          </Button>
        </Li>
      ))}
    </List>
  );
};
