import React, { forwardRef, useContext, useRef } from 'react';
import { childOf } from 'vevet-dom';
import { useStoreAPI } from '@/store/api';
import { Context } from '@/store/context';
import { LayoutMenu } from '../menu';
import {
  ChildrenWrapper,
  CloseButton,
  Container,
  LinksWrapper,
  Modal,
  ScrollContainer,
  Wrapper,
} from './styles';
import { TProps } from './types';

export const LayoutMenuModal = forwardRef<HTMLDivElement, TProps>(
  ({ isForceShow, links, children, ...tagProps }, ref) => {
    const ctx = useContext(Context);
    const containerRef = useRef<HTMLDivElement>(null);
    const { shown } = ctx.system.navigationModal;
    const api = useStoreAPI();
    const isShown = !!(shown || isForceShow);

    return (
      <Modal
        ref={ref}
        {...tagProps}
        onClick={(e) => {
          if (!childOf(e.target as any, containerRef.current!)) {
            api.toggleNavigationModal(false);
          }
        }}
        isShown={isShown}
        aria-hidden
      >
        <ScrollContainer>
          <Container ref={containerRef} isShown={isShown}>
            <Wrapper>
              <LinksWrapper>
                <LayoutMenu links={links} />
              </LinksWrapper>
              {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
            </Wrapper>
          </Container>
        </ScrollContainer>
        <CloseButton
          type="button"
          aria-label="Close navigation"
          onClick={() => {
            api.toggleNavigationModal(false);
          }}
        />
      </Modal>
    );
  }
);
LayoutMenuModal.displayName = 'LayoutMenuModal';
