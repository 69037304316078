import styled, { css } from 'styled-components';
import { ISharedProps } from './types';

export const Label = styled.div<ISharedProps>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black);

  font-weight: 500;
  color: var(--color-white);
  letter-spacing: 0;

  ${({ size }) =>
    size === 'large' &&
    css`
      border-radius: 4px;
      padding: 2px 6px;
      font-size: 12px;
      line-height: 20px;

      > * {
        + * {
          margin-left: 4px;
        }
      }
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      border-radius: 4px;
      padding: 4px 5px;
      font-size: 8px;
      line-height: 9px;

      > * {
        + * {
          margin-left: 3px;
        }
      }
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      border-radius: 2px;
      padding: 4px 3px;
      font-size: 8px;
      line-height: 6px;

      > * {
        + * {
          margin-left: 3px;
        }
      }
    `}
`;

export const Image = styled.img<ISharedProps>`
  width: auto;

  ${({ size }) =>
    size === 'large' &&
    css`
      height: 12px;
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      height: 10px;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      height: 7px;
    `}
`;
