import React, { FC, useContext } from 'react';
import { LinkExplore } from '@/general/links/explore';
import { ButtonCopy } from '@/general/button/copy';
import { Context } from '@/store/context';
import { CoinBuy } from '@/composed/coin/buy';
import {
  BuyWrapper,
  Container,
  DisconnectButton,
  LinksWrapper,
  Title,
  UserAddress,
  UserImg,
  UserInfo,
  UserNetwork,
  UserRow,
  UserWrapper,
  Wrapper,
} from './styles';

export const ConnectUserInfo: FC = () => {
  const { address, network, disconnectCallback, explorerHref } =
    useContext(Context).user;

  const shortAddress =
    address.length > 10
      ? `${address.slice(0, 6)}..${address.slice(-4)}`
      : address;

  return (
    <Container>
      <Wrapper>
        <Title>Account</Title>

        <UserWrapper>
          <UserRow>
            <UserImg />
            <UserInfo>
              <UserAddress>{shortAddress}</UserAddress>
              <UserNetwork>{network}</UserNetwork>
            </UserInfo>
          </UserRow>
          {disconnectCallback && (
            <DisconnectButton tag="button" onClick={() => disconnectCallback()}>
              Disconnect
            </DisconnectButton>
          )}
        </UserWrapper>

        <LinksWrapper>
          {explorerHref && (
            <LinkExplore
              href={explorerHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              View on Explorer
            </LinkExplore>
          )}
          <ButtonCopy text={address}>Copy Address</ButtonCopy>
        </LinksWrapper>
      </Wrapper>
      <BuyWrapper>
        <CoinBuy />
      </BuyWrapper>
    </Container>
  );
};
