import styled, { css } from 'styled-components';
import { IContainerProps, ISpinnerProps } from './types';

export const Container = styled.div<IContainerProps>`
  position: absolute;
  z-index: 1;
  inset: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s;

  ${({ isShown }) =>
    isShown &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export const SpinnerWrapper = styled.div<ISpinnerProps>`
  position: relative;
  width: 80px;
  height: 80px;

  transition: opacity 0.5s, transform 0.5s;

  ${({ isShown }) =>
    !isShown &&
    css`
      opacity: 0;
      transform: scale(0.25);
    `}
`;

export const FireworkWrapper = styled.div`
  position: absolute;
  inset: 0;

  > * {
    &:nth-child(1) {
      bottom: 50%;
      right: 50%;
    }

    &:nth-child(2) {
      bottom: 50%;
      left: 50%;
      transform: scaleX(-1);
    }

    &:nth-child(3) {
      top: 50%;
      right: 50%;
      transform: scaleX(-1) rotate(180deg);
    }

    &:nth-child(4) {
      top: 50%;
      left: 50%;
      transform: rotate(180deg);
    }
  }
`;
