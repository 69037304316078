import React, { forwardRef } from 'react';
import { Popup } from '../popup';
import { IPopupHandle } from '../popup/types';
import styles from './index.module.scss';
import { ITooltipProps } from './types';

export const Tooltip = forwardRef<IPopupHandle, ITooltipProps>(
  ({ children, className, useTextStyles = true, ...popupProps }, ref) => (
    <Popup
      ref={ref}
      gap={0}
      direction="auto"
      alignment="center"
      {...popupProps}
      useStyles={false}
      className={[className, styles.tooltip].join(' ')}
    >
      {useTextStyles ? (
        <div className={styles.content}>{children}</div>
      ) : (
        children
      )}
    </Popup>
  )
);
