import styled, { css } from 'styled-components';
import { ButtonBase } from '../__base/Base';
import { TButtonProps } from './types';

export const Button = styled(ButtonBase)<TButtonProps>`
  position: relative;
  padding: 4px 32px;

  transition: opacity 0.25s, color 0.5s;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  color: var(--button-color-text);
  ${({ isHovered }) =>
    isHovered &&
    css`
      color: var(--button-color-text-hover);
    `}
`;

export const Content = styled.span`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
