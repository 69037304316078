import React, { forwardRef } from 'react';
import { NumberFormat } from '../format';
import { IProps } from './types';
import { Layout, Icon } from './styles';

export const NumberPercent = forwardRef<HTMLDivElement, IProps>(
  ({ className, style, value }, ref) => (
    <Layout
      ref={ref}
      className={className}
      style={style}
      isNegative={value < 0}
      isPositive={value > 0}
    >
      <Icon />
      <span>
        <NumberFormat value={value ?? 0} rightFixed={1} suffix="%" />
      </span>
    </Layout>
  )
);

NumberPercent.displayName = 'NumberPercent';
