import styled from 'styled-components';

export const Container = styled.span`
  position: relative;
  white-space: nowrap;
`;

export const StaticWrapper = styled.span`
  position: relative;
  opacity: 0;
`;

export const DynamicWrapper = styled.span`
  position: absolute;
  top: 0;
  left: 0;
`;
