import React, { FC } from 'react';
import { NumberFormat } from '@/general/number/format';
import { NumberCounter } from '@/general/number/counter';
import { IProps } from './types';
import { Color, Layout, Name, Value } from './styles';

export const Anchor: FC<IProps> = ({
  className,
  style,
  onMouseEnter,
  onMouseLeave,
  isAppearAnimation,
  name,
  hex,
  value,
  numberFormat,
  isUnactive,
}) => (
  <Layout
    className={className}
    style={style}
    onMouseEnter={() => onMouseEnter?.()}
    onMouseLeave={() => onMouseLeave?.()}
    isUnactive={isUnactive}
  >
    <Name>
      <Color style={{ backgroundColor: `#${hex.replace('#', '')}` }} />
      <span>{name}</span>
    </Name>
    <Value>
      {typeof isAppearAnimation === 'boolean' ? (
        <NumberCounter
          animation={isAppearAnimation}
          prefix="$"
          value={value}
          {...numberFormat}
        />
      ) : (
        <NumberFormat prefix="$" value={value} {...numberFormat} />
      )}
    </Value>
  </Layout>
);
