type TDeviceType =
  | 'xs'
  | 'sm'
  | 'smMin'
  | 'smMax'
  | 'md'
  | 'mdMin'
  | 'mdMax'
  | 'lg'
  | 'lgMin'
  | 'lgMax'
  | 'xl'
  | 'xlMin'
  | 'xlMax'
  | 'xxl';

interface IRange {
  max: number;
  min: number;
}

const DEVICE_CONSTRAINTS: Record<TDeviceType, IRange> = {
  xs: {
    min: 0,
    max: 743,
  },
  sm: {
    min: 744,
    max: 899,
  },
  smMin: {
    min: 744,
    max: Infinity,
  },
  smMax: {
    min: 0,
    max: 899,
  },
  md: {
    min: 900,
    max: 1023,
  },
  mdMin: {
    min: 900,
    max: Infinity,
  },
  mdMax: {
    min: 0,
    max: 1023,
  },
  lg: {
    min: 1024,
    max: 1199,
  },
  lgMin: {
    min: 1024,
    max: Infinity,
  },
  lgMax: {
    min: 0,
    max: 1199,
  },
  xl: {
    min: 1200,
    max: 1499,
  },
  xlMin: {
    min: 1200,
    max: Infinity,
  },
  xlMax: {
    min: 0,
    max: 1499,
  },
  xxl: {
    min: 1500,
    max: Infinity,
  },
};

function getRangeConstraints({ min, max }: IRange): string {
  const constraints = ['screen'];

  if (Number.isFinite(min)) {
    constraints.push(`(min-width: ${min}px)`);
  }

  if (Number.isFinite(max)) {
    constraints.push(`(max-width: ${max}px)`);
  }

  return constraints.join(' and ');
}

export function getMediaQuery(...deviceTypes: TDeviceType[]): string {
  let resultMin = Infinity;
  let resultMax = -Infinity;

  deviceTypes.forEach((type) => {
    const {
      [type]: { min, max },
    } = DEVICE_CONSTRAINTS;

    resultMin = Math.min(min, resultMin);
    resultMax = Math.max(max, resultMax);
  });

  return getRangeConstraints({ max: resultMax, min: resultMin });
}
