import styled, { keyframes } from 'styled-components';

export const SvgElement = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const loadingOutlineKeyframes = keyframes`
  from {
    stroke-dasharray: 0, var(--button-outline-length);
    stroke-dashoffset: var(--button-outline-length);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  to {
    stroke-dasharray: var(--button-outline-length), 0;
    stroke-dashoffset: 0;
    opacity: 0;
  }
`;

export const LoadingOutline = styled.rect`
  animation-name: ${loadingOutlineKeyframes};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
`;
