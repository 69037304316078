import React, { FC, useId, useState, useContext, useEffect } from 'react';
import { usePrevious } from '@/utils/usePrevious';
import { Context } from '@/store/context';
import { Info } from './info/Info';
import {
  Container,
  Description,
  Form,
  Header,
  InfoWrapper,
  LabelledCheckbox,
  PolicyText,
  PolicyWrapper,
  Submit,
} from './styles';
import { IProps } from './types';
import { Input } from './input';
import { Loading } from './loading';
import { Success } from './success';
import { Confirm } from './confirm';

export const FormAmount: FC<IProps> = ({
  status = 'default',
  header,
  input,
  info,
  submit,
  description,
  confirm,
  success,
}) => {
  const id = useId();
  const [isAcceptPolicy, setIsAcceptPolicy] = useState(false);
  const prevStatus = usePrevious(status);

  const [isShowSuccess, setIsShowSuccess] = useState(status === 'success');
  useEffect(() => {
    let timeout: any;

    if (prevStatus === 'success' && status !== 'success') {
      timeout = setTimeout(() => {
        setIsShowSuccess(false);
      }, 1500);
    } else if (status === 'success') {
      timeout = setTimeout(() => {
        setIsShowSuccess(status === 'success');
      }, 500);
    } else {
      setIsShowSuccess(false);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [status, prevStatus]);

  const ctx = useContext(Context);
  const { links } = ctx;

  return (
    <Container>
      <Form
        isHidden={status !== 'default' || isShowSuccess}
        method="post"
        action=""
        onSubmit={(e) => {
          e.preventDefault();
          if (!submit?.isDisabled) {
            submit?.onSubmit();
          }
        }}
      >
        {header && <Header>{header}</Header>}

        {input && <Input {...input} />}

        {info && info.length > 0 && (
          <InfoWrapper>
            {info.map((item) => (
              <Info key={item.name} {...item} />
            ))}
          </InfoWrapper>
        )}

        <PolicyWrapper>
          <PolicyText>
            Accept the{' '}
            <a href={links.privacy} target="_blank" rel="noopener noreferrer">
              Terms & Conditions
            </a>
          </PolicyText>
          <LabelledCheckbox
            name={`${id}-accept`}
            id={`${id}-accept`}
            label="I have read and accept"
            checked={isAcceptPolicy}
            onChange={(e) => {
              setIsAcceptPolicy(e.target.checked);
            }}
          />
        </PolicyWrapper>

        {submit && (
          <Submit
            tag="button"
            type="submit"
            disabled={!isAcceptPolicy || submit.isDisabled}
          >
            {submit.name}
          </Submit>
        )}

        {description && <Description>{description}</Description>}
      </Form>

      <Loading status={status} />

      {confirm && <Confirm {...confirm} isShown={status === 'confirm'} />}

      {!!success && isShowSuccess && (
        <Success {...success} isShown={status === 'success'} />
      )}
    </Container>
  );
};
