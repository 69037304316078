import React, { FC, useContext } from 'react';
import { NumberFormat } from '@/general/number/format';
import { Context } from '@/store/context';
import { CoinBuy } from '@/composed/coin/buy';
import {
  AddToMetamaskButton,
  AddToMetamaskIcon,
  BuyWrapper,
  Container,
  Head,
  InfoItem,
  InfoWrapper,
  Title,
  TokenIcon,
  TokenInfo,
  TokenName,
  TokenWrapper,
  Wrapper,
  TokenPrice,
  TokenExplore,
} from './styles';

export const CoinLFIInfo: FC = () => {
  const { price, explorerHref, info } = useContext(Context).lfi;
  const { addToMetamaskCallback } = useContext(Context);
  const priceValue = Math.round(price * 100000) / 100000;

  return (
    <Container>
      <Wrapper>
        <Title>HBTS Tokens</Title>

        <Head>
          <TokenWrapper>
            <TokenIcon aria-hidden />
            <TokenInfo>
              <div>
                <TokenName aria-hidden>HBTS</TokenName>
                <TokenPrice aria-label="Price">
                  <NumberFormat value={priceValue} prefix="$" />
                </TokenPrice>
              </div>
              <div>
                {explorerHref && (
                  <TokenExplore
                    href={explorerHref}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View on Explorer
                  </TokenExplore>
                )}
              </div>
            </TokenInfo>
          </TokenWrapper>
          <span>
            {addToMetamaskCallback && (
              <AddToMetamaskButton
                tag="button"
                onClick={() => {
                  addToMetamaskCallback?.();
                }}
              >
                <AddToMetamaskIcon aria-hidden />
                Add HBTS
              </AddToMetamaskButton>
            )}
          </span>
        </Head>

        {info && (
          <InfoWrapper>
            {info.map((item) => (
              <InfoItem key={item.key} aria-label={item.name}>
                {item.name}
                <span>
                  <NumberFormat value={item.value} {...item.numberFormat} />
                </span>
              </InfoItem>
            ))}
          </InfoWrapper>
        )}
      </Wrapper>
      <BuyWrapper>
        <CoinBuy />
      </BuyWrapper>
    </Container>
  );
};
