import styled from 'styled-components';
import { BoxOutline } from '@/general/box/outline';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const Container = styled(BoxOutline)`
  overflow: hidden;

  @media ${getMediaQuery('smMin')} {
    padding: 20px;
  }
  @media ${getMediaQuery('xs')} {
    padding: 14px;
  }
`;
