import { useContext } from 'react';
import { Context } from './context';

interface IAPI {
  toggleNavigationModal: (val: boolean) => void;
  toggleConnectWallet: (val: boolean) => void;
}

export function useStoreAPI(): IAPI {
  const context = useContext(Context);
  const { system } = context;

  return {
    toggleNavigationModal(val) {
      system.navigationModal.setShown(val);
    },
    toggleConnectWallet(val) {
      system.connectWallet.setShown(val);
    },
  };
}
