import { useState } from 'react';
import { useOnResize } from './resize';
import { vevetApp } from './vevet';

export const breakpoints = {
  xs: [0, 743],
  sm: [744, 899],
  md: [900, 1023],
  lg: [1024, 1199],
  xl: [1200, 1499],
  xxl: [1500, Infinity],
};

export const media = {
  breakpoints,

  isXXL: () =>
    vevetApp.viewport.width >= breakpoints.xxl[0] &&
    vevetApp.viewport.width <= breakpoints.xxl[1],
  isXL: () =>
    vevetApp.viewport.width >= breakpoints.xl[0] &&
    vevetApp.viewport.width <= breakpoints.xl[1],
  isLG: () =>
    vevetApp.viewport.width >= breakpoints.lg[0] &&
    vevetApp.viewport.width <= breakpoints.lg[1],
  isMD: () =>
    vevetApp.viewport.width >= breakpoints.md[0] &&
    vevetApp.viewport.width <= breakpoints.md[1],
  isSM: () =>
    vevetApp.viewport.width >= breakpoints.sm[0] &&
    vevetApp.viewport.width <= breakpoints.sm[1],
  isXS: () =>
    vevetApp.viewport.width >= breakpoints.xs[0] &&
    vevetApp.viewport.width <= breakpoints.xs[1],
};

export function useMediaSize() {
  const [size, setSize] = useState<keyof typeof breakpoints>('xs');

  useOnResize(() => {
    if (media.isXXL()) {
      setSize('xxl');
    } else if (media.isXL()) {
      setSize('xl');
    } else if (media.isLG()) {
      setSize('lg');
    } else if (media.isMD()) {
      setSize('md');
    } else if (media.isSM()) {
      setSize('sm');
    } else {
      setSize('xs');
    }
  });

  return size;
}
