import styled, { css } from 'styled-components';
import { ButtonSimple } from '@/general/button/simple';
import { FormCheckboxLabelled } from '../checkbox/labelled/Labelled';
import { IFormProps } from './types';

export const Container = styled.div`
  position: relative;
`;

export const Form = styled.form<IFormProps>`
  transition: opacity 0.35s;

  ${({ isHidden }) =>
    isHidden &&
    css`
      opacity: 0;
    `}
`;

export const Header = styled.div`
  margin-bottom: 20px;
`;

export const InfoWrapper = styled.div`
  margin-top: 20px;

  > * {
    + * {
      margin-top: 8px;
    }
  }
`;

export const PolicyWrapper = styled.div`
  margin-top: 30px;
  text-align: center;
`;

export const PolicyText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: var(--color-grey-400);

  a {
    color: var(--color-blue);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Description = styled.div`
  margin-top: 18px;
  opacity: 0.72;

  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
`;

export const LabelledCheckbox = styled(FormCheckboxLabelled)`
  width: max-content;
  margin: 14px auto 0;
`;

export const Submit = styled(ButtonSimple).attrs({
  variant: 'primary',
  size: 'large',
  fullWidth: true,
})`
  margin-top: 48px;
  --button-size: 60px;
  --button-border-radius: calc(var(--button-size) / 2);
`;
