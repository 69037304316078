import { css } from 'styled-components';
import { getTransition } from '@/utils/styles/getTransition';
import { TSharedProps } from './types';

type TGetShowStylesProps = TSharedProps & {
  delay?: string;
};

export const getShowStyles = ({
  appearAnimation,
  delay,
}: TGetShowStylesProps) =>
  appearAnimation.use &&
  css`
    opacity: 0;

    ${appearAnimation.on &&
    css`
      ${getTransition('opacity 1s', delay)}
      opacity: 1;
    `}
  `;
