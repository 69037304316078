import React, { FC, ReactNode } from 'react';
import { NumberFormat } from '@/general/number/format';
import { TooltipInfo } from '@/general/tooltip/info';
import styles from './Info.module.scss';
import { Label } from './Label';

interface IProps {
  className?: string;
  tooltip?: ReactNode;
  value: number;
}

export const Info: FC<IProps> = ({ className, tooltip, value }) => (
  <div className={[styles.info, className].join(' ')}>
    <div className={styles.title}>
      <span>You will receive</span>
      {tooltip && (
        <TooltipInfo>
          <div className={styles.tooltip}>{tooltip}</div>
        </TooltipInfo>
      )}
    </div>
    <div className={styles.value}>
      <span>
        <NumberFormat value={value} suffix=" LFI" rightFixed={2} />
      </span>
      <Label isNew />
    </div>
  </div>
);
