import classNames from 'classnames';
import {
  Children,
  cloneElement,
  DetailedReactHTMLElement,
  FC,
  HTMLAttributes,
  PropsWithChildren,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { ScrollView } from 'vevet';
import { Context } from '@/store/context';
import { IProps } from './types';
import styles from './styles.module.scss';

let ScrollViewInstance: ScrollView | undefined;

export const LayoutScrollView: FC<PropsWithChildren<IProps>> = ({
  handleIn,
  handleOut,
  isAppearAnimation,
  delay,
  animation,
  children,
}) => {
  const { states } = useContext(Context);
  const isReady = states?.isContentReady;

  const [isViewed, setIsViewed] = useState<undefined | boolean>(undefined);

  const child = Children.only(children) as DetailedReactHTMLElement<
    HTMLAttributes<HTMLElement>,
    HTMLElement
  >;

  const animationClassName = animation === 'alpha' ? styles.alpha : '';

  const domRef = useRef<HTMLElement>(null);
  useImperativeHandle(child.ref as any, () => domRef.current!);

  useEffect(() => {
    if (typeof isViewed !== 'boolean') {
      return;
    }
    if (isViewed) {
      if (handleIn) {
        handleIn();
      }
    } else if (handleOut) {
      handleOut();
    }
  }, [handleIn, handleOut, isViewed]);

  useLayoutEffect(() => {
    if (!domRef.current || !isReady) {
      return undefined;
    }

    if (!ScrollViewInstance) {
      ScrollViewInstance = new ScrollView({
        container: window,
        useDelay: {
          dir: 'y',
          max: 1000,
        },
      });
    }

    const inCallback = ScrollViewInstance.addCallback(
      'in',
      (data) => {
        if (data === domRef.current) {
          setIsViewed(true);
        }
      },
      { timeout: delay }
    );

    const outCallback = ScrollViewInstance.addCallback('out', (data) => {
      if (data === domRef.current) {
        setIsViewed(false);
      }
    });

    const observer = ScrollViewInstance.addElement(domRef.current);

    return () => {
      observer.remove();
      inCallback.remove();
      outCallback.remove();
    };
  }, [domRef, isReady, delay]);

  if (!states) {
    return child;
  }

  return cloneElement(child, {
    className: classNames(
      child.props.className,
      'view',
      isViewed && 'viewed',
      animationClassName,
      isViewed && styles.viewed
    ),
    ref: domRef,
    ...(isAppearAnimation ? { isAppearAnimation: isViewed || false } : {}),
  });
};
