import React, { FC, useEffect, useState, useMemo } from 'react';
import GroupStyles from './Group.module.scss';
import MiniNavStyles from './MiniNav.module.scss';
import AsteriskNavStyles from './AsteriskNav.module.scss';
import { IProps } from './types';

export const TabButtons: FC<IProps> = ({
  styles: style,
  items,
  onSelect,
  selectedKey,
  className,
  ...tagProps
}) => {
  const styles = useMemo(() => {
    switch (style) {
      case 'group':
        return GroupStyles;
      case 'mininav':
        return MiniNavStyles;
      case 'asterisknav':
        return AsteriskNavStyles;
      default:
        return style || {};
    }
  }, [style]);

  const [selected, setSelected] = useState(
    selectedKey ?? items[0]?.key ?? undefined
  );
  useEffect(() => {
    if (typeof selectedKey !== 'undefined') {
      setSelected(selectedKey);
    }
  }, [selectedKey]);

  return (
    <div
      {...tagProps}
      className={[styles.buttons, className].join(' ')}
      role="tablist"
    >
      {items.map(({ key, id, name }) => {
        const hasAsterisk = name.endsWith('*');
        const tabName = hasAsterisk ? name.slice(0, -1) : name;

        return (
          <button
            key={key}
            type="button"
            role="tab"
            aria-selected={selected === key}
            aria-controls={id || undefined}
            className={[
              styles.button,
              selected === key ? styles.selected : '',
              hasAsterisk ? styles.has_asterisk : '',
            ].join(' ')}
            onClick={() => {
              if (selected !== key) {
                if (typeof selectedKey === 'undefined') {
                  setSelected(key);
                }
                onSelect?.(key);
              }
            }}
          >
            {tabName}
            {hasAsterisk && (
              <span
                className={[
                  styles.asterisk,
                  selected === key ? styles.selected : '',
                ].join(' ')}
              />
            )}
          </button>
        );
      })}
    </div>
  );
};
