import React, { FC, useRef } from 'react';
import { NumberFormat } from '@/general/number/format';
import { TooltipInfo } from '@/general/tooltip/info';
import { Container, Name, Value } from './styles';
import { IInfoProps } from './types';

export const Info: FC<IInfoProps> = ({
  name,
  value,
  color,
  tooltip,
  numberFormat,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <Container ref={parentRef}>
      <Name>
        <span>{name}</span>
        {tooltip && (
          <TooltipInfo
            viewSizeRef={parentRef}
            direction="center"
            alignment="right"
          >
            {tooltip}
          </TooltipInfo>
        )}
      </Name>
      <Value color={color}>
        <NumberFormat value={value} {...numberFormat} />
      </Value>
    </Container>
  );
};
