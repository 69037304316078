import styled, { css } from 'styled-components';
import { TabContent } from '@/general/tab/content';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { LayoutWrapper } from '../wrapper';
import { IBodyProps } from './types';
import { AsideTabButtons } from './aside/tab-buttons';

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: calc(100 * var(--vh) - var(--top-height));

  > * {
    width: 100%;
  }
`;

export const Wrapper = styled(LayoutWrapper)`
  padding-top: 0;
`;

export const Head = styled.div`
  box-sizing: border-box;
  padding: 24px;
  padding-bottom: 0;
  margin-bottom: -10px;

  @media ${getMediaQuery('xs')} {
    padding: 12px;
    padding-bottom: 0;
  }

  > * {
    margin-bottom: 24px;
  }
`;

export const HeadBreadcrumbs = styled.div`
  > * {
    @media ${getMediaQuery('smMin')} {
      display: none;
    }
  }
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-grey-800);
`;

export const NavBreadcrumbs = styled.div`
  > * {
    @media ${getMediaQuery('xs')} {
      display: none;
    }
  }
`;

export const Body = styled.div<IBodyProps>`
  padding-top: 20px;

  ${({ isTwoColumns }) =>
    isTwoColumns &&
    css`
      display: grid;
      grid-template-columns: 0.67fr 0.33fr;
      grid-gap: 24px;
    `}
`;

export const TabPanel = styled(TabContent)`
  height: 100%;
`;

export const Aside = styled.div`
  background-color: var(--color-grey-1000);
  border-radius: 8px;
  overflow: hidden;
`;

export const AsideTabs = styled(AsideTabButtons)`
  box-sizing: border-box;
  padding: 20px 16px;
  background-color: var(--color-grey-1250);
`;

export const AsideContent = styled.div`
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;

  @media ${getMediaQuery('smMin')} {
    padding: 20px 20px 24px;
  }
  @media ${getMediaQuery('xs')} {
    padding: 20px 12px 24px;
  }
`;

export const AsideContentInPopup = styled(AsideContent)`
  min-height: 530px;
`;
