import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const Container = styled.div`
  box-sizing: border-box;
  padding: 24px;
  overflow: hidden;

  @media ${getMediaQuery('xs')} {
    padding: 12px;
  }
`;
