import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Timeline, utils } from 'vevet';
import { ButtonSimple } from '@/general/button/simple';
import { NumberCounter } from '@/general/number/counter';
import { NumberFormat } from '@/general/number/format';
import successVideo from './success.mp4';
import { ITokenPreviewClaimProps } from './types';
import {
  Buttons,
  Preview,
  Rewards,
  RewardsTitle,
  RewardsValue,
  StatusButton,
  StatusButtonWrapper,
  StatusConfirm,
  StatusContainer,
  StatusPending,
  Submit,
  SuccessContainer,
} from './styles';
import { useTimeoutPropState } from '@/utils/useTimeoutPropState';
import { isNonNullable } from '@/utils/isNonNulable';

export const TokenPreviewClaim = forwardRef<
  HTMLDivElement,
  ITokenPreviewClaimProps
>(
  (
    {
      isAppearAnimation,
      size = 'medium',
      rewards,
      status,
      buttons,
      onSuccess,
      hideOnSuccess,
      onHidden,
      ...props
    },
    ref
  ) => {
    const parentRef = useRef<HTMLDivElement>(null);
    const previewRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => previewRef.current!);

    const isChildrenAppearAnimation = useTimeoutPropState(
      isAppearAnimation,
      !isAppearAnimation ? 0 : 500
    );

    const statusCodes = {
      confirm: 1,
      pending: 2,
      success: 3,
    };
    const statusCode = status ? statusCodes[status] || 0 : 0;

    useEffect(() => {
      if (status === 'success') {
        onSuccess?.();
      }
    }, [status, onSuccess]);

    const [hide, setHide] = useState(false);
    useEffect(() => {
      if (!hide) {
        return undefined;
      }

      const parent = parentRef.current;
      const preview = previewRef.current;
      if (!parent || !preview) {
        return undefined;
      }

      // reset sizes
      if (status !== 'success') {
        parent.style.opacity = '';
        parent.style.width = '';
        return undefined;
      }

      // save sizes
      const startParentWidth = parent.clientWidth;
      const startPreviewWidth = preview.clientWidth;

      // shrink
      const tm = new Timeline({
        duration: 600,
      });
      tm.addCallback('progress', (data) => {
        const opacity =
          1 - utils.math.clampScope(data.easing, [0, 0.5], [0, 1]);
        const width =
          (1 - utils.math.clampScope(data.easing, [0.5, 1], [0, 1])) *
          startParentWidth;

        parent.style.opacity = `${opacity}`;
        parent.style.width = `${width}px`;
        preview.style.width = `${startPreviewWidth}px`;
      });
      tm.addCallback('end', () => {
        onHidden?.();
      });
      tm.play();

      return () => {
        tm.destroy();
      };
    }, [hide, status, onHidden]);

    return (
      <div ref={parentRef} style={{ overflow: 'hidden' }}>
        <Preview
          ref={previewRef}
          {...props}
          isAppearAnimation={isAppearAnimation}
          size={size}
        >
          {isNonNullable(rewards) && (
            <Rewards>
              <RewardsTitle>Total Rewards:</RewardsTitle>
              <RewardsValue>
                {typeof isChildrenAppearAnimation === 'boolean' ? (
                  <NumberCounter
                    animation={isChildrenAppearAnimation}
                    rightFixed={2}
                    {...rewards}
                    suffix=""
                  />
                ) : (
                  <NumberFormat rightFixed={2} {...rewards} suffix="" />
                )}
                <i> {rewards.suffix}</i>
              </RewardsValue>
            </Rewards>
          )}

          {buttons && (
            <Buttons>
              <Submit isHide={!!status}>
                <ButtonSimple
                  tag="button"
                  variant="gold"
                  hasBorder
                  size={size}
                  fullWidth
                  isForceHover={!!status}
                  onClick={() => {
                    if (!status) {
                      buttons.onClick?.();
                    }
                  }}
                >
                  {buttons.submit || 'Claim'}
                </ButtonSimple>
              </Submit>

              <StatusButtonWrapper isShow={!!status}>
                <StatusButton tag="button" size={size} disabled>
                  <StatusContainer>
                    <StatusConfirm isActive={statusCode <= 1}>
                      {buttons.confirm || 'Confirm in MetaMask'}
                    </StatusConfirm>
                    <StatusPending isActive={statusCode >= 2}>
                      {buttons.pending || 'Claiming...'}
                    </StatusPending>
                  </StatusContainer>
                </StatusButton>
              </StatusButtonWrapper>
            </Buttons>
          )}

          {status === 'success' && (
            <SuccessContainer>
              <video
                disablePictureInPicture
                preload="auto"
                muted
                playsInline
                autoPlay
                onEnded={() => {
                  if (hideOnSuccess) {
                    setHide(true);
                  }
                }}
              >
                <source src={successVideo} type="video/mp4" />
              </video>
            </SuccessContainer>
          )}
        </Preview>
      </div>
    );
  }
);

TokenPreviewClaim.displayName = 'TokenPreviewClaim';
