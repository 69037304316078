import React, {
  Children,
  PropsWithChildren,
  ReactElement,
  useRef,
} from 'react';
import { Slide } from './slide';
import { Layout, Slide as StyledSlide } from './styles';
import { IProps } from './types';
import { useSlides } from './utils/useSlides';

export const BannerSlider = ({
  className,
  style,
  interval = 6000,
  children,
}: PropsWithChildren<IProps>): ReactElement => {
  const parentRef = useRef<HTMLDivElement>(null);

  const { activeIndex } = useSlides({
    count: Array.isArray(children) ? children.length : 0,
    parentRef,
    interval,
  });

  const slides = Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return (
        <StyledSlide isActive={activeIndex === index}>{child}</StyledSlide>
      );
    }
    return null;
  });

  return (
    <Layout ref={parentRef} className={className} style={style}>
      {slides}
    </Layout>
  );
};

BannerSlider.Slide = Slide;
Slide.displayName = 'BannerSlider.Slide';
