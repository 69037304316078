import React, {
  FC,
  createContext,
  PropsWithChildren,
  useState,
  useMemo,
} from 'react';
import { IStoreData, IStoreProps } from './types';

const defaultState: IStoreData = {
  authorized: false,
  connectNetwork: {
    items: [],
    selectedKey: '',
    onSelect() {},
    wrongNetwork: false,
  },
  connectWallet: {
    wallets: [],
    selectedKey: undefined,
    onSelect: () => {},
  },
  lfi: {
    price: 0,
    info: [],
    explorerHref: '',
  },
  buy: {
    items: [],
  },
  user: {
    address: '',
    network: '',
    explorerHref: '',
    disconnectCallback: () => {},
  },
  addToMetamaskCallback: () => {},
  upgradeTokenHref: '',
  links: {
    privacy: '',
    terms: '',
  },
  system: {
    navigationModal: {
      shown: false,
      setShown() {},
    },
    connectWallet: {
      shown: false,
      setShown() {},
    },
  },
};

export const Context = createContext(defaultState);

export const Provider: FC<
  PropsWithChildren<{
    value: IStoreProps;
  }>
> = ({ children, value }) => {
  const [navigationModalShown, setNavigationModalShown] = useState(false);
  const [connectWalletShown, setConnectWalletShown] = useState(false);

  const val: IStoreData = useMemo(
    () => ({
      ...value,
      system: {
        navigationModal: {
          shown: navigationModalShown,
          setShown: setNavigationModalShown,
        },
        connectWallet: {
          shown: connectWalletShown,
          setShown: setConnectWalletShown,
        },
      },
    }),
    [connectWalletShown, navigationModalShown, value]
  );

  return <Context.Provider value={val}>{children}</Context.Provider>;
};
