import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > * {
    @media ${getMediaQuery('smMin')} {
      width: 160px;

      + * {
        margin-left: 16px;
      }
    }

    @media ${getMediaQuery('xs')} {
      width: calc(50% - 4px);

      + * {
        margin-left: 8px;
      }
    }
  }
`;
