import { css } from 'styled-components';

export function resetScrollBars() {
  return css`
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `;
}
