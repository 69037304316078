import styled, { css } from 'styled-components';
import { resetUl } from '@/utils/styles/resetUl';
import { ISharedProps } from './types';

export const Title = styled.div<ISharedProps>`
  font-weight: 500;
  line-height: 100%;
  color: var(--color-grey-600);

  ${({ size }) =>
    (size === 'large' || size === 'medium') &&
    css`
      font-size: 12px;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 10px;
    `}
`;

export const List = styled.ul<ISharedProps>`
  ${resetUl}
  margin: 8px 0 0 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-style: normal;
  line-height: 100%;

  ${({ size }) =>
    (size === 'large' || size === 'medium') &&
    css`
      font-weight: 600;
      font-size: 20px;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      font-weight: 500;
      font-size: 16px;
    `}

  li {
    + li {
      margin-left: 0.25em;
    }

    > * {
      display: block;
    }
  }
`;

export const LFI = styled.li`
  color: var(--color-success);
`;

export const Children = styled.div`
  margin-top: 18px;
`;
