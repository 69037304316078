import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const InputWrapper = styled.div``;

export const InputLabel = styled.label`
  display: block;
  text-transform: uppercase;
  color: var(--color-grey-475);

  @media ${getMediaQuery('smMin')} {
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  @media ${getMediaQuery('xs')} {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
  }
`;
