import { useEffect, useRef, useState } from 'react';
import { useTimeoutPropState } from '@/utils/useTimeoutPropState';
import { useAppearAnimation } from '@/utils/useAppearAnimation';
import { IStoreProps } from '@/store/types';

export function useContainerStates(states: IStoreProps['states']) {
  const onLayoutReadyRef = useRef(states?.onLayoutReady);

  // layout may be animated only once
  const [isLayoutReady, setIsLayoutReady] = useState(states?.isLayoutReady);
  useEffect(() => {
    if (states?.isLayoutReady) {
      setIsLayoutReady(states.isLayoutReady);
    }
  }, [states?.isLayoutReady]);

  const isLayoutAppearAnimation = useTimeoutPropState(isLayoutReady, 250);

  const isContentAppearAnimation = useTimeoutPropState(isLayoutReady, 1450);
  const contentAppearAnimation = useAppearAnimation(isContentAppearAnimation);

  useEffect(() => {
    if (isContentAppearAnimation) {
      onLayoutReadyRef.current?.();
    }
  }, [isContentAppearAnimation]);

  return {
    isLayoutReady,
    isLayoutAppearAnimation,
    contentAppearAnimation,
  };
}
