import styled, { css } from 'styled-components';
import { ButtonBase } from '../__base/Base';
import { TSharedProps, TProps, TStylesProps } from './types';

const settings = css`
  --button-border-radius: 8px;
`;

const getVariantStyles = ({ variant }: TProps) => css`
  ${variant === 'primary' &&
  css`
    --button-color-text: #000;
    --button-color-text-hover: #000;
    --button-color-bg: #00fff4;
    --button-color-bg-hover: #00e2d7;
  `}

  ${variant === 'secondary' &&
  css`
    --button-color-text: #ced4da;
    --button-color-text-hover: #ced4da;
    --button-color-bg: #144;
    --button-color-bg-hover: #008d8d;
  `}

  ${variant === 'success' &&
  css`
    --button-color-text: #fff;
    --button-color-text-hover: #fff;
    --button-color-bg: #0e6330;
    --button-color-bg-hover: #0d7737;
  `}

  ${variant === 'danger' &&
  css`
    --button-color-text: #fff;
    --button-color-text-hover: #fff;
    --button-color-bg: #5b1d1d;
    --button-color-bg-hover: #711f1f;
  `}

  ${variant === 'light' &&
  css`
    --button-color-text: #000;
    --button-color-text-hover: #000;
    --button-color-bg: #e9ecef;
    --button-color-bg-hover: #fff;
  `}

  ${variant === 'dark' &&
  css`
    --button-color-text: #fff;
    --button-color-text-hover: #fff;
    --button-color-bg: #343a40;
    --button-color-bg-hover: #4b5259;
  `}

  ${variant === 'gold' &&
  css`
    --button-color-text: #000;
    --button-color-text-hover: #fff;
    --button-color-bg: linear-gradient(
      268.49deg,
      #f5d147 5.55%,
      #ffe47d 50.69%,
      #f5d147 99.76%
    );
    --button-color-bg-hover: #000;
  `}
`;

export const Bg = styled.span<TSharedProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-border-radius);
  background: var(--button-color-bg);
  transition: transform 0.5s, filter 0.25s;
  transform: translateZ(0);

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      transform: translate(0, 105%);
    `}
`;

export const Button = styled(ButtonBase)<TStylesProps>`
  ${settings}

  position: relative;
  border-radius: var(--button-border-radius);
  overflow: hidden;
  padding: 4px 32px;

  background-color: var(--color-grey-700);
  transition: color 0.5s;

  text-align: center;

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          color: var(--color-white);
        `
      : css`
          color: var(--button-color-text);
        `}

  ${({ isHovered }) =>
    isHovered &&
    css`
      color: var(--button-color-text-hover);
    `}

  ${getVariantStyles}

  &:active ${Bg} {
    filter: brightness(0.75);
  }
`;

export const BgHover = styled.span<TSharedProps>`
  position: absolute;
  overflow: hidden;
  border-radius: var(--button-border-radius);

  ${({ hasBorder }) =>
    hasBorder
      ? css`
          top: 1px;
          left: 1px;
          right: 1px;
          bottom: 1px;
        `
      : css`
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        `}

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    border-radius: calc(var(--button-size) / 2);
    width: calc(50% + var(--button-size) / 2);
    height: 100%;
    transition: transform 0.5s;
    background: var(--button-color-bg-hover);
  }

  &::before {
    left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transform: translate(-101%, 0);
  }

  &::after {
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transform: translate(101%, 0);
  }

  ${({ isHovered }) =>
    isHovered &&
    css`
      &::before,
      &::after {
        transform: none;
      }
    `}
`;

export const Content = styled.span`
  position: relative;
  z-index: 1;
`;
