import React, { FC, useId, useRef, useState } from 'react';
import { media } from '@/utils/media';
import { useOnResize } from '@/utils/resize';
import { IProps, IPropsSingle } from './types';
import {
  Aside,
  AsideContent,
  AsideContentInPopup,
  AsideTabs,
  Body,
  Content,
  Head,
  HeadBreadcrumbs,
  Nav,
  NavBreadcrumbs,
  TabPanel,
  Wrapper,
} from './styles';
import { HeadTabs } from './head-tabs';
import { AsidePopup } from './aside/popup';
import { TabContent } from '@/general/tab/content/index';

export const LayoutTwoColumnTabs: FC<IProps> = ({
  breadcrumbs,
  header,
  contentTabs,
  asideTabs,
}) => {
  const id = useId();
  const wrapperRef = useRef<HTMLDivElement>(null);

  // content tabs
  const contentTabList = contentTabs.map(({ key, name, children }, index) => ({
    key,
    name,
    children,
    id: `${id}-content-${index}`,
  }));
  const [contentSelectedKey, setContentSelectedKey] = useState(
    contentTabList[0].key
  );

  // aside tabs
  const asideTabList = asideTabs.map(({ key, name, children }, index) => ({
    key,
    name,
    children,
    id: `${id}-aside-${index}`,
  }));
  const [asideSelectedKey, setAsideSelectedKey] = useState(asideTabList[0].key);

  // two columns layout
  const [isTwoColumns, setIsTwoColumns] = useState(
    media.isXXL() || media.isXL()
  );
  useOnResize(
    () => {
      setIsTwoColumns(media.isXXL() || media.isXL());
    },
    [],
    {
      timeout: 0,
    }
  );

  return (
    <div>
      <Head>
        {breadcrumbs && <HeadBreadcrumbs>{breadcrumbs}</HeadBreadcrumbs>}
        {header}
      </Head>

      <Content>
        <Wrapper ref={wrapperRef}>
          <Nav>
            <NavBreadcrumbs>{breadcrumbs}</NavBreadcrumbs>
            <HeadTabs
              items={contentTabList}
              selectedKey={contentSelectedKey}
              onSelect={(key) => setContentSelectedKey(key as any)}
            />
          </Nav>
          <Body isTwoColumns={isTwoColumns}>
            <TabPanel items={contentTabList} selectedKey={contentSelectedKey} />
            {isTwoColumns && (
              <Aside>
                <AsideTabs
                  selectedKey={asideSelectedKey}
                  tablist={asideTabList}
                  onSelect={setAsideSelectedKey}
                />
                <AsideContent>
                  <TabContent
                    selectedKey={asideSelectedKey}
                    items={asideTabList}
                    duration={500}
                  />
                </AsideContent>
              </Aside>
            )}
          </Body>
        </Wrapper>

        {!isTwoColumns && (
          <AsidePopup
            contentBelowRef={wrapperRef}
            tabs={{
              selectedKey: asideSelectedKey,
              tablist: asideTabList,
              onSelect: setAsideSelectedKey,
            }}
          >
            <AsideContentInPopup>
              <TabContent
                selectedKey={asideSelectedKey}
                items={asideTabList}
                duration={500}
              />
            </AsideContentInPopup>
          </AsidePopup>
        )}
      </Content>
    </div>
  );
};

export const LayoutSingleColumnTabs: FC<IPropsSingle> = ({
  breadcrumbs,
  header,
  contentTabs,
}) => {
  const id = useId();
  const wrapperRef = useRef<HTMLDivElement>(null);

  // content tabs
  const contentTabList = contentTabs.map(({ key, name, children }, index) => ({
    key,
    name,
    children,
    id: `${id}-content-${index}`,
  }));
  const [contentSelectedKey, setContentSelectedKey] = useState(
    contentTabList[0].key
  );

  return (
    <div>
      <Head>
        {breadcrumbs && <HeadBreadcrumbs>{breadcrumbs}</HeadBreadcrumbs>}
        {header}
      </Head>

      <Content>
        <Wrapper ref={wrapperRef}>
          <Nav>
            <NavBreadcrumbs>{breadcrumbs}</NavBreadcrumbs>
            <HeadTabs
              items={contentTabList}
              selectedKey={contentSelectedKey}
              onSelect={(key) => setContentSelectedKey(key as any)}
            />
          </Nav>
          <Body isTwoColumns={false}>
            <TabPanel items={contentTabList} selectedKey={contentSelectedKey} />
          </Body>
        </Wrapper>
      </Content>
    </div>
  );
};
