import React, { FC, useMemo, useState } from 'react';
import { useOnResize } from '@/utils/resize';
import { vevetApp } from '@/utils/vevet';
import { ConnectUserButton } from './button/Button';
import { ConnectUserInfo } from './info/Info';
import { Modal, Popup } from './styles';
import { TProps } from './types';

export const ConnectUser: FC<TProps> = ({ isAppearAnimation }) => {
  const [usePopup, setUsePopup] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useOnResize(() => {
    setUsePopup(vevetApp.viewport.width > 1024);
  }, []);

  const Button = useMemo(
    () => (
      <ConnectUserButton
        isAppearAnimation={isAppearAnimation}
        onClick={() => setIsModalOpen(true)}
      />
    ),
    [isAppearAnimation]
  );

  if (usePopup) {
    return (
      <Popup trigger={Button} alignment="auto" direction="auto">
        <ConnectUserInfo />
      </Popup>
    );
  }

  return (
    <>
      {Button}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ConnectUserInfo />
      </Modal>
    </>
  );
};
