import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { IPopupHandle } from '@/general/popup/types';
import { Context } from '@/store/context';
import { ConnectNetworkButton } from './button/Button';
import { Item } from './item/Item';
import { IItemProps } from './item/types';
import { List, Popup, Title } from './styles';
import { TProps } from './types';

export const ConnectNetwork: FC<TProps> = ({ isAppearAnimation }) => {
  const { items, selectedKey, onSelect } = useContext(Context).connectNetwork;

  const popupRef = useRef<IPopupHandle>(null);

  const [activeNetwork, setActiveNetwork] = useState<IItemProps | undefined>();
  useEffect(() => {
    setActiveNetwork(items.find(({ key }) => key === selectedKey));
  }, [selectedKey, items]);

  return (
    <Popup
      ref={popupRef}
      trigger={
        <ConnectNetworkButton
          isAppearAnimation={isAppearAnimation}
          icon={activeNetwork?.icon}
          color={activeNetwork?.color}
        />
      }
      direction="auto"
      alignment="start"
    >
      <Title>Swap Network</Title>
      <List>
        {items.map(({ key, ...network }) => (
          <Item
            key={key}
            {...network}
            keyVal={key}
            isActive={key === activeNetwork?.key}
            onSelect={onSelect}
            popupRef={popupRef}
          />
        ))}
      </List>
    </Popup>
  );
};
