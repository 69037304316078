import { RefObject, useEffect, useState } from 'react';
import { addEventListener } from 'vevet-dom';
import { vevetApp } from './vevet';

export function useHover(ref: RefObject<HTMLElement>) {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el || vevetApp.isMobile) {
      return undefined;
    }
    const enter = addEventListener(el, 'mouseenter', () => setIsHovered(true));
    const leave = addEventListener(el, 'mouseleave', () => setIsHovered(false));
    return () => {
      enter.remove();
      leave.remove();
    };
  }, [ref]);

  return isHovered;
}
