import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Timeline, utils } from 'vevet';
import { clampScopeEasing } from '@/utils/clampScopeEasing';
import { Circle, Svg } from './styles';
import { IProps } from './types';

const width = 180;
const height = width;
const radius = height / 2;
const strokeWidth = 18;
const circleLength = (radius - strokeWidth / 2) * Math.PI * 2;

export const ChartPie: FC<IProps> = ({
  className,
  style,
  isAppearAnimation,
  data,
  activeKey,
}) => {
  const [svgRef, setSvgRef] = useState<SVGSVGElement | null>(null);

  /**
   * Generate circles data
   */
  const circlesData = useMemo(() => {
    // calculate base fraction
    const totalValue = data.reduce((acc, item) => acc + item.value, 0);
    let totalFraction = 0;
    const realFractionItems = [...data].map((item) => {
      const minFraction = 1 / (data.length * 2);
      const fraction = utils.math.clamp(item.value / totalValue, [
        minFraction,
        Infinity,
      ]);
      totalFraction += fraction;
      const newData = {
        ...item,
        fraction,
        canShrink: fraction !== minFraction,
      };
      return newData;
    });

    // shink fraction when needed
    // source from here - https://www.madebymike.com.au/demos/flexbox-tester/
    const fractionRemaining = 1 - totalFraction;
    const srinkableItems = realFractionItems.filter((item) => item.canShrink);
    const shrinkFractionItems = realFractionItems.map((item) => {
      const shrinkBase = item.canShrink ? 1 / srinkableItems.length : 0;
      const fraction = shrinkBase * fractionRemaining + item.fraction;
      return {
        ...item,
        fraction,
      };
    });

    // and calculate strokes
    let strokeStart = 0;
    return shrinkFractionItems.map((item) => {
      const newData = {
        ...item,
        strokeStart: strokeStart * circleLength,
        strokeEnd: (strokeStart + item.fraction) * circleLength,
      };
      strokeStart += item.fraction;
      return newData;
    });
  }, [data]);

  /**
   * Render the scene
   */
  const render = useCallback(
    (progress: number) => {
      if (!svgRef || circlesData.length === 0) {
        return;
      }

      const circleElements = Array.from(
        svgRef.querySelectorAll<SVGCircleElement>('circle')
      );
      const easing = clampScopeEasing(progress, [0, 1]);

      // draw circles
      circlesData.forEach((circle, index) => {
        const elements = circleElements.filter(
          (el) => el.dataset.key === circlesData[index].key
        );
        const { strokeStart, strokeEnd } = circle;

        elements.forEach((el) => {
          const element = el;
          element.style.strokeDasharray = `
            ${(strokeEnd - strokeStart) * easing},
            ${circleLength}
          `;
          element.style.strokeDashoffset = `${-strokeStart * easing}`;
        });
      });

      // show svg
      svgRef.style.opacity = `${utils.math.clampScope(progress, [0, 0.5])}`;
    },
    [circlesData, svgRef]
  );

  // set animation
  useEffect(() => {
    if (typeof isAppearAnimation === 'undefined') {
      render(1);
      return undefined;
    }

    if (!isAppearAnimation) {
      render(0);
      return undefined;
    }

    const tm = new Timeline({ duration: 1500 });
    tm.addCallback('progress', ({ progress }) => render(progress));
    tm.play();

    return () => tm.destroy();
  }, [isAppearAnimation, render]);

  return (
    <Svg
      ref={setSvgRef}
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
    >
      {[...circlesData].reverse().map(({ key, name, hex }) => (
        <Circle
          key={key}
          data-key={key}
          isUnactive={!!activeKey && activeKey !== key}
          data-name={name}
          cx={width / 2}
          cy={height / 2}
          r={radius - strokeWidth / 2}
          strokeWidth={strokeWidth}
          stroke={hex}
        />
      ))}
    </Svg>
  );
};
