import React, { FC } from 'react';
import { Slide } from '@/general/banner/slider/slide';
import { StyledTitle } from './styles';
import { IProps } from './types';

import imageXS from './img/xs.png';
import imageSM from './img/sm.png';
import imageMD from './img/md.png';
import imageLG from './img/lg.png';
import imageXL from './img/xl.png';
import imageXXL from './img/xxl.png';

export const Discord: FC<IProps> = ({ ...props }) => (
  <Slide
    title={<StyledTitle />}
    image={{
      xs: imageXS,
      sm: imageSM,
      md: imageMD,
      lg: imageLG,
      xl: imageXL,
      xxl: imageXXL,
    }}
    {...props}
  />
);
