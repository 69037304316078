import styled from 'styled-components';
import { Popup as PopupFC } from '@/general/popup';
import { Modal as ModalFC } from '@/internal/modal';
import { ModalCloseButton } from '@/internal/modal/styles';

export const Popup = styled(PopupFC).attrs({
  useStyles: false,
})`
  width: 100vw;
`;

export const Modal = styled(ModalFC).attrs({
  hasPadding: false,
  hasStyles: false,
})`
  ${ModalCloseButton} {
    top: 16px;
    right: 10px;
  }
`;
