import styled, { css, keyframes } from 'styled-components';
import { ButtonSvgOutline } from '@/general/button/svg-outline';
import { TSharedProps } from './types';
import { IconLunaFi } from '@/internal/icons/LunaFi';

export const Button = styled(ButtonSvgOutline)`
  font-size: 16px;
  padding-left: 8px;
  padding-right: 8px;
`;

const iconRotationKeyframes = keyframes`
  from {
    transform: rotateY(0deg) rotateX(0deg);
  }
  25% {
    transform: rotateY(${1 * 360}deg) rotateX(45deg);
  }
  to {
    transform: rotateY(${2 * 360}deg) rotateX(0deg);
  }
`;

export const IconWrapper = styled.span<TSharedProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--color-black);
  margin-right: 12px;

  ${({ isAppearAnimation }) =>
    isAppearAnimation &&
    css`
      animation: ${iconRotationKeyframes};
      animation-delay: 0s;
      animation-duration: 1.25s;
      animation-fill-mode: both;
      animation-timing-function: ease-out;
    `}
`;

export const Icon = styled(IconLunaFi)`
  width: 26px;
  height: auto;
`;

export const ArrowSvg = styled.svg`
  margin-left: 6px;
  margin-right: 2px;
  margin-top: 2px;
  color: var(--color-grey-500);
`;
