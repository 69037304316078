import styled, { css } from 'styled-components';
import { getTransition } from '@/utils/styles/getTransition';
import { IImageProps } from './types';

const getImagePosition = ({ pos }: IImageProps) => {
  if (pos) {
    return css`
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    `;
  }
  return null;
};

const getImageObjectFit = ({ pos }: IImageProps) => {
  if (pos === 'cover') {
    return css`
      object-fit: cover;
    `;
  }
  if (pos === 'contain') {
    return css`
      object-fit: contain;
    `;
  }
  return null;
};

const getImageAnimation = ({ useAlpha, isLoaded }: IImageProps) => {
  if (useAlpha) {
    return css`
      ${getTransition('opacity 0.35s')};
      opacity: ${isLoaded ? 1 : 0};
    `;
  }
  return null;
};

export const Image = styled.img<IImageProps>`
  ${getImagePosition}
  ${getImageObjectFit}
  ${getImageAnimation}
`;
