import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { Timeline, utils } from 'vevet';
import { Letter, Word, Wrapper } from './styles';
import { IProps } from './types';

export const LetterText: FC<IProps> = ({ text, isShown, className, style }) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const elementsRef = useRef<HTMLElement[]>([]);

  // split elements
  const words = useMemo(
    () =>
      text.split(' ').map((word, wordIndex) => ({
        key: `w-${wordIndex}`,
        letters: word.split('').map((letter, letterIndex) => ({
          key: `l-${letterIndex}`,
          letter,
        })),
      })),
    [text]
  );

  // render the scene
  const progressRef = useRef(0);
  const render = useCallback(() => {
    const elements = elementsRef.current;
    const spread = utils.math.spreadScope(elements.length, 0.92);

    for (let index = 0, l = elements.length; index < l; index += 1) {
      const el = elements[index];
      const easing = utils.math.easing(
        utils.math.clampScope(progressRef.current, spread[index])
      );
      el.style.opacity = `${easing}`;
    }
  }, []);

  // get elements
  useLayoutEffect(() => {
    if (!parentRef.current) {
      return;
    }
    elementsRef.current = Array.from(
      parentRef.current.querySelectorAll('.js-letter')
    );
    render();
  }, [words, render]);

  // animation timeline
  const timelineRef = useRef<Timeline | null>(null);
  useEffect(
    () => () => {
      timelineRef.current?.destroy();
      timelineRef.current = null;
    },
    []
  );

  // animate elements
  useEffect(() => {
    // create animation timeline if it does't exist yet
    if (!timelineRef.current) {
      timelineRef.current = new Timeline({ duration: 1000 });
      timelineRef.current.addCallback('progress', ({ progress }) => {
        progressRef.current = progress;
        render();
      });
    }

    // define animation direction
    if (isShown) {
      timelineRef.current.play();
    } else if (timelineRef.current.progress > 0) {
      timelineRef.current.reverse();
    }
  }, [isShown, render]);

  return (
    <Wrapper ref={parentRef} className={className} style={style}>
      {words.map((word, wordIndex) => (
        <Word key={word.key} className="js-word">
          {word.letters.map((letter) => (
            <Letter key={letter.key} className="js-letter">
              {letter.letter}
            </Letter>
          ))}
          {wordIndex !== words.length - 1 && ' '}
        </Word>
      ))}
    </Wrapper>
  );
};
