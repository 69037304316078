import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Timeline, utils } from 'vevet';
import gsap from 'gsap';
import { clampScopeEasing } from '@/utils/clampScopeEasing';
import { useOnElementResize } from '@/utils/resize';
import { useStateToRef } from '@/utils/useStateToRef';
import { useAppearAnimation } from '@/utils/useAppearAnimation';

interface IProps {
  isAppearAnimation?: boolean;
  parentRef: RefObject<HTMLElement>;
  svgRef: RefObject<SVGSVGElement>;
  staticOutlineRef: RefObject<SVGRectElement>;
  hoverOutlineRef: RefObject<SVGRectElement>;
  bgPlaneRef: RefObject<SVGRectElement>;
  contentRef: RefObject<HTMLElement>;
}

export function useSvg({
  isAppearAnimation,
  parentRef,
  svgRef,
  staticOutlineRef,
  hoverOutlineRef,
  bgPlaneRef,
  contentRef,
}: IProps) {
  const appearAnimation = useAppearAnimation(isAppearAnimation);
  const showProgressRef = useRef(0);
  const hoverProgressRef = useRef(0);

  // states are used for html
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(100);

  // and refs are used in callbacks for better performance
  // to avoid multiple re-renders
  const widthRef = useStateToRef(width);
  const heightRef = useStateToRef(height);

  /**
   * Render the scene
   */
  const render = useCallback(() => {
    const showProgress = showProgressRef.current;
    const hoverProgress = hoverProgressRef.current;

    // elements
    const svg = svgRef.current;
    const staticOutline = staticOutlineRef.current;
    const hoverOutline = hoverOutlineRef.current;
    const bgPlane = bgPlaneRef.current;
    const content = contentRef.current;

    // show svg
    if (svg) {
      svg.style.opacity = `${utils.math.clampScope(showProgress, [0, 0.25])}`;
    }

    // get element outline length
    let totalLength = 0;
    try {
      totalLength = staticOutline?.getTotalLength() || 0;
    } catch (e) {
      totalLength = 0;
    }
    const dashArrayStart = 0;
    const dashArrayEnd = totalLength;

    // render static stroke
    if (staticOutline) {
      const progress = clampScopeEasing(showProgress, [0, 0.7]);

      const showOffset =
        heightRef.current * 2 + widthRef.current - totalLength * progress;
      staticOutline.style.strokeDasharray = `
        ${utils.math.lerp(dashArrayStart, dashArrayEnd, progress)}
        ${utils.math.lerp(dashArrayEnd, dashArrayStart, progress)}
      `;
      staticOutline.style.strokeDashoffset = `${showOffset}`;
    }

    // render background
    if (bgPlane) {
      const progress = clampScopeEasing(showProgress, [0.6, 1]);
      bgPlane.style.transform = `translate3d(0, ${(1 - progress) * 100}%, 0)`;
    }

    // render content
    if (content) {
      const progress = clampScopeEasing(showProgress, [0.6, 1]);
      content.style.opacity = `${progress}`;
    }

    // render hover stroke
    if (hoverOutline) {
      const hoverOffset =
        heightRef.current * 2 + widthRef.current - totalLength * hoverProgress;
      hoverOutline.style.strokeDasharray = `
        ${utils.math.lerp(dashArrayStart, dashArrayEnd, hoverProgress)}
        ${utils.math.lerp(dashArrayEnd, dashArrayStart, hoverProgress)}
      `;
      hoverOutline.style.strokeDashoffset = `${hoverOffset}`;
      hoverOutline.style.opacity = hoverProgress > 0 ? '1' : '0';
    }
  }, [
    bgPlaneRef,
    contentRef,
    heightRef,
    hoverOutlineRef,
    staticOutlineRef,
    svgRef,
    widthRef,
  ]);

  /**
   * Resize the scene
   */
  const resize = () => {
    if (!svgRef.current) {
      return;
    }
    setWidth(utils.math.clamp(svgRef.current.clientWidth, [4, Infinity]));
    setHeight(utils.math.clamp(svgRef.current.clientHeight, [4, Infinity]));
    render();
  };

  // set resize event listener
  useOnElementResize(
    parentRef,
    () => {
      resize();
    },
    []
  );

  /**
   * Set show progress and render
   */
  const setShowProgress = useCallback(
    (val: number) => {
      showProgressRef.current = val;
      render();
    },
    [render]
  );

  /**
   * Enable/disable hover state
   */
  const setHover = (bool: boolean) => {
    if (bool) {
      gsap.to(hoverProgressRef, {
        current: 1,
        duration: 0.75,
        onUpdate: () => {
          render();
        },
      });
    } else if (hoverProgressRef.current > 0) {
      gsap.to(hoverProgressRef, {
        current: 0,
        duration: 0.75,
        onUpdate: () => {
          render();
        },
      });
    }
  };

  // render appear animation
  useEffect(() => {
    if (!appearAnimation.use) {
      setShowProgress(1);
      return undefined;
    }

    if (!appearAnimation.on) {
      setShowProgress(0);
      return undefined;
    }

    const tm = new Timeline({ duration: 2000 });
    tm.addCallback('progress', ({ progress }) => setShowProgress(progress));
    tm.play();

    return () => {
      tm.destroy();
    };
  }, [appearAnimation.on, appearAnimation.use, setShowProgress]);

  return {
    width,
    height,
    setHover,
  };
}
