import React, { forwardRef, useEffect, useState } from 'react';
import { NumberPercent } from '@/general/number/percent';
import { NumberFormat } from '@/general/number/format';
import { RechartsLinear } from '@/internal/charts/linear';
import { IProps, IPeriod, IChartData } from './types';
import { Charts, Head, Layout, Periods, Title, Value } from './styles';
import { Icon } from './Icon';

export const FinancePeriodsPriceChart = forwardRef<HTMLDivElement, IProps>(
  ({ isAppearAnimation, title, periods, chartProps, numberFormat }, ref) => {
    const [tabKey, setTabKey] = useState(periods[0].key);
    const [period, setPeriod] = useState<IPeriod | null>(null);

    const [activeData, setActiveData] =
      useState<IChartData | undefined>(undefined);
    const value = activeData?.value ?? period?.value ?? 0;
    const percent = activeData?.percent ?? period?.percent ?? 0;

    useEffect(() => {
      if (tabKey) {
        setPeriod(periods.find(({ key }) => key === tabKey) || null);
      }
    }, [periods, tabKey]);

    return (
      <Layout
        ref={ref}
        isAppearAnimation={isAppearAnimation}
        aria-label={title}
      >
        <Head>
          {title && (
            <Title>
              <Icon />
              <span>{title}</span>
            </Title>
          )}

          <Value>
            <span>
              <NumberFormat value={value} {...numberFormat} />
            </span>
            <NumberPercent value={percent} />
          </Value>

          {periods && periods.length > 1 && (
            <Periods
              items={periods}
              selectedKey={tabKey}
              onSelect={setTabKey}
            />
          )}
        </Head>

        <Charts onMouseLeave={() => setActiveData(undefined)}>
          {period && (
            <RechartsLinear
              bg={{
                colorStart: '#00FFF4',
                colorStartAlpha: 0.5,
                colorEnd: '#00FFF4',
                colorEndAlpha: 0,
              }}
              tooltip={{
                fill: '#00FFF4',
                text: '',
              }}
              axis={{ x: { hasLine: true }, y: { hasLine: true, dx: 4 } }}
              grid={{ x: false, y: false }}
              {...chartProps}
              isAppearAnimation={isAppearAnimation}
              data={period?.data}
              numberFormat={numberFormat}
              onActive={(data) => setActiveData(data as any)}
            />
          )}
        </Charts>
      </Layout>
    );
  }
);

FinancePeriodsPriceChart.displayName = 'FinancePeriodsPriceChart';
