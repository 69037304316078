import styled from 'styled-components';
import { BoxOutline } from '@/general/box/outline';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const Container = styled(BoxOutline)`
  background-color: var(--color-grey-1100);

  @media ${getMediaQuery('smMin')} {
    padding: 15px 27px;
  }
  @media ${getMediaQuery('xs')} {
    padding: 10px 20px;
  }
`;
