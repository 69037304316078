import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { IProps } from './types';
import { Container } from './styles';

export const BoxOutline = forwardRef<HTMLDivElement, IProps>(
  ({ children, ...tagProps }, ref) => {
    const domRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => domRef.current!);

    return (
      <Container ref={domRef} {...tagProps}>
        {children}
      </Container>
    );
  }
);
