import styled from 'styled-components';
import { ButtonSvgCircleFill } from '@/general/button/svg-circle-fill';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import arrow from './arrow.svg';

export const Button = styled(ButtonSvgCircleFill).attrs({
  tag: 'button',
  hasStaticFill: true,
  variant: 'gradient',
})`
  padding: 0 8px;
`;

export const Wrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Arrow = styled.span`
  display: block;
  margin-left: 11px;
  width: 11px;
  height: 7px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('${arrow}');
`;

export const IconWrapper = styled.span`
  position: relative;
  display: block;
  width: 28px;
  height: 28px;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Text = styled.span`
  margin-left: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-white);

  @media ${getMediaQuery('mdMin')} {
    display: block;
  }

  @media ${getMediaQuery('smMax')} {
    display: none;
  }
`;
