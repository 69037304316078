import {
  DependencyList,
  EffectCallback,
  RefObject,
  useEffect,
  useRef,
} from 'react';
import { NCallbacks } from 'vevet';
import { vevetApp } from './vevet';

interface ISettings extends NCallbacks.CallbackBaseSettings {
  isMobileOptimized?: boolean;
}

export function useOnResize(
  effect: EffectCallback,
  deps?: DependencyList,
  settings?: ISettings
) {
  const timeout = settings?.timeout ?? 100;
  const isMobileOptimized = settings?.isMobileOptimized ?? true;

  useEffect(() => {
    let destructor = effect();
    const viewportCallback = vevetApp.viewport.add(
      isMobileOptimized && vevetApp.isMobile ? 'w' : '',
      () => {
        destructor?.();
        destructor = effect();
      },
      {
        ...settings,
        timeout,
      }
    );
    return () => {
      viewportCallback.remove();
      destructor?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useOnElementResize(
  ref: RefObject<any>,
  effect: EffectCallback,
  deps?: DependencyList
) {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return undefined;
    }
    let destructor: ReturnType<EffectCallback> | null;
    const resizeObserver = new ResizeObserver(() => {
      destructor?.();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        destructor = effect();
      }, 100);
    });
    resizeObserver.observe(element);
    return () => {
      resizeObserver.disconnect();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      destructor?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
