import styled, { css } from 'styled-components';
import { getTransition } from '@/utils/styles/getTransition';
import { ButtonSvgCircleFill } from '@/general/button/svg-circle-fill';

export const Button = styled(ButtonSvgCircleFill).attrs({
  fullWidth: true,
  hasStaticFill: false,
  variant: 'gradient',
  size: 'medium',
})`
  height: 40px;
  font-size: 14px;
  font-weight: 500;

  border-radius: 8px;
  ${getTransition('box-shadow 0.5s')};

  ${({ isForceHover }) =>
    isForceHover &&
    css`
      box-shadow: 0 0 5px var(--color-blue);
    `}
`;
