import React, { FC } from 'react';
import { Button, Container, Info, Text } from './styles';
import { IProps } from './types';

export const BannerInfo: FC<IProps> = ({ text, button }) => (
  <Container role="region" aria-label={text}>
    <Info>
      <Text>{text}</Text>
    </Info>
    {button && <Button>{button}</Button>}
  </Container>
);
