import React, { FC, useEffect, useRef, useState } from 'react';
import { Timeline, utils } from 'vevet';
import { vevetApp } from '@/utils/vevet';
import { ITabContentItem } from './types';
import styles from './styles.module.scss';

export interface IItemProps extends ITabContentItem {
  state: 'prev' | 'next' | 'selected' | null;
  onShown: () => void;
  onHidden: () => void;
  duration: number;
}

export const Item: FC<IItemProps> = ({
  id,
  name,
  children,
  state,
  onShown,
  onHidden,
  duration,
}) => {
  const [parent, setParent] = useState<HTMLDivElement | null>(null);

  const appearStateRef = useRef<'show' | 'hide' | null>(null);
  const [appearState, setAppearState] = useState(appearStateRef.current);

  useEffect(() => {
    if (!parent || !state) {
      return undefined;
    }

    if (state === 'selected') {
      parent.style.position = 'relative';
      parent.style.opacity = '1';
      return undefined;
    }

    const tm = new Timeline({ duration });
    tm.addCallback('progress', (data) => {
      const itemProgress = utils.math.easing(
        state === 'prev'
          ? utils.math.clampScope(data.progress, [0, 0.5])
          : utils.math.clampScope(data.progress, [0.5, 1]),
        vevetApp.prop.easing
      );

      // update item opacity
      const opacity = state === 'prev' ? 1 - itemProgress : itemProgress;
      parent.style.opacity = `${opacity}`;

      // change position
      if (state === 'prev' && itemProgress >= 1) {
        parent.style.position = 'absolute';
      } else if (state === 'next' && itemProgress > 0) {
        parent.style.position = 'relative';
      }

      // update appear state
      if (
        state === 'prev' &&
        itemProgress > 0 &&
        appearStateRef.current !== 'hide'
      ) {
        appearStateRef.current = 'hide';
        setAppearState('hide');
      } else if (
        state === 'next' &&
        itemProgress > 0 &&
        appearStateRef.current !== 'show'
      ) {
        appearStateRef.current = 'show';
        setAppearState('show');
      }
    });
    tm.addCallback('end', () => {
      if (state === 'next') {
        onShown();
      } else {
        onHidden();
      }
    });
    tm.play();

    return () => {
      tm.destroy();
    };
  }, [parent, state, name, duration, onShown, onHidden]);

  if (!state) {
    return null;
  }

  return (
    <div
      ref={setParent}
      className={[
        'tab-content-item',
        styles.item,
        appearState === 'show' ? 'show' : '',
        appearState === 'hide' ? 'hide' : '',
      ].join(' ')}
      role="tabpanel"
      id={id}
      aria-label={name}
    >
      {children}
    </div>
  );
};
