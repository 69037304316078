import React, { FC, HTMLAttributes } from 'react';

export const Icon: FC<HTMLAttributes<SVGSVGElement>> = (props) => (
  <svg
     width="9.6834517mm"
     height="9.6834517mm"
     viewBox="0 0 9.6834516 9.6834516"
     version="1.1"
     id="svg1"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:svg="http://www.w3.org/2000/svg">
    <defs
       id="defs1" />
    <g
       id="layer1"
       transform="translate(-81.631869,-74.938082)">
      <circle
         style={{fill:"#000000",fillOpacity:1,stroke:"#00ffff",strokeWidth:"0.2",strokeDasharray:"none",strokeOpacity:"1"}}
         id="path2"
         cx="86.473595"
         cy="79.779808"
         r="4.7417259" />
      <path
         d="m 89.285585,77.133975 v 5.291667 h -1.99964 v -1.673993 c 0,-0.263075 -0.13036,-0.509084 -0.34806,-0.656801 l -0.16716,-0.113427 c -0.17939,-0.121735 -0.41487,-0.121735 -0.59424,0 l -0.16717,0.113427 c -0.2177,0.147717 -0.34807,0.393726 -0.34807,0.656801 v 1.673993 h -1.99964 v -5.290851 h 1.99964 v 1.96443 h 1.6247 v -1.965246 z"
         fill="#00ffff"
         id="path1"
         style={{strokeWidth:"0.264583"}} />
    </g>
  </svg>
);