import styled from 'styled-components';
import { ButtonSvgCircleFill } from '@/general/button/svg-circle-fill';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const Container = styled.div`
  box-sizing: border-box;
  background-color: var(--color-grey-800);
  color: var(--color-white);
  border-radius: 8px;
  border: 1px solid rgba(var(--color-blue--rgb), 0.4);
`;

export const Wrapper = styled.div`
  padding: 22px 20px 16px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  @media ${getMediaQuery('xs')} {
    @media (max-width: 370px) {
      align-items: flex-start;
      flex-direction: column;

      > * + * {
        margin-top: 10px;
      }
    }
  }
`;

export const UserRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const UserImg = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: linear-gradient(145.48deg, #fab446 18.8%, #7b61ff 132.99%);
`;

export const UserInfo = styled.div`
  margin-left: 16px;
`;

export const UserAddress = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const UserNetwork = styled.div`
  margin-top: -4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-grey-500);
`;

export const DisconnectButton = styled(ButtonSvgCircleFill).attrs({
  variant: 'gradient',
  hasStaticFill: false,
  size: 'small',
})``;

export const LinksWrapper = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  font-weight: 500;
  font-size: 12px;
  line-height: 21px;

  > * {
    margin-top: 10px;

    &:not(:last-child) {
      margin-right: 40px;
    }
  }
`;

export const BuyWrapper = styled.div`
  margin-top: 14px;
  margin-left: -1px;
  width: calc(100% + 2px);
`;
