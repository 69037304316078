import styled, { css } from 'styled-components';
import { FormCheckboxLabelled } from '@/general/form/checkbox/labelled/Labelled';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { IStepProps } from './types';

export const Title = styled.div`
  font-weight: 600;
  text-transform: capitalize;

  @media ${getMediaQuery('smMin')} {
    font-size: 28px;
    line-height: 42px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const Steps = styled.div`
  @media ${getMediaQuery('smMin')} {
    margin-top: 34px;
  }
  @media ${getMediaQuery('xs')} {
    margin-top: 14px;
  }
`;

export const Step = styled.div<IStepProps>`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  transition: opacity 0.5s;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.25;
      pointer-events: none;
    `}

  &:not(:last-child) {
    @media ${getMediaQuery('smMin')} {
      padding-bottom: 48px;
    }
    @media ${getMediaQuery('xs')} {
      padding-bottom: 28px;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 50%;
      margin-left: -1px;
      width: 0;
      height: 100%;
      box-sizing: content-box;
      border-left: 2px dashed #1e5150;

      @media ${getMediaQuery('smMin')} {
        left: 18px;
      }
      @media ${getMediaQuery('xs')} {
        left: 12px;
      }
    }
  }
`;

export const StepNum = styled.div`
  flex-shrink: 0;

  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: #1e5150;

  color: var(--color-white);
  font-weight: 600;

  @media ${getMediaQuery('smMin')} {
    width: 36px;
    height: 36px;
    margin-right: 28px;
    font-size: 16px;
  }
  @media ${getMediaQuery('xs')} {
    width: 24px;
    height: 24px;
    margin-right: 13px;
    font-size: 10px;
  }
`;

export const StepContent = styled.div`
  width: 100%;
  font-weight: 500;

  @media ${getMediaQuery('smMin')} {
    margin-top: 5px;
    font-size: 16px;
    line-height: 24px;
  }
  @media ${getMediaQuery('xs')} {
    margin-top: 2px;
    font-size: 14px;
    line-height: 22px;
  }

  a {
    color: var(--color-blue);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Checkbox = styled(FormCheckboxLabelled)`
  margin-top: 15px;

  @media ${getMediaQuery('smMin')} {
    font-size: 16px;
    line-height: 24px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const Wallets = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media ${getMediaQuery('lgMin')} {
    padding-right: 72px;
    grid-column-gap: 40px;
    grid-row-gap: 10px;
  }

  @media ${getMediaQuery('md')} {
    padding-right: 36px;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
  }

  @media ${getMediaQuery('smMax')} {
    grid-gap: 4px;
  }
`;
