import styled, { css, keyframes } from 'styled-components';
import { ButtonSimple } from '@/general/button/simple';
import { TokenPreview } from '..';
import { ISubmitProps, IStatusButtonWrapperProps, IStatusProps } from './types';

export const Preview = styled(TokenPreview)`
  position: relative;
`;

export const Rewards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const RewardsTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  color: var(--color-grey-400);
`;

export const RewardsValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--color-white);

  i {
    display: block;
    margin-left: 0.25em;
    font-style: normal;
    color: var(--color-blue);
  }
`;

export const Buttons = styled.div`
  margin-top: 20px;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
`;

export const Submit = styled.div<ISubmitProps>`
  transition: transform 0.5s;

  ${({ isHide }) =>
    isHide &&
    css`
      transform: scale(0.99);
      pointer-events: none;
    `}
`;

export const StatusButtonWrapper = styled.div<IStatusButtonWrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate(-101%, 0);
  transition: transform 0.5s;

  ${({ isShow }) =>
    isShow &&
    css`
      transform: translate(0, 0);
    `}
`;

export const StatusButton = styled(ButtonSimple).attrs({
  variant: 'gold',
  hasBorder: true,
  fullWidth: true,
})`
  padding: 0;

  > * {
    width: 100%;
    height: var(--button-size);
  }
`;

export const StatusContainer = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StatusConfirm = styled.span<IStatusProps>`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s;

  ${({ isActive }) =>
    !isActive &&
    css`
      transform: translate(0, -100%);
    `}
`;

export const StatusPending = styled.span<IStatusProps>`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  transform: translate(0, 100%);
  transition: transform 0.5s;

  ${({ isActive }) =>
    isActive &&
    css`
      transform: none;
    `}
`;

export const showSuccessKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const SuccessContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${showSuccessKeyframes} 0.5s ease-in-out;
  background-color: var(--color-black);

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
