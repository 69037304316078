import React, { forwardRef, useContext } from 'react';
import { LazyImage } from '@/internal/Image/Lazy';
import { Context } from '@/store/context';
import { ErrorIcon } from './ErrorIcon';
import { Arrow, Button, IconWrapper, Text, Wrapper } from './styles';
import { TProps } from './types';

export const ConnectNetworkButton = forwardRef<HTMLButtonElement, TProps>(
  ({ isAppearAnimation, icon, color, ...tagProps }, ref) => {
    const ctx = useContext(Context);
    const { connectNetwork } = ctx;
    const { wrongNetwork } = connectNetwork;

    const currentColor = wrongNetwork ? '#BC1010' : color;

    return (
      <Button
        ref={ref}
        {...tagProps}
        isAppearAnimation={isAppearAnimation}
        aria-label={wrongNetwork ? 'Wrong network' : 'Choose Network'}
        variantColors={
          currentColor
            ? {
                staticColor1: currentColor,
                staticColor2: currentColor,
                hoverColor1: '#14161A',
                hoverColor2: '#14161A',
                contentColor: '#fff',
                contentColorHover: '#fff',
              }
            : undefined
        }
      >
        <Wrapper>
          <IconWrapper>
            {wrongNetwork ? (
              <ErrorIcon />
            ) : (
              icon && (
                <LazyImage aria-hidden src={icon} alt="Network" pos="contain" />
              )
            )}
          </IconWrapper>
          {wrongNetwork && <Text aria-hidden>Wrong Network</Text>}
          <Arrow />
        </Wrapper>
      </Button>
    );
  }
);
ConnectNetworkButton.displayName = 'ConnectNetworkButton';
