import React, {
  cloneElement,
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Portal } from 'react-portal';
import { addEventListener, childOf } from 'vevet-dom';
import { useDOMRect } from '@/utils/domRect';
import styles from './index.module.scss';
import {
  getPopupAlignmentStyles,
  getPopupAutoAlignment,
  getPopupAutoDirection,
  getPopupDirectionStyles,
} from './utils';
import { IPopupHandle, IPopupProps } from './types';

export const Popup = forwardRef<IPopupHandle, IPopupProps>(
  (
    {
      trigger,
      rectRef,
      show,
      showEvent = 'click',
      direction: directionProp = 'auto',
      alignment: alignmentProp = 'auto',
      gap = 10,
      viewGap = 10,
      viewSizeRef,
      useStyles = {
        bg: true,
        padding: true,
      },
      children,
      id: idProp,
      className,
      style,
    },
    ref
  ) => {
    const dynamicID = useId();
    const id = idProp || dynamicID;

    // elements
    const triggerRef = useRef<HTMLElement>(null);
    const [popupRef, setPopupRef] = useState<HTMLDivElement | null>(null);

    // states
    const [render, setRender] = useState(false);
    const [isActive, setIsActive] = useState<boolean | undefined>(undefined);
    const isShown = !!(isActive || show);

    // allow render
    useEffect(() => {
      if (isShown) {
        setRender(true);
      }
    }, [isShown]);

    // sizes
    const triggerRect = useDOMRect({
      ref: rectRef || triggerRef,
      disabled: !render,
    });

    // pass ref
    useImperativeHandle(ref, () => ({
      close() {
        setIsActive(false);
      },
    }));

    // set outside click
    useEffect(() => {
      const outsideClick = addEventListener(window, 'click', (e) => {
        if (popupRef && triggerRef.current) {
          const target = e.target as HTMLElement;
          if (
            isActive &&
            !childOf(target, triggerRef.current) &&
            !childOf(target, popupRef)
          ) {
            setIsActive(false);
          }
        }
      });
      return () => {
        outsideClick.remove();
      };
    }, [isActive, popupRef]);

    // copy trigger
    const triggerChild = trigger
      ? cloneElement(trigger as any, {
          ref: triggerRef,
          id: `${id}-trigger`,
          'aria-controls': `${id}-content`,
          ...(showEvent === 'hover'
            ? {
                onMouseEnter: () => setIsActive(true),
                onMouseLeave: () => setIsActive(false),
                onFocus: () => setIsActive(true),
                onBlur: () => setIsActive(false),
              }
            : undefined),
          ...(showEvent === 'click'
            ? {
                onClick: () => setIsActive((val) => !val),
              }
            : undefined),
          'data-active': isShown || undefined,
        })
      : null;

    // calculate direction
    const direction =
      !directionProp || directionProp === 'auto'
        ? getPopupAutoDirection(triggerRect)
        : directionProp;
    const directionStyles =
      direction && triggerRect
        ? getPopupDirectionStyles({ direction, triggerRect, gap })
        : undefined;

    // calculate alignment
    const alignment =
      (alignmentProp === 'auto'
        ? getPopupAutoAlignment(triggerRect)
        : alignmentProp) || 'center';
    const alignmentStyles =
      direction && triggerRect
        ? getPopupAlignmentStyles({
            alignment,
            triggerRect,
            gap,
            viewGap,
            viewSizeRef,
          })
        : undefined;

    if (!direction) {
      return triggerChild;
    }

    return (
      <>
        {triggerChild}
        {render && (
          <Portal>
            <div
              ref={setPopupRef}
              id={`${id}-content`}
              className={styles.popup}
              role="dialog"
              aria-hidden={!isShown}
              aria-labelledby={`${id}-trigger`}
              style={{
                ...(directionStyles && {
                  top: directionStyles.top,
                  bottom: directionStyles.bottom,
                }),
                ...(alignmentStyles && {
                  left: alignmentStyles.left,
                  right: alignmentStyles.right,
                  maxWidth: alignmentStyles.maxWidth,
                }),
                transform: `translate(${alignmentStyles?.x || '0'}, ${
                  directionStyles?.y || '0'
                })`,
              }}
              {...(showEvent === 'hover'
                ? {
                    onMouseEnter: () => setIsActive(true),
                    onMouseLeave: () => setIsActive(false),
                  }
                : undefined)}
            >
              <div
                className={[
                  styles.animated,
                  isShown ? styles.show : '',
                  `direction_${direction}`,
                  `alignment_${alignment}`,
                  useStyles && useStyles.bg ? styles.bg : '',
                  useStyles && useStyles.padding ? styles.padding : '',
                  className,
                ].join(' ')}
                style={style}
                onAnimationEnd={() => {
                  if (!isShown) {
                    setRender(false);
                  }
                }}
              >
                {children}
              </div>
            </div>
          </Portal>
        )}
      </>
    );
  }
);
