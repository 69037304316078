import React, { FC, HTMLAttributes } from 'react';

export const Icon: FC<HTMLAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.10788 17.3437C5.34498 21.6428 11.3697 22.9424 16.1657 20.1734C17.2556 19.5456 18.2281 18.7334 19.0396 17.7732L10.7216 12.3706L2.10788 17.3437Z"
      fill="#00FFF4"
    />
    <path
      d="M13.4112 0.329691C10.6868 -0.35049 7.8005 0.0577616 5.36614 1.46764C0.570132 4.23662 -1.31695 10.1036 0.787842 15.0569L9.70034 9.91131L13.4112 0.329691Z"
      fill="#00FFF4"
    />
    <path
      d="M20.4873 15.5553C21.2706 13.9625 21.6497 12.2031 21.5914 10.4322C21.533 8.66129 21.0388 6.93335 20.1525 5.40079C19.1469 3.65088 17.667 2.22062 15.8823 1.27379L12.3875 10.2941L20.4873 15.5553Z"
      fill="#00FFF4"
    />
  </svg>
);
