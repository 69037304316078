import React, { FC, useCallback, useState } from 'react';
import { isNonNullable } from '@/utils/isNonNulable';
import { NumberFormat } from '@/general/number/format';
import { PreviewNetworkLabel } from '@/general/preview/network-label';
import { media } from '@/utils/media';
import { useOnResize } from '@/utils/resize';
import { PoolApy } from '@/composed/pool/Apy';
import { IPoolsListItemProps } from './types';
import {
  DepositButton,
  Item,
  MoreDetails,
  Pool,
  PoolIcon,
  PoolIconImage,
  PoolInfo,
  PoolName,
} from './styles';
import {
  ColumnApy,
  ColumnDeposit,
  ColumnLiquidity,
  ColumnMyLiquidity,
  ColumnNav,
  ColumnPool,
} from '../global';

export const PoolsListItem: FC<IPoolsListItemProps> = ({
  href,
  icon,
  name,
  network,
  liquidity,
  myLiquidity,
  apy,
}) => {
  const [networkLabelSize, setNetworkLabelSize] = useState<any>('large');

  const resize = useCallback(() => {
    if (media.isXS()) {
      setNetworkLabelSize('small');
    } else if (media.isSM()) {
      setNetworkLabelSize('medium');
    } else {
      setNetworkLabelSize('large');
    }
  }, []);
  useOnResize(() => {
    resize();
  }, [resize]);

  return (
    <Item aria-label={name}>
      <ColumnPool>
        <div>
          <Pool>
            <PoolIcon>
              {icon && <PoolIconImage src={icon} alt={name} aria-hidden />}
            </PoolIcon>
            <PoolInfo>
              <PoolName>{name}</PoolName>
              {network && (
                <PreviewNetworkLabel {...network} size={networkLabelSize} />
              )}
            </PoolInfo>
          </Pool>
        </div>
      </ColumnPool>

      <ColumnMyLiquidity aria-label="My liquidity">
        <div>
          {isNonNullable(myLiquidity) && (
            <NumberFormat value={myLiquidity} prefix="$" />
          )}
        </div>
      </ColumnMyLiquidity>

      <ColumnLiquidity aria-label="Liquidity">
        <div>
          {isNonNullable(liquidity) && (
            <NumberFormat value={liquidity} prefix="$" />
          )}
        </div>
      </ColumnLiquidity>

      <ColumnApy aria-label="APY">
        <div>{apy ? <PoolApy {...apy} /> : '-'}</div>
      </ColumnApy>

      <ColumnDeposit>
        <div>
          <DepositButton tag="a" href={href}>
            Deposit
          </DepositButton>
        </div>
      </ColumnDeposit>

      <ColumnNav>
        <div>
          <MoreDetails href={href} aria-label="More details">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.2461 8.91613C14.1017 8.77181 13.9059 8.69074 13.7018 8.69074C13.4977 8.69074 13.3019 8.77181 13.1575 8.91613L10.6226 11.4511L8.08757 8.91613C7.94238 8.7759 7.74792 8.69831 7.54608 8.70007C7.34424 8.70182 7.15116 8.78278 7.00843 8.92551C6.8657 9.06824 6.78474 9.26132 6.78299 9.46316C6.78123 9.665 6.85883 9.85946 6.99905 10.0046L10.0783 13.0839C10.2227 13.2282 10.4184 13.3093 10.6226 13.3093C10.8267 13.3093 11.0225 13.2282 11.1668 13.0839L14.2461 10.0046C14.3904 9.86028 14.4714 9.66451 14.4714 9.46039C14.4714 9.25626 14.3904 9.06049 14.2461 8.91613Z"
                fill="white"
              />
              <rect
                width="20"
                height="20"
                rx="10"
                transform="matrix(-1 0 0 1 20.6226 1)"
                stroke="white"
                strokeWidth="0.962264"
              />
            </svg>
          </MoreDetails>
        </div>
      </ColumnNav>
    </Item>
  );
};
