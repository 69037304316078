import styled from 'styled-components';
import { PreviewNetworkLabel } from '@/general/preview/network-label';
import { Icon as IconComponent } from './Icon';
import { IRowProps } from './types';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  padding: 16px 18px;

  > * {
    width: 100%;

    + * {
      margin-top: 32px;
    }
  }
`;

export const Row = styled.div<IRowProps>`
  display: flex;
  flex-direction: row;
  align-items: ${({ align }) => (align === 'start' ? 'flex-start' : 'center')};
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-grey-600);

  > * + * {
    margin-left: 8px;
  }
`;

export const Icon = styled(IconComponent)`
  width: 24px;
  height: auto;
`;

export const Network = styled(PreviewNetworkLabel).attrs({ size: 'large' })`
  padding: 0 6px;
`;

export const Value = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-white);
`;
