import React, { FC } from 'react';
import { Container, IconWrapper, Image } from './styles';
import bannerImage from './banner.png';

export const Banner: FC = () => (
  <Container>
    <Image src={bannerImage} alt="banner" />
    <IconWrapper>
      <div>
        <svg
          width="3"
          height="16"
          viewBox="0 0 3 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 1.71094V8.21427"
            stroke="white"
            strokeWidth="2.03125"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.5 14.7244H1.51333"
            stroke="white"
            strokeWidth="2.03125"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </IconWrapper>
  </Container>
);
