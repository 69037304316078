import React, { FC, useId } from 'react';
import { Image, ImageWrapper, Input, Label, Name } from './styles';
import { IProps } from './types';

export const WalletItem: FC<IProps> = ({
  keyVal,
  isSelected,
  onSelect,
  img,
  name,
}) => {
  const id = useId();

  return (
    <Label isSelected={isSelected} htmlFor={`${id}-wallet-${keyVal}`}>
      <Input
        type="radio"
        name={`${id}-wallet`}
        id={`${id}-wallet-${keyVal}`}
        onChange={(e) => {
          if (e.target.checked) {
            onSelect();
          }
        }}
        checked={isSelected}
      />
      <ImageWrapper>{img && <Image src={img} alt={name} />}</ImageWrapper>
      <Name>{name}</Name>
    </Label>
  );
};
