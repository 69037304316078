import styled from 'styled-components';
import { LazyImage } from '@/internal/Image/Lazy';
import icon from './icon.svg';

export const Link = styled.a`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-decoration: none;
  color: var(--color-blue);

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transition: transform 0.35s;
    transform: scale(0, 1);
  }

  &:hover {
    &::before {
      transform: scale(1, 1);
    }
  }
`;

export const Icon = styled(LazyImage).attrs({
  src: icon,
  pos: false,
})`
  margin-top: -0.16em;
  margin-left: 0.5em;
  width: 1.33em;
  height: auto;
`;
