import React, { forwardRef, PropsWithChildren, memo } from 'react';
import { isNonNullable } from '@/utils/isNonNulable';
import { useTimeoutPropState } from '@/utils/useTimeoutPropState';
import { BoxOutline } from '@/general/box/outline';
import { NumberCounter } from '@/general/number/counter';
import { NumberFormat } from '@/general/number/format';
import {
  Children,
  Container,
  Content,
  Head,
  Image,
  Info,
  Name,
  Network,
  Value,
  Wrapper,
} from './styles';
import { ITokenPreviewProps } from './types';

const WrapperFC = memo(
  ({ href, children }: PropsWithChildren<{ href?: string }>) => {
    if (href) {
      return (
        <Wrapper as="a" href={href}>
          {children}
        </Wrapper>
      );
    }
    return <Wrapper>{children}</Wrapper>;
  }
);

export const TokenPreview = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ITokenPreviewProps>
>(
  (
    {
      className,
      style,
      isAppearAnimation,
      href,
      isDisabled,
      size = 'medium',
      hasHover = false,
      img,
      name,
      value,
      network,
      children,
    },
    ref
  ) => {
    const isChildrenAppearAnimation = useTimeoutPropState(
      isAppearAnimation,
      !isAppearAnimation ? 0 : 500
    );

    return (
      <BoxOutline
        ref={ref}
        className={className}
        style={style}
        isAppearAnimation={isAppearAnimation}
        isDisabled={isDisabled}
        hasHover={hasHover}
      >
        <WrapperFC href={href}>
          <Container size={size}>
            <Head hasValue={typeof value !== 'undefined'}>
              <Info>
                {img && (
                  <Image
                    isAppearAnimation={isAppearAnimation}
                    size={size}
                    hasValue={typeof value !== 'undefined'}
                    src={img}
                    alt={name}
                    width="50"
                    height="50"
                  />
                )}
                <Content>
                  <Name size={size}>{name}</Name>
                  {isNonNullable(value) && (
                    <Value size={size}>
                      {typeof isChildrenAppearAnimation === 'boolean' ? (
                        <NumberCounter
                          animation={isChildrenAppearAnimation}
                          prefix="$"
                          value={value}
                        />
                      ) : (
                        <NumberFormat prefix="$" value={value} />
                      )}
                    </Value>
                  )}
                </Content>
              </Info>
              {network && (
                <Network
                  {...network}
                  size={size === 'small' ? 'medium' : size}
                />
              )}
            </Head>

            {children && <Children>{children}</Children>}
          </Container>
        </WrapperFC>
      </BoxOutline>
    );
  }
);
TokenPreview.displayName = 'TokenPreview';
