import React, { FC } from 'react';
import { Group, Link, Links, List, Title } from './styles';
import { IProps } from './types';

export const Menu: FC<IProps> = ({ groups }) => (
  <List aria-label="Footer Menu">
    {groups.map((group) => (
      <Group key={group.name}>
        <Title>{group.name}</Title>
        <Links>
          {group.links.map((link) => (
            <li key={link.name}>
              <Link
                href={link.href}
                target={link.isExternal ? '_blank' : ''}
                rel="noreferrer"
              >
                {link.name}
              </Link>
            </li>
          ))}
        </Links>
      </Group>
    ))}
  </List>
);
