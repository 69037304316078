import React, { FC, useEffect, useId, useRef } from 'react';
import { useHover } from '@/utils/useHover';
import { BgPlane, HoverOutline, StaticOutline, SvgElement } from './styles';
import { TSvgProps } from '../types';
import { useSvg } from './useSvg';

export const Svg: FC<TSvgProps> = ({
  isAppearAnimation,
  parentRef,
  contentRef,
  hasBg,
}) => {
  const id = useId();

  // elements
  const svgRef = useRef<SVGSVGElement>(null);
  const staticOutlineRef = useRef<SVGRectElement>(null);
  const hoverOutlineRef = useRef<SVGRectElement>(null);
  const bgPlaneRef = useRef<SVGRectElement>(null);

  // animation
  const { width, height, setHover } = useSvg({
    isAppearAnimation,
    parentRef,
    svgRef,
    staticOutlineRef,
    hoverOutlineRef,
    bgPlaneRef,
    contentRef,
  });

  // hover
  const isHovered = useHover(parentRef);
  useEffect(() => {
    setHover(isHovered);
  }, [isHovered, setHover]);

  return (
    <SvgElement
      ref={svgRef}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      aria-hidden
    >
      {/* static outline */}
      <StaticOutline
        ref={staticOutlineRef}
        x="1"
        y="1"
        width={width - 2}
        height={height - 2}
        rx={7}
        strokeWidth={2}
      />

      {/* background */}
      {hasBg && (
        <g mask={`url(#${id}-bg-mask)`}>
          <BgPlane
            ref={bgPlaneRef}
            x="1"
            y="1"
            width={width - 2}
            height={height - 2}
            rx={7}
            strokeWidth={2}
          />
        </g>
      )}

      {/* hover outline */}
      <HoverOutline
        ref={hoverOutlineRef}
        x="1"
        y="1"
        width={width - 2}
        height={height - 2}
        rx={7}
        strokeWidth={2}
      />

      <defs>
        {hasBg && (
          <mask id={`${id}-bg-mask`}>
            <rect
              x="1"
              y="1"
              width={width - 2}
              height={height - 2}
              rx={7}
              fill="#fff"
            />
          </mask>
        )}
      </defs>
    </SvgElement>
  );
};
