import React, { FC } from 'react';
import { Button } from './styles';
import { IActionProps } from './types';

export const Action: FC<IActionProps> = ({
  name,
  onClick,
  href,
  target,
  isDisabled,
  isHovered,
  hasHover,
  isAppearAnimation,
}) => {
  if (href) {
    return (
      <Button
        tag="a"
        href={href}
        target={target}
        disabled={isDisabled}
        isForceHover={isHovered && hasHover}
        isAppearAnimation={isAppearAnimation}
      >
        {name}
      </Button>
    );
  }

  return (
    <Button
      tag="button"
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      isForceHover={isHovered && hasHover}
      isAppearAnimation={isAppearAnimation}
    >
      {name}
    </Button>
  );
};
