import styled, { css } from 'styled-components';
import { IContainerProps } from './types';

export const Container = styled.div<IContainerProps>`
  position: absolute;
  width: 50%;
  height: 50%;

  opacity: 0;

  ${({ isShown }) =>
    isShown &&
    css`
      transition: opacity 0.2s;
      opacity: 1;
    `}

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
