export interface IUseAppearAnimation {
  use: boolean;
  on: boolean;
  shown: boolean;
}

export function useAppearAnimation(
  bool: boolean | undefined
): IUseAppearAnimation {
  return {
    use: typeof bool === 'boolean',
    on: !!bool,
    shown: typeof bool === 'boolean' ? bool : true,
  };
}
