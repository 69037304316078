import styled, { css } from 'styled-components';
import { CheckboxContainer } from '../styles';
import { ISharedProps } from './types';

export const Label = styled.label<ISharedProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  user-select: none;

  font-weight: 500;
  font-size: 14px;
  line-height: 120%;

  ${CheckboxContainer} {
    margin-left: 8px;

    ${({ isReversed }) =>
      isReversed &&
      css`
        order: -1;
        margin-left: 0;
        margin-right: 8px;
      `}
  }
`;
