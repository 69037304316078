import { RefObject, useEffect, useRef } from 'react';
import { addEventListener } from 'vevet-dom';

type TClickOutsideEvent = MouseEvent | TouchEvent;

export function useOutsideClick(
  ref: RefObject<HTMLElement>,
  callback: () => void
) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    const listener = (event: TClickOutsideEvent): void => {
      const { current: element } = ref;

      if (!element?.contains(event?.target as Node)) {
        callbackRef.current();
      }
    };

    const mouseDown = addEventListener(document, 'mousedown', listener);
    const pointerDown = addEventListener(document, 'mousedown', listener);

    return () => {
      mouseDown.remove();
      pointerDown.remove();
    };
  }, [ref]);
}
