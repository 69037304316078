import styled, { css } from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { ButtonSimple } from '@/general/button/simple';

const fullAbsolute = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Layout = styled.div`
  ${fullAbsolute}
`;

export const Background = styled.div`
  ${fullAbsolute}
  z-index: 0;
`;

export const Container = styled.div`
  ${fullAbsolute}
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;

  @media ${getMediaQuery('mdMax')} {
    justify-content: flex-end;
  }
  @media ${getMediaQuery('lgMin')} {
    justify-content: center;
  }

  @media ${getMediaQuery('mdMax')} {
    padding: 16px;
  }
  @media ${getMediaQuery('lg')} {
    padding: 20px;
  }
  @media ${getMediaQuery('xl')} {
    padding: 44px;
  }
  @media ${getMediaQuery('xxl')} {
    padding: 56px;
  }
`;

export const Title = styled.div`
  font-weight: 800;
  font-size: 46px;
  line-height: 52px;
  color: var(--color-white);

  @media ${getMediaQuery('xs')} {
    font-size: 42px;
    line-height: 46px;
  }
`;

export const Content = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${getMediaQuery('mdMax')} {
    flex-direction: row-reverse;
  }

  @media ${getMediaQuery('lgMin')} {
    flex-direction: column;
  }

  @media ${getMediaQuery('lg')} {
    position: absolute;
    bottom: 28px;
    right: 20px;
  }
  @media ${getMediaQuery('xl')} {
    position: absolute;
    bottom: 28px;
    right: 44px;
  }
  @media ${getMediaQuery('xxl')} {
    position: absolute;
    bottom: 28px;
    right: 56px;
  }

  > * {
    @media ${getMediaQuery('smMax')} {
      margin-right: 16px;
    }
    @media ${getMediaQuery('md')} {
      margin-right: 20px;
    }
    @media ${getMediaQuery('lgMin')} {
      margin-top: 20px;
    }
  }
`;

export const Button = styled(ButtonSimple).attrs({ size: 'medium' })`
  min-width: 192px;
  --button-size: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  @media ${getMediaQuery('xs')} {
    min-width: 162px;
    --button-size: 38px;
    font-size: 14px;
    line-height: 22px;
  }
  @media ${getMediaQuery('xxl')} {
    min-width: 224px;
  }
`;

export const Description = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-grey-500);

  @media ${getMediaQuery('xs')} {
    display: none;
  }

  @media ${getMediaQuery('mdMax')} {
    max-width: 7.4em;
  }
`;
