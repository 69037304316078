import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const Info = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const Head = styled.div``;

export const Column = styled.div``;

export const ColumnPool = styled(Column)``;

export const ColumnDeposited = styled(Column)``;

export const ColumnTVL = styled(Column)``;

export const ColumnRewards = styled(Column)``;

export const ColumnApy = styled(Column)``;

export const Body = styled.div`
  position: relative;

  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: var(--color-grey-50);

  @media ${getMediaQuery('lgMax')} {
    font-weight: 700;
  }

  @media ${getMediaQuery('smMin')} {
    font-size: 14px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 12px;
  }
`;

export const Title = styled.div`
  margin-bottom: 12px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: var(--color-grey-600);
  text-transform: uppercase;

  @media ${getMediaQuery('smMin')} {
    font-size: 10px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 8px;
  }
`;

export const Container = styled.div`
  @media ${getMediaQuery('xlMin')} {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 58px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      box-sizing: border-box;
      background-color: var(--color-grey-900);
      border: 1px solid var(--color-grey-700);
      border-radius: 8px;
    }

    ${Info} {
      position: relative;
      z-index: 1;
      display: table;
    }

    ${Head}, ${Body} {
      display: table-row;
    }

    ${Column} {
      display: table-cell;
      vertical-align: middle;
    }

    ${ColumnPool} {
      width: 33%;
    }

    ${ColumnDeposited}, ${ColumnTVL} {
      width: 16%;
    }

    ${ColumnRewards} {
      width: 15%;
    }

    ${ColumnApy} {
      width: 20%;
    }

    ${Head} {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: var(--color-white);
      opacity: 0.48;

      @media ${getMediaQuery('lgMax')} {
        display: none;
      }

      ${Column} {
        padding: 8px 10px 26px 0;
      }

      ${ColumnPool} {
        padding-left: 96px;
      }
    }

    ${Body} {
      ${Column} {
        padding: 16px 10px 16px 0;
      }

      ${ColumnPool} {
        padding-left: 20px;
      }
    }

    ${Title} {
      display: none;
    }
  }

  @media ${getMediaQuery('lgMax')} {
    ${Head} {
      display: none;
    }

    ${Body} {
      display: grid;
      grid-gap: 10px;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-right: 10px;

      @media ${getMediaQuery('lg')} {
        grid-template-columns: repeat(4, 1fr);
        padding-left: 10px;
      }

      @media ${getMediaQuery('mdMax')} {
        grid-template-columns: repeat(2, 1fr);

        @media ${getMediaQuery('smMin')} {
          padding-left: 68px;

          ${ColumnPool} {
            width: calc(100% + 68px);
            margin-left: -68px;
          }
        }

        @media ${getMediaQuery('xs')} {
          padding-left: 36px;

          ${ColumnPool} {
            width: calc(100% + 36px);
            margin-left: -36px;
          }
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        box-sizing: border-box;
        background-color: var(--color-grey-900);
        border: 1px solid var(--color-grey-700);
        border-radius: 8px;
      }

      > * {
        position: relative;
        z-index: 1;
      }

      ${Column} {
        position: relative;

        @media ${getMediaQuery('smMin')} {
          padding: 20px 16px 8px;
        }
        @media ${getMediaQuery('xs')} {
          padding: 10px 0 8px 16px;
        }

        // line
        @media ${getMediaQuery('smMin')} {
          &::after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: 1px;
            height: 100%;
            background-color: var(--color-grey-700);
          }

          @media ${getMediaQuery('lg')} {
            &:last-child {
              &::after {
                display: none;
              }
            }
          }
        }
      }

      ${ColumnPool} {
        padding-top: 0;
        padding-bottom: 0;

        @media ${getMediaQuery('lg')} {
          grid-column: 1 / 5;
        }
        @media ${getMediaQuery('mdMax')} {
          grid-column: 1 / 3;
        }

        @media ${getMediaQuery('smMin')} {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
        }
        @media ${getMediaQuery('xs')} {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
      }

      // order
      @media ${getMediaQuery('mdMax')} {
        ${ColumnDeposited} {
          order: 1;
        }

        ${ColumnRewards} {
          order: 2;
        }

        ${ColumnApy} {
          order: 3;
        }

        ${ColumnTVL} {
          order: 4;
        }
      }

      @media ${getMediaQuery('mdMax')} {
        ${ColumnRewards}, ${ColumnTVL} {
          &::after {
            display: none;
          }
        }
      }

      // line
      @media ${getMediaQuery('smMin')} {
        ${ColumnPool} {
          &::after {
            display: none;
          }
        }
      }
    }
  }
`;
