import styled, { css } from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { resetUl } from '@/utils/styles/resetUl';
import { TSharedProps } from './types';
import arrow from './arrow.svg';

export const List = styled.ol`
  ${resetUl}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  font-weight: 700;
  line-height: 20px;
  color: var(--color-grey-50);

  @media ${getMediaQuery('smMin')} {
    font-size: 16px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 10px;
  }
`;

export const Li = styled.li<TSharedProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  ${({ isActive }) =>
    !isActive &&
    css`
      &::after {
        content: '';
        display: block;
        margin-left: 8px;
        background-image: url('${arrow}');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;

        @media ${getMediaQuery('smMin')} {
          width: 24px;
          height: 24px;
        }
        @media ${getMediaQuery('xs')} {
          width: 20px;
          height: 20px;
        }
      }
    `}
`;

export const Link = styled.a<TSharedProps>`
  text-decoration: none;
  color: var(--color-grey-50);
  transition: color 0.25s;

  ${({ isActive }) =>
    isActive &&
    css`
      color: var(--color-blue);
    `}

  &:hover {
    color: var(--color-blue);
  }
`;
