import styled, { css } from 'styled-components';
import { getTransition } from '@/utils/styles/getTransition';
import { ButtonSvgCircleFill } from '@/general/button/svg-circle-fill';
import closeImage from './close.svg';
import { TSharedProps } from './types';

export const Wrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: var(--button-size);
  width: var(--button-size);
`;

export const DotsWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${getTransition('opacity 0.5s')}

  > * + * {
    margin-left: 4px;
  }
`;

export const Dot = styled.span<TSharedProps>`
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-blue);

  ${({ isAppearAnimation }) =>
    typeof isAppearAnimation === 'boolean' &&
    css`
      transform: translate(0, 10px);

      ${isAppearAnimation &&
      css`
        ${getTransition('transform 0.8s')}
        transform: translate(0, 0);

        &:nth-child(2) {
          transition-delay: 0.1s;
        }

        &:nth-child(1) {
          transition-delay: 0.2s;
        }
      `}
    `}
`;

export const CloseIcon = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-size: 18px auto;
  background-image: url('${closeImage}');
  background-position: center;
  background-repeat: no-repeat;

  ${getTransition('opacity 0.5s')}
  opacity: 0;
`;

export const Button = styled(ButtonSvgCircleFill).attrs({
  hasStaticFill: false,
  variant: 'gradient',
})`
  position: relative;
  padding: 0;
  height: var(--button-size);
  width: var(--button-size);

  &[data-active] {
    ${DotsWrapper} {
      opacity: 0;
    }

    ${CloseIcon} {
      opacity: 1;
    }
  }
`;
