import React, {
  Children,
  cloneElement,
  forwardRef,
  useId,
  useMemo,
  useState,
} from 'react';
import { SliderSwiperScroll } from '@/general/slider/swiper-scroll';
import { TabButtons } from '@/general/tab/buttons';
import { TabContent } from '@/general/tab/content';
import { EmptyBox } from './styles';
import styles from './styles.module.scss';
import { IProps } from './types';

export const TokenPreviewSlider = forwardRef<HTMLDivElement, IProps>(
  ({ tabs, className, style }, ref) => {
    const id = useId();

    const items = useMemo(
      () =>
        tabs.map(({ placeholder, ...tab }, index) => ({
          ...tab,
          id: `${id}-${index}`,
          children: (() => {
            const childStyle = { width: '230px', margin: '0 18px 0 0' };

            const count = Children.count(tab.children || []);
            if (count === 0) {
              return placeholder || null;
            }

            const emptyBoxes = Math.max(7 - count, 0);
            const boxes = Array.from({ length: emptyBoxes }, (t, i) => i + 1);

            const children = Children.map(tab.children, (child) =>
              cloneElement(child as any, {
                size: 'small',
                className: styles.item,
                style: childStyle,
              })
            );

            return (
              <SliderSwiperScroll spaceBetween={0} isStretchHeight>
                {children}
                {boxes.map((key) => (
                  <EmptyBox
                    key={key}
                    className={styles.item}
                    style={childStyle}
                  />
                ))}
              </SliderSwiperScroll>
            );
          })(),
        })),
      [id, tabs]
    );

    const [selectedKey, setSelectedKey] = useState(items[0] ? items[0].key : 0);

    return (
      <div ref={ref} style={style} className={className}>
        <TabButtons
          items={items}
          selectedKey={selectedKey}
          onSelect={setSelectedKey}
          styles="asterisknav"
        />
        <TabContent
          items={items}
          selectedKey={selectedKey}
          className={styles.content}
          duration={750}
        />
      </div>
    );
  }
);
TokenPreviewSlider.displayName = 'TokenPreviewSlider';
