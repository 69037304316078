import RcInputNumber from 'rc-input-number';
import React, {
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { usePropState } from '@/utils/usePropState';
import { isNumber } from '@/utils/number';
import { InputBox } from '../box';
import styles from './Number.module.scss';
import { MaxButton } from './styles';
import { TProps } from './types';

export const InputNumber = forwardRef<HTMLInputElement, TProps>(
  (
    {
      label,
      icon,
      error,
      value,
      defaultValue,
      numberFormat,
      onKeyUp,
      ...tagProps
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current!);

    const dynamicId = useId();
    const id = tagProps.id || dynamicId;

    const [isFocused, setIsFocused] = useState(false);
    const [momentValue, setMomentValue] = usePropState<typeof value>(value);
    const isFilled = value !== '' && value !== undefined && value !== null;

    // min max clamp
    const [showTooltipClamp, setShowTooltipClamp] = useState<boolean>(false);
    const [tooltipClampType, setTooltipClampType] =
      useState<'min' | 'max'>('min');
    useEffect(() => {
      const val = parseFloat(`${momentValue}`);
      if (!isNumber(val)) {
        setShowTooltipClamp(false);
        return;
      }
      const min = parseFloat(`${tagProps.min}`);
      const max = parseFloat(`${tagProps.max}`);
      if (isNumber(min) && val < min) {
        setTooltipClampType('min');
        setShowTooltipClamp(true);
      } else if (isNumber(max) && val > max) {
        setTooltipClampType('max');
        setShowTooltipClamp(true);
      } else {
        setShowTooltipClamp(false);
      }
    }, [momentValue, tagProps.max, tagProps.min]);

    return (
      <InputBox
        className={styles.wrapper}
        id={id}
        isFilled={isFilled}
        isFocused={isFocused}
        label={label}
        icon={icon}
        error={error}
        info={{
          show: showTooltipClamp && isFocused,
          message: (
            <>
              {tooltipClampType === 'min' ? 'Minimum' : ''}
              {tooltipClampType === 'max' ? 'Maximum' : ''} amount{' '}
              <b>
                {numberFormat?.prefix}
                {tooltipClampType === 'min' ? tagProps.min : ''}
                {tooltipClampType === 'max' ? tagProps.max : ''}
                {numberFormat?.suffix}
              </b>
            </>
          ),
        }}
      >
        <RcInputNumber
          ref={inputRef}
          {...tagProps}
          id={id}
          onFocus={(e) => {
            tagProps.onFocus?.(e);
            setIsFocused(true);
          }}
          onBlur={(e) => {
            tagProps.onBlur?.(e);
            setIsFocused(false);
          }}
          value={value}
          onKeyUp={(e) => {
            const { currentTarget } = e;
            const val = parseFloat(currentTarget.value);
            if (isNumber(val)) {
              setMomentValue(val);
            }
            onKeyUp?.(e);
          }}
        />
        {isNumber(parseFloat(`${tagProps.max}`)) && (
          <MaxButton
            tag="button"
            isHidden={!!error}
            aria-hidden
            onClick={() => {
              tagProps.onChange?.(parseFloat(`${tagProps.max}` || '0'));
            }}
          >
            Max
          </MaxButton>
        )}
      </InputBox>
    );
  }
);
