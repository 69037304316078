import styled, { css } from 'styled-components';
import { resetButtonStyles } from '@/utils/styles/resetButtonStyles';
import { resetScrollBars } from '@/utils/styles/resetScrollBars';
import { ISharedProps } from './types';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const Modal = styled.div`
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(var(--color-dark--rgb), 0.8);
`;

export const ModalContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ModalScrollWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  ${resetScrollBars}
  padding: 0 10px;
`;

export const ModalContent = styled.div<ISharedProps>`
  margin: 40px auto;
  width: 540px;
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;

  ${({ hasPadding }) =>
    hasPadding &&
    css`
      @media ${getMediaQuery('lgMin')} {
        padding: 40px;
      }
      @media ${getMediaQuery('md')} {
        padding: 30px;
      }
      @media ${getMediaQuery('smMax')} {
        padding: 24px 20px;
      }
    `}

  ${({ hasStyles }) =>
    hasStyles &&
    css`
      background-color: var(--color-grey-900);
      border: 1px solid var(--color-grey-800);
      border-radius: 8px;
    `}
`;

export const ModalCloseButton = styled.button<ISharedProps>`
  ${resetButtonStyles}
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
  }

  ${({ hasPadding }) =>
    hasPadding &&
    css`
      @media ${getMediaQuery('lgMin')} {
        top: 30px;
        right: 30px;
      }
      @media ${getMediaQuery('md')} {
        top: 20px;
        right: 20px;
      }
      @media ${getMediaQuery('smMax')} {
        top: 14px;
        right: 14px;
      }
    `}
`;
