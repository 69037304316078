import styled from 'styled-components';
import { BoxOutline } from '@/general/box';
import icon from './icon.svg';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const Container = styled(BoxOutline)`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;

  background-image: linear-gradient(to right, #040613, #240642);

  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 84px;

  @media ${getMediaQuery('xxl')} {
    padding-left: 52px;
    padding-right: 55px;
  }
  @media ${getMediaQuery('xl')} {
    padding-left: 42px;
    padding-right: 42px;
  }
  @media ${getMediaQuery('lg')} {
    padding-left: 42px;
    padding-right: 40px;
  }
  @media ${getMediaQuery('md')} {
    padding-left: 35px;
    padding-right: 33px;
  }
  @media ${getMediaQuery('sm')} {
    padding-left: 18px;
    padding-right: 16px;
  }
  @media ${getMediaQuery('xs')} {
    padding-left: 18px;
    padding-right: 16px;
  }

  color: var(--color-white);
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-weight: 600;

  @media ${getMediaQuery('mdMin')} {
    font-size: 20px;
    line-height: 36px;
  }
  @media ${getMediaQuery('sm')} {
    font-size: 18px;
    line-height: 36px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 14px;
    line-height: 20px;
  }

  &::before {
    flex-shrink: 0;
    content: '';
    display: block;
    position: relative;
    background-image: url('${icon}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;

    @media ${getMediaQuery('smMin')} {
      width: 48px;
      height: 48px;
      margin-right: 20px;
    }
    @media ${getMediaQuery('xs')} {
      width: 30px;
      height: 30px;
      margin-right: 12px;
    }
  }
`;

export const Text = styled.span`
  display: inline-block;

  @media ${getMediaQuery('xs')} {
    max-width: 8.1em;
  }
`;

export const Button = styled.div`
  flex-shrink: 0;
  white-space: nowrap;

  a,
  button {
    font-weight: 600;
    font-size: 14px;

    @media ${getMediaQuery('xxl')} {
      --button-size: 36px;
      width: 200px;
    }
    @media ${getMediaQuery('xlMax')} {
      @media ${getMediaQuery('smMin')} {
        --button-size: 36px;
        width: 160px;
      }
      @media ${getMediaQuery('xs')} {
        --button-size: 29px;
        --button-border-radius: 4px;
        padding: 0 14px;
        font-size: 11px;
      }
    }
  }
`;
