import React, { FC, PropsWithChildren } from 'react';
import { BoxHeading } from '@/general/box/heading';
import { Filters } from './styles';
import { IProps } from './types';

export const PoolsListBox: FC<PropsWithChildren<IProps>> = ({
  heading,
  filters,
  children,
}) => (
  <BoxHeading heading={heading} nav={<Filters>{filters}</Filters>}>
    {children}
  </BoxHeading>
);
