import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTimeoutPropState } from '@/utils/useTimeoutPropState';
import { useHover } from '@/utils/useHover';
import { TokenPreviewEarning } from '../earning';
import { ITokenPreviewEarningActionProps } from './types';
import { Action } from './Action';

export const TokenPreviewEarningAction = forwardRef<
  HTMLDivElement,
  ITokenPreviewEarningActionProps
>(({ isAppearAnimation, hasHover, size = 'medium', action, ...props }, ref) => {
  const domRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => domRef.current!);

  const isChildrenAppearAnimation = useTimeoutPropState(
    isAppearAnimation,
    !isAppearAnimation ? 0 : 500
  );

  const isHovered = useHover(domRef);

  return (
    <TokenPreviewEarning
      ref={domRef}
      {...props}
      hasHover={hasHover}
      isAppearAnimation={isAppearAnimation}
      size={size}
    >
      {action && (
        <Action
          {...action}
          isHovered={isHovered}
          hasHover={hasHover}
          isAppearAnimation={isChildrenAppearAnimation}
        />
      )}
    </TokenPreviewEarning>
  );
});

TokenPreviewEarningAction.displayName = 'TokenPreviewEarningAction';
