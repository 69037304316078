import styled, { css } from 'styled-components';
import { getTransition } from '@/utils/styles/getTransition';
import { ICircleProps } from './types';

export const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  opacity: 0;
  transform: rotate(-90deg);
`;

export const Circle = styled.circle<ICircleProps>`
  ${getTransition('opacity 0.35s')}

  ${({ isUnactive }) =>
    isUnactive &&
    css`
      opacity: 0.25;
    `}
`;
