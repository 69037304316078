import React, { FC } from 'react';
import { IconLunaFi } from '@/internal/icons/LunaFi';
import { NumberFormat } from '@/general/number/format';
import { PreviewNetworkLabel } from '@/general/preview/network-label';
import { LazyImage } from '@/internal/Image/Lazy';
import { IFarmItemInfoProps } from './types';
import {
  Body,
  ColumnApy,
  ColumnDeposited,
  ColumnPool,
  ColumnRewards,
  ColumnTVL,
  Container,
  Head,
  Info,
  Title,
} from './styles';
import styles from './Info.module.scss';

export const FarmItemInfo: FC<IFarmItemInfoProps> = ({
  icon,
  name,
  label,
  network,
  deposited,
  tvl,
  dailyRewards,
  apy,
}) => (
  <Container>
    <Info>
      <Head aria-hidden>
        <ColumnPool>Pool</ColumnPool>
        <ColumnDeposited>Deposited</ColumnDeposited>
        <ColumnTVL>TVL</ColumnTVL>
        <ColumnRewards>Rewards</ColumnRewards>
        <ColumnApy>APY</ColumnApy>
      </Head>

      <Body aria-label={name}>
        <ColumnPool aria-label="Pool">
          <div className={styles.pool}>
            <div className={styles.pool__icon}>
              {icon && (
                <LazyImage pos="contain" src={icon} alt={name} aria-hidden />
              )}
            </div>
            <div className={styles.pool__name}>{name}</div>
            {label && <div className={styles.pool__label}>{label}</div>}
            {network && (
              <PreviewNetworkLabel
                {...network}
                size="large"
                className={styles.pool__network}
              />
            )}
          </div>
        </ColumnPool>

        <ColumnDeposited aria-label="Deposited">
          <Title>Deposited</Title>
          {deposited ? <NumberFormat value={deposited} prefix="$" /> : '-'}
        </ColumnDeposited>

        <ColumnTVL aria-label="TVL">
          <Title>TVL</Title>
          {tvl ? <NumberFormat value={tvl} prefix="$" /> : '-'}
        </ColumnTVL>

        <ColumnRewards aria-label="Rewards">
          <Title>Rewards</Title>
          {dailyRewards ? (
            <div className={styles.daily_rewards}>
              <IconLunaFi className={styles.daily_rewards__icon} aria-hidden />
              <div className={styles.daily_rewards__content}>
                <div className={styles.daily_rewards__name}>LFI</div>
                <div className={styles.daily_rewards__value}>
                  <NumberFormat value={dailyRewards} /> LFI / D
                </div>
              </div>
            </div>
          ) : (
            '-'
          )}
        </ColumnRewards>

        <ColumnApy aria-label="APY">
          <Title>APY</Title>
          {apy ? (
            <div
              className={[
                styles.apy,
                apy > 0 ? styles.positive : styles.negative,
              ].join(' ')}
            >
              <span>
                <NumberFormat value={apy} suffix="%" hasSignPrefix />
              </span>
              <IconLunaFi className={styles.apy__icon} aria-hidden />
            </div>
          ) : (
            '-'
          )}
        </ColumnApy>
      </Body>
    </Info>
  </Container>
);
