import React, { FC, useState } from 'react';
import { useTimeoutPropState } from '@/utils/useTimeoutPropState';
import { useAppearAnimation } from '@/utils/useAppearAnimation';
import { vevetApp } from '@/utils/vevet';
import { Link } from './link/Link';
import { Item, List } from './styles';
import { TProps } from './types';

export const LayoutMenu: FC<TProps> = ({
  links,
  isAppearAnimation,
  isAdaptive,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const appearAnimation = useAppearAnimation(isAppearAnimation);

  const isActiveLinkAnimation = useTimeoutPropState(isAppearAnimation, 500);

  return (
    <List
      onMouseEnter={() => {
        if (!vevetApp.isMobile) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      role="navigation"
      aria-label="Navigation"
    >
      {links.map(({ isActive, ...link }, index) => (
        <Item
          key={link.key}
          style={{
            transitionDelay: `${
              appearAnimation.use && appearAnimation.on ? index * 0.15 : 0
            }s`,
          }}
          role="presentation"
          useAppearAnimation={appearAnimation.use}
          isAppearAnimationOn={appearAnimation.on}
        >
          <Link
            {...link}
            isActive={isActive && isActiveLinkAnimation}
            isUnactive={isHovered}
            isAdaptive={isAdaptive}
          />
        </Item>
      ))}
    </List>
  );
};
