import styled from 'styled-components';
import React, { forwardRef } from 'react';
import { TButtonProps } from './types';

const Styled = styled.button``;

export const Button = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  TButtonProps
>(({ tag, children, ...tagProps }, ref) => (
  <Styled
    ref={ref as any}
    as={tag}
    type={(tag === 'button' && !tagProps.type && 'button') || undefined}
    {...(tagProps as any)}
  >
    {children}
  </Styled>
));
Button.displayName = 'Button';
