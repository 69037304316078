import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { Title } from './Title';

export const StyledTitle = styled(Title)`
  display: block;
  height: auto;
  width: 220px;

  @media ${getMediaQuery('smMax')} {
    width: 176px;
  }
  @media ${getMediaQuery('md')} {
    width: 180px;
  }
`;
