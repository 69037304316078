import styled, { css } from 'styled-components';
import { getTransition } from '@/utils/styles/getTransition';
import { resetUl } from '@/utils/styles/resetUl';
import { TSharedProps } from './types';

export const List = styled.ul`
  ${resetUl}
  width: 100%;
`;

export const Item = styled.li<TSharedProps>`
  ${({ useAppearAnimation, isAppearAnimationOn }) =>
    useAppearAnimation &&
    css`
      opacity: 0;
      transform: translate(0, 2rem);

      ${isAppearAnimationOn &&
      css`
        ${getTransition('opacity 1s, transform 1s')}
        opacity: 1;
        transform: none;
      `}

      ${!isAppearAnimationOn &&
      css`
        transition: 0s !important;
      `}
    `}
`;
