import React, {
  FC,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  CartesianGrid,
} from 'recharts';
import { useAppearAnimation } from '@/utils/useAppearAnimation';
import { formatNumber } from '@/utils/number';
import { useOnElementResize } from '@/utils/resize';
import { Tooltip } from './tooltip';
import { IProps } from './types';
import { Layout, Wrapper } from './styles';

export const Recharts: FC<IProps> = ({
  isAppearAnimation,
  data,
  tooltip,
  onActive,
  grid = true,
  axis = true,
  numberFormat,
  render,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const appearAnimation = useAppearAnimation(isAppearAnimation);
  const dx =
    typeof axis === 'object' && typeof axis.y === 'object'
      ? axis.y.dx ?? 28
      : 28;

  // get current data
  const getCurrentData = useCallback(
    (reset: boolean, duration: number) => ({
      duration,
      data: reset ? data.map((item) => ({ ...item, value: 0 })) : [...data],
    }),
    [data]
  );

  // chart data
  // use zero values when animation needed
  const [currentData, setCurrentData] = useState(
    getCurrentData(appearAnimation.use || false, 0)
  );

  // change data on animation launch
  const dataChanges = useRef(0);
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (appearAnimation.use) {
      if (appearAnimation.on) {
        timeout = setTimeout(
          () => {
            setCurrentData(getCurrentData(false, 1000));
          },
          dataChanges.current > 0 ? 0 : 250
        );
        dataChanges.current += 1;
      } else {
        setCurrentData(getCurrentData(true, 0));
      }
    } else {
      setCurrentData(getCurrentData(false, dataChanges.current > 0 ? 500 : 0));
      dataChanges.current += 1;
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [appearAnimation.on, appearAnimation.use, getCurrentData]);

  // sizes
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  /**
   * Resize the scene
   */
  const resize = useCallback(() => {
    if (!parentRef.current) {
      return;
    }
    setWidth(parentRef.current.clientWidth);
    setHeight(parentRef.current.clientHeight);
  }, []);

  useOnElementResize(
    parentRef,
    () => {
      resize();
    },
    [resize]
  );

  useEffect(() => {
    if (appearAnimation.use && appearAnimation.on) {
      resize();
    }
  }, [appearAnimation.on, appearAnimation.use, resize]);

  // get axis settings
  const axisSettings = useMemo(() => {
    if (typeof axis === 'boolean') {
      if (!axis) {
        return null;
      }
      return {
        x: { hasLine: true },
        y: { hasLine: true },
      };
    }
    return axis;
  }, [axis]);

  const memoElements = useMemo(
    () => (
      <>
        {grid && (
          <CartesianGrid
            strokeDasharray="2 4"
            vertical={grid === true || grid.y}
            horizontal={grid === true || grid.x}
            stroke="rgba(255, 255, 255, 0.2)"
          />
        )}
        <RechartsTooltip
          animationDuration={100}
          cursor={false}
          offset={0}
          content={
            <Tooltip
              tooltipText={tooltip?.text}
              tooltipColor={tooltip?.fill || ''}
              onActive={(dataItem) => {
                if (onActive) {
                  onActive(dataItem);
                }
              }}
              numberFormat={numberFormat}
            />
          }
        />
        {axisSettings && (
          <>
            {axisSettings.x && (
              <XAxis
                dataKey="label"
                axisLine
                tickLine={false}
                interval="preserveStartEnd"
                minTickGap={10}
                fontSize={14}
                fontWeight={500}
                stroke="var(--color-grey-600)"
                strokeWidth={axisSettings.x.hasLine ? 1 : 0}
                dy={5}
              />
            )}
            {axisSettings.y && (
              <YAxis
                axisLine
                tickLine={false}
                minTickGap={22}
                fontSize={14}
                fontWeight={500}
                stroke="var(--color-grey-600)"
                strokeWidth={axisSettings.y.hasLine ? 1 : 0}
                tickFormatter={(val) => formatNumber(val)}
                tickCount={40}
                dx={dx * -1}
              />
            )}
          </>
        )}
      </>
    ),
    [
      axisSettings,
      grid,
      numberFormat,
      onActive,
      tooltip?.fill,
      tooltip?.text,
      dx,
    ]
  );

  return (
    <Layout
      appearAnimation={appearAnimation}
      onMouseLeave={() =>
        setTimeout(() => {
          if (onActive) {
            onActive(null);
          }
        }, 100)
      }
    >
      <Wrapper
        ref={parentRef}
        style={{
          width: axisSettings?.y ? `calc(100% - ${dx}px)` : '',
          left: axisSettings?.y ? `${dx}px` : '',
        }}
      >
        {render({
          width,
          height,
          children: memoElements,
          data: currentData,
        })}
      </Wrapper>
    </Layout>
  );
};
