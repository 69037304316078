import React, { FC } from 'react';
import { IconLunaFi } from '@/internal/icons/LunaFi';
import { NumberFormat } from '@/general/number/format';
import styles from './Amount.module.scss';
import { Label } from './Label';

interface IProps {
  title: string;
  value: number;
  isNew: boolean;
}

export const Amount: FC<IProps> = ({ title, value, isNew }) => (
  <div className={styles.container}>
    <div className={styles.column}>
      <div className={styles.title}>{title}</div>
      <div className={styles.item}>
        <div className={styles.token}>
          <IconLunaFi className={styles.token__icon} useOutline />
          LFI
          <Label isNew={isNew} className={styles.token__label} />
        </div>
      </div>
    </div>

    <div className={styles.column}>
      <div className={styles.title}>Amount</div>
      <div className={styles.item}>
        <div className={styles.value}>
          <NumberFormat value={value} suffix=" LFI" rightFixed={2} />
        </div>
      </div>
    </div>
  </div>
);
