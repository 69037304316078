import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { BoxOutline } from '@/general/box/outline';
import { TabButtons } from '@/general/tab/buttons';

export const Layout = styled(BoxOutline)`
  @media ${getMediaQuery('smMin')} {
    padding: 24px;
  }
  @media ${getMediaQuery('xs')} {
    padding: 24px 8px 24px 16px;
  }
`;

export const Head = styled.div`
  position: relative;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-weight: 500;
  line-height: 24px;

  @media ${getMediaQuery('smMin')} {
    font-size: 16px;
    color: var(--color-grey-500);

    > * + * {
      margin-left: 8px;
    }
  }
  @media ${getMediaQuery('xs')} {
    font-size: 18px;
    color: var(--color-grey-600);

    > * + * {
      margin-left: 16px;
    }
  }
`;

export const Value = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-weight: 600;
  color: var(--color-white);

  @media ${getMediaQuery('smMin')} {
    margin-top: 16px;
    font-size: 32px;
    line-height: 42px;

    > * + * {
      margin-left: 16px;
    }
  }
  @media ${getMediaQuery('xs')} {
    margin-top: 22px;
    font-size: 20px;
    line-height: 28px;

    > * + * {
      margin-left: 32px;
    }
  }
`;

export const Periods = styled(TabButtons).attrs({
  styles: 'group',
})`
  @media ${getMediaQuery('smMin')} {
    position: absolute;
    top: 0;
    right: 0;
  }
  @media ${getMediaQuery('xs')} {
    margin-top: 20px;
  }
`;

export const Charts = styled.div`
  position: relative;
  height: 316px;

  @media ${getMediaQuery('smMin')} {
    margin-top: 32px;
  }
  @media ${getMediaQuery('xs')} {
    margin-top: 28px;
    height: 228px;
  }
`;
