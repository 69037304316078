import React, { FC } from 'react';
import { useTimeoutPropState } from '@/utils/useTimeoutPropState';
import { useAppearAnimation } from '@/utils/useAppearAnimation';
import { ConnectGrid } from '@/composed/connect/grid';
import { LinksSocialMenu } from '@/general/links/social-menu';
import { useStoreAPI } from '@/store/api';
import { IProps } from './types';
import {
  Container,
  ChildrenWrapper,
  Content,
  Logo,
  MenuButton,
  Title,
} from './styles';

export const Top: FC<IProps> = ({ isAppearAnimation, title, social }) => {
  const api = useStoreAPI();

  const containerAppearAnimation = useAppearAnimation(isAppearAnimation);

  const isChildrenAppearAnimation = useTimeoutPropState(
    isAppearAnimation,
    1000
  );
  const childrenAppearAnimation = useAppearAnimation(isChildrenAppearAnimation);

  return (
    <Container appearAnimation={containerAppearAnimation}>
      <Logo
        appearAnimation={childrenAppearAnimation}
        href="/"
        aria-label={title}
      />
      <Content>
        <Title appearAnimation={childrenAppearAnimation}>{title}</Title>
        <ChildrenWrapper>
          <ConnectGrid isAppearAnimation={isChildrenAppearAnimation} />
          {social && (
            <LinksSocialMenu
              isAppearAnimation={isChildrenAppearAnimation}
              {...social}
            />
          )}
        </ChildrenWrapper>
      </Content>
      <MenuButton
        type="button"
        aria-label="Show navigation"
        onClick={() => api.toggleNavigationModal(true)}
        appearAnimation={childrenAppearAnimation}
      />
    </Container>
  );
};
