import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media ${getMediaQuery('xlMin')} {
    height: 54px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: var(--color-white);
  }

  @media ${getMediaQuery('lgMax')} {
    padding-bottom: 12px;
    font-weight: 600;
    color: var(--color-grey-400);

    @media ${getMediaQuery('smMin')} {
      font-size: 12px;
      line-height: 20px;
    }

    @media ${getMediaQuery('xs')} {
      font-size: 10px;
      line-height: 18px;
    }
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  @media ${getMediaQuery('xlMin')} {
    margin-top: 24px;
  }

  @media ${getMediaQuery('lgMax')} {
    @media ${getMediaQuery('smMin')} {
      margin-top: 30px;
    }
    @media ${getMediaQuery('xs')} {
      margin-top: 24px;
    }
  }
`;
