import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { InputNumber } from '@/general/input/number';
import { NumberFormat } from '@/general/number/format';
import { ButtonsWrapper, Head, InputWrapper } from './styles';
import { IFarmItemFormHandle, TProps } from './types';

export const FarmItemForm = forwardRef<IFarmItemFormHandle, TProps>(
  ({ label, balance, min, max, icon, renderButton, onSubmit }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState('');

    useImperativeHandle(ref, () => ({
      getInputValue: () =>
        inputRef.current && inputRef.current.value.length > 0
          ? parseFloat(inputRef.current.value || '0')
          : undefined,
    }));

    return (
      <form
        action="/"
        onSubmit={(evt) => {
          evt.preventDefault();
          onSubmit(value.length > 0 ? parseFloat(value) : undefined);
        }}
      >
        <Head>
          Balance: <NumberFormat value={balance} suffix={` ${label}`} />
        </Head>
        <InputWrapper>
          <InputNumber
            ref={inputRef}
            label="Enter Amount"
            icon={icon}
            min={min}
            max={max}
            value={value}
            onChange={(val) => {
              setValue(`${val ?? ''}`);
            }}
            numberFormat={{
              suffix: ' LFI',
            }}
          />
        </InputWrapper>
        <ButtonsWrapper>
          {renderButton(value.length > 0 ? parseFloat(value) : undefined)}
        </ButtonsWrapper>
      </form>
    );
  }
);
