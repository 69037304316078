import React, { FC, useId } from 'react';
import { BarChart, Bar } from 'recharts';
import { Recharts } from '../recharts';
import { IRechartsBarProps } from './types';

export const RechartsBar: FC<IRechartsBarProps> = ({
  fill = {
    colorStart: '#00FFF4',
    colorStartAlpha: 1,
    colorEnd: '#8730CB',
    colorEndAlpha: 1,
  },
  ...rechartsProps
}) => {
  const id = useId();

  return (
    <Recharts
      {...rechartsProps}
      render={({ width, height, data, children }) => (
        <BarChart
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          width={width}
          height={height}
          data={data.data}
        >
          {children}
          <defs>
            {fill && (
              <linearGradient
                id={`${id}_fill`}
                x1="0"
                y1="0"
                x2="0"
                y2={height * 0.9}
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0"
                  stopColor={fill.colorStart}
                  stopOpacity={fill.colorStartAlpha}
                />
                <stop
                  offset="1"
                  stopColor={fill.colorEnd}
                  stopOpacity={fill.colorEndAlpha}
                />
              </linearGradient>
            )}
          </defs>
          <Bar
            dataKey="value"
            fill={fill ? `url('#${id}_fill')` : ''}
            animationDuration={data.duration}
          />
        </BarChart>
      )}
    />
  );
};
