import React, { FC } from 'react';
import { ITitleProps } from './types';

export const Title: FC<ITitleProps> = ({ className, style }) => (
  <svg
    className={className}
    style={style}
    width="220"
    height="95"
    viewBox="0 0 220 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7108 0.499671H30.3946L26.2307 25.8044C25.8273 28.1719 24.9317 30.236 23.5437 31.9968C22.1557 33.7575 20.4 35.1256 18.2765 36.1012C16.153 37.0648 13.7981 37.5467 11.212 37.5467C8.94613 37.5467 6.94719 37.1481 5.21518 36.351C3.48317 35.5539 2.20195 34.3167 1.37154 32.6392C0.541119 30.9617 0.345378 28.8143 0.784312 26.197H9.53928C9.42065 27.1488 9.46217 27.9518 9.66385 28.6061C9.87738 29.2605 10.2392 29.7542 10.7493 30.0873C11.2713 30.4204 11.9416 30.587 12.7601 30.587C13.6143 30.587 14.3616 30.4026 15.0022 30.0338C15.6547 29.665 16.1945 29.1177 16.6216 28.392C17.0605 27.6663 17.3749 26.78 17.5647 25.733L21.7108 0.499671Z"
      fill="#FFE221"
    />
    <path
      d="M66.8064 19.3444C66.1421 23.2823 64.8372 26.6075 62.8916 29.3199C60.9579 32.0205 58.6031 34.0668 55.8271 35.4588C53.063 36.8507 50.0913 37.5467 46.912 37.5467C43.5548 37.5467 40.6483 36.7972 38.1926 35.2981C35.737 33.7991 33.9516 31.6161 32.8364 28.7489C31.7332 25.8817 31.5196 22.4019 32.1958 18.3094C32.8364 14.3715 34.1236 11.0403 36.0573 8.31594C38.0028 5.59155 40.3695 3.52744 43.1573 2.1236C45.957 0.707866 48.9584 0 52.1614 0C55.495 0 58.3836 0.755454 60.8274 2.26636C63.2831 3.77727 65.0626 5.97225 66.1658 8.85131C67.2691 11.7304 67.4826 15.2281 66.8064 19.3444ZM57.9981 18.3094C58.3658 16.0132 58.3658 14.08 57.9981 12.5096C57.6422 10.9392 56.9423 9.75547 55.8983 8.95838C54.8544 8.14939 53.496 7.74489 51.8233 7.74489C49.9727 7.74489 48.3 8.20292 46.8052 9.11899C45.3224 10.0232 44.0827 11.3437 43.0862 13.0807C42.0897 14.8057 41.3957 16.8936 41.0042 19.3444C40.6008 21.6643 40.589 23.5975 40.9686 25.1441C41.3601 26.6907 42.0897 27.8566 43.1573 28.6418C44.225 29.4151 45.5833 29.8018 47.2323 29.8018C49.0711 29.8018 50.726 29.3556 52.197 28.4634C53.668 27.5711 54.8959 26.2684 55.8805 24.5552C56.877 22.8421 57.5829 20.7601 57.9981 18.3094Z"
      fill="#FFE221"
    />
    <path
      d="M83.4706 0.499671L77.4204 37.047H68.6121L74.6623 0.499671H83.4706Z"
      fill="#FFE221"
    />
    <path
      d="M119.616 0.499671L113.565 37.047H106.092L95.1123 15.9538H94.8632L91.3576 37.047H82.5493L85.5743 18.7733L88.5994 0.499671H96.18L107.07 21.5572H107.373L110.825 0.499671H119.616Z"
      fill="#FFE221"
    />
    <path
      d="M13.235 94.3683H0V57.9906H13.2175C16.8657 57.9906 20.0069 58.7189 22.6411 60.1754C25.2869 61.6201 27.3266 63.7042 28.7603 66.4278C30.1939 69.1396 30.9107 72.3842 30.9107 76.1617C30.9107 79.951 30.1939 83.2075 28.7603 85.9311C27.3383 88.6547 25.3044 90.7448 22.6585 92.2013C20.0127 93.646 16.8715 94.3683 13.235 94.3683ZM8.65431 86.8725H12.9028C14.9076 86.8725 16.6034 86.5291 17.9905 85.8423C19.3891 85.1436 20.444 84.0127 21.155 82.4496C21.8776 80.8747 22.2389 78.7787 22.2389 76.1617C22.2389 73.5447 21.8776 71.4606 21.155 69.9093C20.4323 68.3462 19.3658 67.2212 17.9555 66.5344C16.5568 65.8357 14.8318 65.4864 12.7804 65.4864H8.65431V86.8725Z"
      fill="white"
    />
    <path d="M44.2239 57.9906V94.3683H35.5696V57.9906H44.2239Z" fill="white" />
    <path
      d="M68.6172 68.8968C68.5006 67.5942 67.9819 66.5818 67.0611 65.8594C66.152 65.1252 64.8524 64.7582 63.1623 64.7582C62.0434 64.7582 61.1109 64.9062 60.365 65.2022C59.619 65.4983 59.0596 65.9068 58.6866 66.4278C58.3136 66.937 58.1213 67.5232 58.1096 68.1863C58.0863 68.731 58.1912 69.2106 58.4243 69.6251C58.6691 70.0395 59.0188 70.4066 59.4733 70.7264C59.9396 71.0343 60.499 71.3066 61.1517 71.5434C61.8045 71.7803 62.5388 71.9875 63.3547 72.1651L66.4317 72.8756C68.2034 73.2664 69.7653 73.7874 71.1173 74.4387C72.481 75.09 73.6233 75.8657 74.5441 76.7656C75.4765 77.6656 76.1817 78.7017 76.6596 79.8741C77.1374 81.0464 77.3822 82.3608 77.3939 83.8174C77.3822 86.1146 76.8111 88.0863 75.6805 89.7323C74.5499 91.3783 72.9239 92.6394 70.8026 93.5157C68.6929 94.392 66.1462 94.8301 63.1623 94.8301C60.1668 94.8301 57.556 94.3742 55.3298 93.4624C53.1035 92.5506 51.3727 91.1651 50.1372 89.306C48.9017 87.4468 48.2664 85.0963 48.2315 82.2543H56.5186C56.5886 83.4266 56.8974 84.4035 57.4453 85.1851C57.9931 85.9666 58.7449 86.5587 59.7006 86.9613C60.668 87.3639 61.787 87.5652 63.0574 87.5652C64.223 87.5652 65.2137 87.4054 66.0296 87.0857C66.8572 86.7659 67.4924 86.3219 67.9353 85.7535C68.3782 85.1851 68.6055 84.5338 68.6172 83.7996C68.6055 83.1128 68.3957 82.5266 67.9878 82.0411C67.5798 81.5437 66.9504 81.1174 66.0996 80.7622C65.2604 80.3951 64.188 80.0576 62.8826 79.7497L59.1411 78.8616C56.0407 78.1393 53.5989 76.9729 51.8156 75.3624C50.0323 73.7401 49.1464 71.5494 49.1581 68.7903C49.1464 66.5403 49.7409 64.5687 50.9414 62.8753C52.1419 61.182 53.8029 59.8616 55.9242 58.9143C58.0455 57.9669 60.4641 57.4933 63.1798 57.4933C65.9539 57.4933 68.3607 57.9729 70.4005 58.932C72.4519 59.8794 74.0429 61.2116 75.1735 62.9286C76.3041 64.6457 76.881 66.6351 76.9043 68.8968H68.6172Z"
      fill="white"
    />
    <path
      d="M113.873 71.1704H105.131C105.014 70.2586 104.775 69.4356 104.414 68.7014C104.053 67.9673 103.575 67.3396 102.98 66.8186C102.386 66.2976 101.681 65.9009 100.865 65.6285C100.061 65.3443 99.169 65.2022 98.1899 65.2022C96.4532 65.2022 94.9555 65.6344 93.6967 66.4989C92.4495 67.3633 91.4879 68.6126 90.8119 70.2468C90.1475 71.8809 89.8154 73.8585 89.8154 76.1795C89.8154 78.5952 90.1534 80.6201 90.8294 82.2543C91.5171 83.8766 92.4787 85.1022 93.7142 85.9311C94.9613 86.7482 96.4358 87.1567 98.1375 87.1567C99.0932 87.1567 99.9616 87.0324 100.743 86.7837C101.535 86.535 102.229 86.1739 102.823 85.7002C103.429 85.2147 103.924 84.6285 104.309 83.9417C104.705 83.243 104.979 82.4556 105.131 81.5793L113.873 81.6326C113.721 83.243 113.261 84.8298 112.491 86.3929C111.734 87.956 110.691 89.383 109.362 90.6737C108.033 91.9526 106.413 92.971 104.501 93.7289C102.602 94.4867 100.422 94.8657 97.9626 94.8657C94.7224 94.8657 91.8201 94.1433 89.2559 92.6986C86.7033 91.2421 84.6869 89.1224 83.2066 86.3396C81.7263 83.5568 80.9862 80.1701 80.9862 76.1795C80.9862 72.177 81.738 68.7843 83.2416 66.0015C84.7452 63.2187 86.7791 61.105 89.3433 59.6603C91.9075 58.2156 94.7807 57.4933 97.9626 57.4933C100.131 57.4933 102.135 57.8012 103.977 58.4169C105.819 59.0209 107.439 59.909 108.837 61.0813C110.236 62.2418 111.372 63.6687 112.247 65.3621C113.121 67.0554 113.663 68.9916 113.873 71.1704Z"
      fill="white"
    />
    <path
      d="M152.226 76.1795C152.226 80.182 151.469 83.5746 149.954 86.3574C148.438 89.1402 146.387 91.2539 143.799 92.6986C141.223 94.1433 138.333 94.8657 135.128 94.8657C131.911 94.8657 129.014 94.1374 126.438 92.6809C123.862 91.2243 121.817 89.1106 120.302 86.3396C118.798 83.5568 118.046 80.1701 118.046 76.1795C118.046 72.177 118.798 68.7843 120.302 66.0015C121.817 63.2187 123.862 61.105 126.438 59.6603C129.014 58.2156 131.911 57.4933 135.128 57.4933C138.333 57.4933 141.223 58.2156 143.799 59.6603C146.387 61.105 148.438 63.2187 149.954 66.0015C151.469 68.7843 152.226 72.177 152.226 76.1795ZM143.38 76.1795C143.38 73.8111 143.048 71.8099 142.383 70.1757C141.73 68.5416 140.786 67.3041 139.551 66.4634C138.327 65.6226 136.853 65.2022 135.128 65.2022C133.414 65.2022 131.94 65.6226 130.704 66.4634C129.469 67.3041 128.519 68.5416 127.854 70.1757C127.202 71.8099 126.875 73.8111 126.875 76.1795C126.875 78.5478 127.202 80.5491 127.854 82.1832C128.519 83.8174 129.469 85.0548 130.704 85.8956C131.94 86.7363 133.414 87.1567 135.128 87.1567C136.853 87.1567 138.327 86.7363 139.551 85.8956C140.786 85.0548 141.73 83.8174 142.383 82.1832C143.048 80.5491 143.38 78.5478 143.38 76.1795Z"
      fill="white"
    />
    <path
      d="M156.885 94.3683V57.9906H171.676C174.357 57.9906 176.671 58.4821 178.617 59.4649C180.575 60.4359 182.085 61.8333 183.145 63.6569C184.206 65.4687 184.736 67.6179 184.736 70.1047C184.736 72.627 184.194 74.7703 183.11 76.5347C182.026 78.2873 180.488 79.6254 178.495 80.5491C176.502 81.4609 174.141 81.9168 171.414 81.9168H162.06V74.9894H169.806C171.111 74.9894 172.201 74.8177 173.075 74.4743C173.961 74.119 174.631 73.5861 175.086 72.8756C175.54 72.1533 175.767 71.2296 175.767 70.1047C175.767 68.9797 175.54 68.0501 175.086 67.316C174.631 66.5699 173.961 66.0134 173.075 65.6463C172.189 65.2673 171.099 65.0779 169.806 65.0779H165.54V94.3683H156.885ZM177.044 77.7426L185.96 94.3683H176.519L167.777 77.7426H177.044Z"
      fill="white"
    />
    <path
      d="M202.324 94.3683H189.089V57.9906H202.307C205.955 57.9906 209.096 58.7189 211.73 60.1754C214.376 61.6201 216.416 63.7042 217.85 66.4278C219.283 69.1396 220 72.3842 220 76.1617C220 79.951 219.283 83.2075 217.85 85.9311C216.428 88.6547 214.394 90.7448 211.748 92.2013C209.102 93.646 205.961 94.3683 202.324 94.3683ZM197.744 86.8725H201.992C203.997 86.8725 205.693 86.5291 207.08 85.8423C208.478 85.1436 209.533 84.0127 210.244 82.4496C210.967 80.8747 211.328 78.7787 211.328 76.1617C211.328 73.5447 210.967 71.4606 210.244 69.9093C209.522 68.3462 208.455 67.2212 207.045 66.5344C205.646 65.8357 203.921 65.4864 201.87 65.4864H197.744V86.8725Z"
      fill="white"
    />
    <path
      d="M154.604 24.7712C154.604 27.7589 154.037 30.2914 152.902 32.3687C151.767 34.446 150.23 36.0239 148.292 37.1023C146.362 38.1807 144.197 38.7199 141.796 38.7199C139.387 38.7199 137.217 38.1763 135.288 37.089C133.358 36.0018 131.826 34.4239 130.691 32.3555C129.565 30.2782 129.002 27.7501 129.002 24.7712C129.002 21.7834 129.565 19.2509 130.691 17.1736C131.826 15.0963 133.358 13.5184 135.288 12.44C137.217 11.3616 139.387 10.8224 141.796 10.8224C144.197 10.8224 146.362 11.3616 148.292 12.44C150.23 13.5184 151.767 15.0963 152.902 17.1736C154.037 19.2509 154.604 21.7834 154.604 24.7712ZM147.978 24.7712C147.978 23.0033 147.729 21.5094 147.231 20.2895C146.742 19.0697 146.035 18.1459 145.11 17.5183C144.193 16.8907 143.089 16.5769 141.796 16.5769C140.513 16.5769 139.409 16.8907 138.483 17.5183C137.558 18.1459 136.846 19.0697 136.349 20.2895C135.86 21.5094 135.615 23.0033 135.615 24.7712C135.615 26.5391 135.86 28.0329 136.349 29.2528C136.846 30.4727 137.558 31.3964 138.483 32.024C139.409 32.6516 140.513 32.9654 141.796 32.9654C143.089 32.9654 144.193 32.6516 145.11 32.024C146.035 31.3964 146.742 30.4727 147.231 29.2528C147.729 28.0329 147.978 26.5391 147.978 24.7712Z"
      fill="white"
    />
    <path
      d="M174.319 11.1936H180.789V28.7092C180.789 30.7334 180.313 32.4969 179.361 33.9996C178.409 35.4935 177.082 36.6515 175.38 37.4736C173.678 38.2868 171.7 38.6934 169.448 38.6934C167.169 38.6934 165.178 38.2868 163.476 37.4736C161.774 36.6515 160.451 35.4935 159.508 33.9996C158.565 32.4969 158.094 30.7334 158.094 28.7092V11.1936H164.576V28.139C164.576 29.076 164.777 29.9113 165.178 30.645C165.589 31.3787 166.161 31.9533 166.894 32.3687C167.627 32.7842 168.479 32.9919 169.448 32.9919C170.417 32.9919 171.264 32.7842 171.988 32.3687C172.722 31.9533 173.293 31.3787 173.704 30.645C174.114 29.9113 174.319 29.076 174.319 28.139V11.1936Z"
      fill="white"
    />
    <path
      d="M184.556 38.3487V11.1936H195.635C197.643 11.1936 199.376 11.5605 200.834 12.2942C202.301 13.019 203.432 14.0621 204.226 15.4234C205.021 16.7758 205.418 18.3802 205.418 20.2365C205.418 22.1193 205.012 23.7193 204.2 25.0363C203.388 26.3446 202.236 27.3435 200.743 28.0329C199.25 28.7136 197.482 29.0539 195.439 29.0539H188.433V23.8828H194.234C195.212 23.8828 196.028 23.7546 196.683 23.4983C197.347 23.2331 197.849 22.8353 198.189 22.3049C198.53 21.7657 198.7 21.0762 198.7 20.2365C198.7 19.3967 198.53 18.7028 198.189 18.1548C197.849 17.5979 197.347 17.1824 196.683 16.9084C196.02 16.6255 195.203 16.4841 194.234 16.4841H191.039V38.3487H184.556ZM199.656 25.938L206.335 38.3487H199.263L192.715 25.938H199.656Z"
      fill="white"
    />
  </svg>
);
