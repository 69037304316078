import React, { forwardRef, PropsWithChildren } from 'react';
import { isNonNullable } from '@/utils/isNonNulable';
import { NumberCounter } from '@/general/number/counter';
import { NumberFormat } from '@/general/number/format';
import { TokenPreview } from '..';
import { ITokenPreviewEarningProps } from './types';
import { LFI, List, Title, Children } from './styles';
import { useTimeoutPropState } from '@/utils/useTimeoutPropState';

export const TokenPreviewEarning = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ITokenPreviewEarningProps>
>(
  (
    { earning, isAppearAnimation, size = 'medium', title, children, ...props },
    ref
  ) => {
    const isChildrenAppearAnimation = useTimeoutPropState(
      isAppearAnimation,
      !isAppearAnimation ? 0 : 500
    );

    return (
      <TokenPreview
        ref={ref}
        {...props}
        isAppearAnimation={isAppearAnimation}
        size={size}
      >
        <div>
          <Title size={size}>{title || 'Earning rate'}</Title>
          <List size={size}>
            {isNonNullable(earning.value) && (
              <li>
                {typeof isChildrenAppearAnimation === 'boolean' ? (
                  <NumberCounter
                    animation={isChildrenAppearAnimation}
                    hasSignPrefix
                    rightFixed={2}
                    {...earning.value}
                  />
                ) : (
                  <NumberFormat
                    hasSignPrefix
                    rightFixed={2}
                    {...earning.value}
                  />
                )}
              </li>
            )}

            {isNonNullable(earning.lfi) && (
              <LFI>
                {typeof isChildrenAppearAnimation === 'boolean' ? (
                  <NumberCounter
                    animation={isChildrenAppearAnimation}
                    hasSignPrefix
                    rightFixed={2}
                    {...earning.lfi}
                  />
                ) : (
                  <NumberFormat hasSignPrefix rightFixed={2} {...earning.lfi} />
                )}
              </LFI>
            )}
          </List>
        </div>
        {children && <Children>{children}</Children>}
      </TokenPreview>
    );
  }
);

TokenPreviewEarning.displayName = 'TokenPreviewEarning';
