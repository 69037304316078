import React, { FC, useId } from 'react';
import { InputNumber } from '@/general/input/number';
import { InputLabel, InputWrapper } from './styles';
import { IInputProps } from './types';

export const Input: FC<IInputProps> = ({ label, ...props }) => {
  const id = useId();

  return (
    <InputWrapper>
      {label && <InputLabel htmlFor={`${id}_input`}>{label}</InputLabel>}
      <InputNumber {...props} label={label || ''} id={`${id}_input`} />
    </InputWrapper>
  );
};
