import styled from 'styled-components';
import { resetUl } from '@/utils/styles/resetUl';
import { Popup as PopupFC } from '@/general/popup';

export const Popup = styled(PopupFC).attrs({
  useStyles: {
    padding: false,
    bg: true,
  },
  direction: 'auto',
  alignment: 'auto',
})`
  padding: 5px;
  width: 150px;
`;

export const List = styled.ul`
  ${resetUl}
`;

export const Link = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 9px;
  border-radius: 6px;

  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: var(--color-white);
  text-decoration: none;

  transition: background-color 0.3s;

  &:hover {
    background: rgba(var(--color-white--rgb), 0.11);
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    flex-shrink: 0;
  }
`;
