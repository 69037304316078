import styled from 'styled-components';
import { ButtonSimple } from '@/general/button/simple';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { resetScrollBars } from '@/utils/styles/resetScrollBars';
import icon from './img/icon.svg';
import { LazyImage } from '@/internal/Image/Lazy';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: calc(100 * var(--vh));
  overflow: auto;
  ${resetScrollBars}
  background-color: rgba(var(--color-black--rgb), 0.75);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100 * var(--vh));
  padding: 40px 12px;
  box-sizing: border-box;
`;

export const Container = styled.div`
  width: 528px;
  max-width: 100%;
  overflow: hidden;
  border-radius: 8px;
  background-color: #222;
  border: 1px solid #333;
`;

export const Banner = styled.div`
  position: relative;
  width: 100%;
  padding-top: 44%;
  background-color: #000;
`;

export const BannerImage = styled(LazyImage).attrs({ pos: 'cover' })``;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  color: var(--color-white);

  @media ${getMediaQuery('smMin')} {
    padding: 60px 40px 34px;
  }
  @media ${getMediaQuery('xs')} {
    padding: 44px 20px 28px;
  }

  @media ${getMediaQuery('smMin')} {
    --icon-size: 1;
  }
  @media ${getMediaQuery('xs')} {
    --icon-size: 1;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(var(--icon-size) * 64px);
    height: calc(var(--icon-size) * 64px);
    border-radius: 50%;
    background: url('${icon}') center no-repeat;
    background-size: 100% 100%;
    filter: drop-shadow(0 14px 14px rgba(0, 0, 0, 0.25));
  }
`;

export const Title = styled.div`
  @media ${getMediaQuery('smMin')} {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  @media ${getMediaQuery('xs')} {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
`;

export const Description = styled.div`
  margin-top: 8px;
  font-weight: 400;
  line-height: 144%;
  max-width: 22.5em;

  @media ${getMediaQuery('smMin')} {
    font-size: 16px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 12px;
  }
`;

export const Button = styled(ButtonSimple)`
  max-width: 100%;
  font-weight: 600 !important;

  @media ${getMediaQuery('smMin')} {
    margin-top: 46px;
    width: 360px;
    font-size: 16px;
    --button-size: 44px;
  }

  @media ${getMediaQuery('xs')} {
    margin-top: 28px;
    width: 240px;
    font-size: 12px;
    --button-size: 36px;
  }
`;
