import styled, { css } from 'styled-components';
import { IProps } from './types';

export const Container = styled.div<IProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  z-index: 999;
  background-color: var(--color-dark);

  ${({ isHidden }) =>
    isHidden &&
    css`
      transition: opacity 0.35s, visibility 0.35s;
      opacity: 0;
      visibility: hidden;
    `}
`;
