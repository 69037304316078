import React, { FC, useEffect, useState } from 'react';
import { LoadingCheck } from './check';
import { LoadingFirework } from './firework';
import { LoadingSpinner } from './spinner';
import { Container, FireworkWrapper, SpinnerWrapper } from './styles';
import { IProps } from './types';

export const Loading: FC<IProps> = ({ status }) => {
  const isShown = ['pending', 'success'].includes(status);

  const [isLoadingShown, setIsLoadingShown] = useState(false);
  const isCheckShown = status === 'success';
  const isFireworkShown = status === 'success';

  useEffect(() => {
    if (status === 'pending') {
      setIsLoadingShown(true);
      return undefined;
    }
    const timeout = setTimeout(() => {
      setIsLoadingShown(false);
    }, 750);
    return () => clearTimeout(timeout);
  }, [status]);

  return (
    <Container isShown={isShown}>
      <FireworkWrapper>
        <LoadingFirework isPlaying={isFireworkShown} />
        <LoadingFirework isPlaying={isFireworkShown} />
        <LoadingFirework isPlaying={isFireworkShown} />
        <LoadingFirework isPlaying={isFireworkShown} />
      </FireworkWrapper>

      <SpinnerWrapper isShown={isLoadingShown}>
        <LoadingSpinner isPlaying={isLoadingShown} />
        <LoadingCheck isPlaying={isCheckShown} />
      </SpinnerWrapper>
    </Container>
  );
};
