import styled from 'styled-components';

export const Wrapper = styled.div`
  opacity: 0;
  will-change: transform;

  svg {
    will-change: transform;
  }
`;
