import React, { FC } from 'react';
import { useStoreAPI } from '@/store/api';
import { TProps } from './types';
import { Button } from './styles';

export const ConnectSubmit: FC<TProps> = ({
  isAppearAnimation,
  text,
  ...buttonProps
}) => {
  const api = useStoreAPI();

  return (
    <Button
      {...buttonProps}
      tag="button"
      isAppearAnimation={isAppearAnimation}
      onClick={() => {
        api.toggleConnectWallet(true);
      }}
    >
      {text || 'Connect'}
    </Button>
  );
};
