import React, { forwardRef, HTMLAttributes } from 'react';

export interface IProps extends HTMLAttributes<SVGSVGElement> {
  useOutline?: boolean | string;
}

export const IconLunaFi = forwardRef<SVGSVGElement, IProps>(
  ({ useOutline, ...tagProps }, ref) => (
  <svg
      ref={ref}
      {...tagProps}
   width="9.4834518mm"
   height="9.4834518mm"
   viewBox="0 0 9.4834518 9.4834518"
   version="1.1"
   id="svg1"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <defs
     id="defs1" />
  <g
     id="layer1"
     transform="translate(-81.731869,-75.038082)">
    <circle
       style={{fill:'#000000',fillOpacity:1,stroke:'none',strokeWidth:'0.394317'}}
       id="path2"
       cx="86.473595"
       cy="79.779808"
       r="4.7417259" />
    <path
       d="m 89.285585,77.133975 v 5.291667 h -1.99964 v -1.673993 c 0,-0.263075 -0.13036,-0.509084 -0.34806,-0.656801 l -0.16716,-0.113427 c -0.17939,-0.121735 -0.41487,-0.121735 -0.59424,0 l -0.16717,0.113427 c -0.2177,0.147717 -0.34807,0.393726 -0.34807,0.656801 v 1.673993 h -1.99964 v -5.290851 h 1.99964 v 1.96443 h 1.6247 v -1.965246 z"
       fill="#00ffff"
       id="path1"
       style={{strokeWidth:'0.264583'}} />
  </g>
</svg>
  )
);
