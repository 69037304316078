import styled, { css } from 'styled-components';
import { ButtonSimple } from '@/general/button/simple';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { TMaxButtonProps } from './types';

export const MaxButton = styled(ButtonSimple).attrs({
  variant: 'secondary',
  size: 'medium',
})<TMaxButtonProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateZ(0);

  @media ${getMediaQuery('xxl')} {
    right: 16px;
  }
  @media ${getMediaQuery('xlMax')} {
    right: 12px;
  }

  min-height: 36px;
  height: 36px;
  padding: 0 23px;

  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--color-blue);

  transition: opacity 0.25s, visibility 0.2s;
  ${({ isHidden }) =>
    isHidden &&
    css`
      opacity: 0;
      visibility: visible;
      pointer-events: none;
    `}
`;
