import styled from 'styled-components';

export const SvgElement = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

export const StaticOutline = styled.rect`
  stroke: var(--color-grey-800);
`;

export const HoverOutline = styled.rect`
  stroke: var(--color-blue);
`;

export const BgPlane = styled.rect`
  fill: var(--color-grey-800);
`;
