import React, { FC, useCallback, useRef } from 'react';
import { useOnElementResize } from '@/utils/resize';
import { LinksSocialIcons } from '@/general/links/social-icons';
import { Logo } from './logo/Logo';
import { Menu } from './menu/Menu';
import { Description, Footer, Info } from './styles';
import { IProps } from './types';

export const LayoutFooter: FC<IProps> = ({ menu, social, ...props }) => {
  const parentRef = useRef<HTMLElement>(null);

  /**
   * Resize the scene
   */
  const resize = useCallback(() => {
    if (!parentRef.current) {
      return;
    }
    document.documentElement.style.setProperty(
      '--footer-height',
      `${parentRef.current.clientHeight}px`
    );
  }, [parentRef]);

  // set resize events
  useOnElementResize(
    parentRef,
    () => {
      resize();
    },
    [resize]
  );

  return (
    <Footer ref={parentRef} id="footer" {...props}>
      <Info>
        <Logo />
        <Description>
          Our community is building a comprehensive decentralized betting
          platform for the future of finance. Join us!
        </Description>
        <LinksSocialIcons {...social} />
      </Info>
      {menu && <Menu groups={menu} />}
    </Footer>
  );
};
