import easingProgress, { Easing } from 'easing-progress';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Timeline } from 'vevet';
import { Wrapper } from './styles';
import { IProps } from './types';

export const Like: FC<IProps> = ({ isShown, className, style }) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<SVGSVGElement>(null);

  // animation timeline
  const timelineRef = useRef<Timeline | null>(null);
  useEffect(
    () => () => {
      timelineRef.current?.destroy();
      timelineRef.current = null;
    },
    []
  );

  // render the scene
  const progressRef = useRef(0);
  const render = useCallback(() => {
    const parent = parentRef.current;
    const icon = iconRef.current;
    if (!parent || !icon) {
      return;
    }

    const progress = progressRef.current;
    const easing = easingProgress(progress, Easing.easeOutBack);

    const scale = progress;
    const direction = timelineRef.current?.isReversed ? -1 : 1;
    parent.style.opacity = `${progress}`;
    parent.style.transform = `translate(${100 * (1 - easing)}px, ${
      -80 * (1 - easing) * direction
    }px)`;
    icon.style.transform = `scale(${scale}) rotate(${120 * (1 - easing)}deg)`;
  }, []);

  // animate
  useEffect(() => {
    // create animation timeline if it does't exist yet
    if (!timelineRef.current) {
      timelineRef.current = new Timeline({ duration: 650 });
      timelineRef.current.addCallback('progress', ({ progress }) => {
        progressRef.current = progress;
        render();
      });
    }

    // define animation direction
    if (isShown) {
      timelineRef.current.play();
    } else if (timelineRef.current.progress > 0) {
      timelineRef.current.reverse();
    }
  }, [isShown, render]);

  return (
    <Wrapper ref={parentRef} className={className} style={style}>
      <svg
        ref={iconRef}
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 30C0 13.4325 13.4325 0 30 0C46.5675 0 60 13.4325 60 30C60 46.5675 46.5675 60 30 60C13.4325 60 0 46.5675 0 30ZM44.9275 26.9675L44.9525 26.845L44.9425 26.8325C44.9725 26.64 45 26.45 45 26.25C45 24.18 43.32 22.5 41.25 22.5H29.375C29.375 22.5 30.625 17.115 30.625 15C30.625 12.185 28.8075 10 26.3075 10H25V13.41L22.6675 21.1175C21.795 22.74 20.1025 23.75 18.2625 23.75H17.5V40H20C21.6 41.6 23.7725 42.5 26.035 42.5H38.75C40.555 42.5 42.0625 41.2225 42.4175 39.5225L42.42 39.515C42.4225 39.5075 42.425 39.5025 42.425 39.495L44.9125 27.04C44.9175 27.015 44.9225 26.9925 44.9275 26.9675Z"
          fill="#00FFF4"
        />
      </svg>
    </Wrapper>
  );
};
