import { useEffect } from 'react';
import useScript from './useScript';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    lottie: any;
  }
}

export function useLottieScript(callback: (lottie: any) => () => void) {
  const status = useScript(
    'https://cdnjs.cloudflare.com/ajax/libs/lottie-web/5.9.6/lottie.min.js'
  );

  useEffect(() => {
    if (status !== 'ready') {
      return undefined;
    }
    return callback(window.lottie);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
}
