import styled from 'styled-components';
import { LinkExplore } from '@/general/links/explore';
import { IconLunaFi } from '@/internal/icons/LunaFi';
import { IconMetamask } from '@/internal/icons/Metamask';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { ButtonSvgCircleFill } from '@/general/button/svg-circle-fill';

export const Container = styled.div`
  box-sizing: border-box;
  background-color: var(--color-grey-800);
  color: var(--color-white);
  border-radius: 8px;
  border: 1px solid rgba(var(--color-blue--rgb), 0.4);
`;

export const Wrapper = styled.div`
  padding: 22px 20px 16px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const AddToMetamaskButton = styled(ButtonSvgCircleFill).attrs({
  variant: 'gradient',
  hasStaticFill: false,
  size: 'small',
})`
  font-size: 12px;

  @media ${getMediaQuery('smMin')} {
    padding-left: 27px;
    padding-right: 27px;
  }

  @media ${getMediaQuery('xs')} {
    padding-left: 18px;
    padding-right: 18px;
  }

  &__icon {
  }
`;

export const AddToMetamaskIcon = styled(IconMetamask)`
  width: 14px;
  height: auto;
  margin-right: 6px;
`;

export const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  @media ${getMediaQuery('xs')} {
    @media (max-width: 360px) {
      align-items: flex-start;
      flex-direction: column;

      ${AddToMetamaskButton} {
        margin-top: 10px;
      }
    }
  }
`;

export const TokenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TokenIcon = styled(IconLunaFi)`
  width: 32px;
  height: auto;
  margin-right: 16px;
`;

export const TokenInfo = styled.div`
  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    > * + * {
      margin-left: 16px;
    }
  }
`;

export const TokenName = styled.div`
  font-weight: 600;

  @media ${getMediaQuery('smMin')} {
    font-size: 20px;
    line-height: 28px;
  }

  @media ${getMediaQuery('xs')} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const TokenPrice = styled.div`
  font-weight: 500;
  color: var(--color-grey-200);

  @media ${getMediaQuery('smMin')} {
    font-size: 18px;
    line-height: 28px;
  }

  @media ${getMediaQuery('xs')} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const TokenExplore = styled(LinkExplore)`
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
`;

export const BuyWrapper = styled.div`
  margin-top: 16px;
  margin-left: -1px;
  width: calc(100% + 2px);
`;

export const InfoWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6px;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgba(var(--color-white--rgb), 0.08);
  border-radius: 4px;

  font-weight: 500;
  color: var(--color-grey-500);

  @media ${getMediaQuery('smMin')} {
    padding: 6px 18px 6px 12px;
    font-size: 10px;
    line-height: 18px;
  }

  @media ${getMediaQuery('xs')} {
    padding: 10px;
    font-size: 8px;
    line-height: 10px;

    @media (max-width: 370px) {
      flex-direction: column;
    }
  }

  span {
    margin-left: 4px;
    color: var(--color-white);

    @media ${getMediaQuery('smMin')} {
      font-size: 12px;
      line-height: 28px;
    }

    @media ${getMediaQuery('xs')} {
      font-size: 10px;
      line-height: 12px;

      @media (max-width: 370px) {
        margin: 6px 0 0 0;
      }
    }
  }
`;
