import styled from 'styled-components';
import { LayoutMenuModal } from '../menu-modal';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { LayoutFooter } from '../footer';
import { TSharedProps } from './types';
import { getShowStyles } from './generalStyles';

export const Container = styled.div`
  display: block;
  width: 100%;
  min-height: 100vh;

  padding-top: var(--top-height);
  padding-left: var(--aside-width);
  box-sizing: border-box;

  background-color: var(--color-black);
  color: var(--color-white);

  --aside-width: 224px;
  @media ${getMediaQuery('mdMax')} {
    --aside-width: 86px;
  }
  @media ${getMediaQuery('xs')} {
    --aside-width: 0;
  }
`;

export const Content = styled.div<TSharedProps>`
  ${getShowStyles}
  position: relative;
  z-index: 1;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  min-height: calc(100vh - var(--top-height) - var(--footer-height) - 1px);
`;

export const Footer = styled(LayoutFooter)`
  margin-top: 276px;

  @media ${getMediaQuery('lg')} {
    margin-top: 200px;
  }
  @media ${getMediaQuery('md')} {
    margin-top: 180px;
  }
  @media ${getMediaQuery('sm')} {
    margin-top: 140px;
  }
  @media ${getMediaQuery('xs')} {
    margin-top: 108px;
  }
`;

export const MenuModal = styled(LayoutMenuModal)`
  @media ${getMediaQuery('smMin')} {
    display: none;
  }
`;
