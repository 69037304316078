import React, { FC, PropsWithChildren } from 'react';
import { useMediaSize } from '@/utils/media';
import { IProps } from './types';

export const MediaBreakpointContent: FC<PropsWithChildren<IProps>> = ({
  breakpoint,
  children,
}) => {
  const mediaSize = useMediaSize();

  if (breakpoint === mediaSize) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return null;
};
