import React, { FC, useId, useState } from 'react';
import { TabButtons } from '@/general/tab/buttons';
import { ITabButtonsItem } from '@/general/tab/buttons/types';
import styles from './styles.module.scss';
import { IProps, TTab } from './types';
import { BoxOutline } from '@/general/box';
import { TooltipInfo } from '@/general/tooltip/info';
import { NumberFormat } from '@/general/number/format';

export const FinanceTabValue: FC<IProps> = ({
  title,
  tabs,
  info,
  numberFormat,
}) => {
  const id = useId();

  const tablist: (ITabButtonsItem & TTab)[] = tabs.map((tab, index) => ({
    ...tab,
    id: `${id}_${index}`,
  }));
  const [tabKey, setTabKey] = useState(tablist[0].key);
  const tab = tablist.find(({ key }) => key === tabKey) || null;
  const value = tab?.value;

  return (
    <BoxOutline aria-label={title}>
      <div className={styles.content}>
        <div className={styles.head}>
          {title && (
            <div className={styles.title}>
              {title}
              {info && <TooltipInfo>{info}</TooltipInfo>}
            </div>
          )}
          {tablist && tablist.length > 1 && (
            <TabButtons
              aria-label={`${title} Tabs`}
              styles="mininav"
              items={tablist}
              selectedKey={tabKey}
              onSelect={setTabKey}
            />
          )}
        </div>

        {typeof value === 'number' && (
          <div className={styles.value} role="tabpanel" id={tab?.id}>
            <NumberFormat value={value} {...numberFormat} />
          </div>
        )}
      </div>
    </BoxOutline>
  );
};
