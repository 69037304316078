import React, { forwardRef } from 'react';
import { Button, Icon } from './styles';
import { IProps } from './types';

export const ButtonCopy = forwardRef<HTMLButtonElement, IProps>(
  ({ text, children, ...tagProps }, ref) => (
    <Button
      ref={ref}
      tag="button"
      type="button"
      {...tagProps}
      onClick={() => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        textArea.remove();
      }}
    >
      {children}
      <Icon />
    </Button>
  )
);
ButtonCopy.displayName = 'ButtonCopy';
