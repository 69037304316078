import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { TProps } from './types';
import { Svg } from './svg/Svg';
import { useHover } from '@/utils/useHover';
import { useVariantColors } from './utils/useVariantColors';
import { Button, Content } from './styles';

export const ButtonSvgCircleFill = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  TProps
>(
  (
    {
      isAppearAnimation,
      hasStaticFill = true,
      hasHover = true,
      isForceHover,
      variant = 'primary',
      variantColors,
      borderRadius = 8,
      strokeWidth = 2,
      isLoading = false,
      children,
      ...tagProps
    },
    ref
  ) => {
    const domRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);
    useImperativeHandle(ref, () => domRef.current!);

    // elements
    const contentRef = useRef<HTMLSpanElement>(null);

    // hover state
    const isDomHovered = useHover(domRef);
    const isHovered = hasHover && (isForceHover || isDomHovered);

    // set colors
    const colors = useVariantColors({ variant, variantColors, hasStaticFill });
    useEffect(() => {
      if (domRef.current) {
        domRef.current.style.setProperty(
          '--button-color-text',
          colors.contentColor
        );
        domRef.current.style.setProperty(
          '--button-color-text-hover',
          colors.contentColorHover
        );
      }
    }, [colors]);

    return (
      <Button ref={domRef} {...tagProps} isHovered={isHovered}>
        <Svg
          isAppearAnimation={isAppearAnimation}
          parentRef={domRef}
          contentRef={contentRef}
          variant={variant}
          variantColors={variantColors}
          hasStaticFill={hasStaticFill}
          borderRadius={borderRadius}
          strokeWidth={strokeWidth}
          isLoading={isLoading}
          isHovered={isHovered}
        />
        <Content ref={contentRef}>{children}</Content>
      </Button>
    );
  }
);
ButtonSvgCircleFill.displayName = 'ButtonSvgCircleFill';
