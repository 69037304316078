import React, { FC, PropsWithChildren } from 'react';
import { LayoutMenu } from '@/layout/menu';
import { useTimeoutPropState } from '@/utils/useTimeoutPropState';
import { useAppearAnimation } from '@/utils/useAppearAnimation';
import { IProps } from './types';
import { Container, Menu, Content } from './styles';
import { LayoutNavButtons } from '@/layout/nav-buttons/NavButtons';

export const Aside: FC<PropsWithChildren<IProps>> = ({
  isAppearAnimation,
  children,
  menuLinks,
}) => {
  const containerAppearAnimation = useAppearAnimation(isAppearAnimation);

  const isChildrenAppearAnimation = useTimeoutPropState(
    isAppearAnimation,
    1250
  );
  const childrenAppearAnimation = useAppearAnimation(isChildrenAppearAnimation);

  return (
    <Container appearAnimation={containerAppearAnimation}>
      <Menu>
        <LayoutMenu
          isAdaptive
          isAppearAnimation={isChildrenAppearAnimation}
          links={menuLinks}
        />
      </Menu>
      <Content appearAnimation={childrenAppearAnimation}>
        {children}
        <LayoutNavButtons />
      </Content>
    </Container>
  );
};
