import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useViewportInOut } from '@/utils/useViewportInOut';
import imagePlaceholder from './placeholder.svg';
import { TProps } from './types';
import { Image } from './styles';

/**
 * Lazy image.
 * Uses IntersectionObserver to define the moment when it needs to be loaded.
 * If IntersectionObserver is not supported, the image will be loaded immediately.
 */
export const LazyImage = forwardRef<HTMLImageElement, TProps>(
  ({ pos = 'cover', useAlpha = true, src, onLoad, ...tagProps }, ref) => {
    const imageRef = useRef<HTMLImageElement>(null);
    useImperativeHandle(ref, () => imageRef.current!);

    // states
    const [srcSet, setSrcSet] = useState(imagePlaceholder.src);
    const [isLoaded, setIsLoaded] = useState(false);

    /**
     * Load the image by replacing its src
     */
    const loadImage = useCallback(() => {
      setSrcSet(src);
    }, [src]);

    // viewport position
    useViewportInOut({
      el: imageRef,
      in() {
        loadImage();
      },
      destroyOnIn: true,
    });

    return (
      <Image
        {...tagProps}
        alt={tagProps.alt || ''}
        src={src}
        srcSet={srcSet}
        ref={imageRef}
        data-is-loaded={isLoaded ? 'true' : undefined}
        onLoad={(e) => {
          if (srcSet !== imagePlaceholder.src) {
            setIsLoaded(true);
            onLoad?.(e.currentTarget);
          }
        }}
        pos={pos}
        useAlpha={useAlpha}
        isLoaded={isLoaded}
      />
    );
  }
);
LazyImage.displayName = 'LazyImage';
