import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;

  > * {
    margin-right: 0.25em;
  }
`;

export const Word = styled.span`
  display: inline-block;
`;

export const Letter = styled.span`
  display: inline-block;
  opacity: 0;
  will-change: opacity;
`;
