import { RefObject, useCallback, useEffect, useState } from 'react';
import { DraggerDirection, utils } from 'vevet';
import { useInterval } from '@/utils/useInterval';

interface IProps {
  count: number;
  parentRef: RefObject<HTMLElement>;
  interval: number;
}

export const useSlides = ({ count, parentRef, interval }: IProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const wrap = useCallback(
    (index: number) => utils.math.wrap(0, count, index) as number,
    [count]
  );

  const goToNext = useCallback(() => {
    if (count > 1) {
      setActiveIndex((val) => wrap(val + 1));
    }
  }, [wrap, count]);

  const goToPrev = useCallback(() => {
    if (count > 1) {
      setActiveIndex((val) => wrap(val - 1));
    }
  }, [wrap, count]);

  const intervalAPI = useInterval(() => goToNext(), interval);

  useEffect(() => {
    if (!parentRef.current) {
      return undefined;
    }

    const swipe = new DraggerDirection({ container: parentRef.current });
    swipe.addCallback('start', () => intervalAPI.stop());
    swipe.addCallback('end', () => intervalAPI.start(interval));
    swipe.addCallback('right', () => goToPrev());
    swipe.addCallback('left', () => goToNext());

    return () => swipe.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToNext, goToPrev, parentRef, interval]);

  return {
    activeIndex,
  };
};
