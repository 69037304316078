import styled from 'styled-components';
import { IconMetamask } from '@/internal/icons/Metamask';
import { IconLunaFi } from '@/internal/icons/LunaFi';
import { ButtonSvgCircleFill } from '@/general/button/svg-circle-fill';
import { ButtonSvgOutline } from '@/general/button/svg-outline';

export const MetamaskButton = styled(ButtonSvgOutline).attrs({
  size: 'small',
  fullWidth: true,
  hasBg: false,
})`
  padding-left: 0;
  padding-right: 0;
`;

export const MetamaskButtonIcon = styled(IconMetamask)`
  flex-shrink: 0;
  margin-right: 6px;
  width: 14px;
  height: auto;
`;

export const UpgradeButton = styled(ButtonSvgCircleFill).attrs({
  size: 'small',
  fullWidth: true,
  hasStaticFill: false,
  variant: 'gradient',
})`
  padding-left: 0;
  padding-right: 0;
`;

export const UpgradeButtonIcon = styled(IconLunaFi)`
  flex-shrink: 0;
  margin-right: 6px;
  width: 20px;
  height: auto;
`;
