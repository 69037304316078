import React, { forwardRef } from 'react';
import { Container, Item } from './styles';
import { TProps } from './types';

export const Grid = forwardRef<HTMLDivElement, TProps>((props, ref) => {
  const { className, children } = props;

  if ('container' in props) {
    return (
      <Container ref={ref} {...props}>
        {children}
      </Container>
    );
  }

  if ('item' in props) {
    return (
      <Item ref={ref} {...props}>
        {children}
      </Item>
    );
  }

  return <div ref={ref} className={className} />;
});
Grid.displayName = 'Grid';
