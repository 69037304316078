import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { TProps } from './types';
import { Svg } from './svg/Svg';
import { Button, Content } from './styles';

export const ButtonSvgOutline = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  TProps
>(({ isAppearAnimation, hasBg = true, children, ...tagProps }, ref) => {
  const domRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);
  useImperativeHandle(ref, () => domRef.current!);

  const contentRef = useRef<HTMLSpanElement>(null);

  return (
    <Button ref={domRef} {...tagProps}>
      <Svg
        parentRef={domRef}
        contentRef={contentRef}
        isAppearAnimation={isAppearAnimation}
        hasBg={hasBg}
      />
      <Content ref={contentRef}>{children}</Content>
    </Button>
  );
});
ButtonSvgOutline.displayName = 'ButtonSvgOutline';
