import styled, { css } from 'styled-components';
import { resetButtonStyles } from '@/utils/styles/resetButtonStyles';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { TSharedProps } from '../types';
import logoFull from './img/logo_full.svg';
import logoMini from './img/logo_mini.svg';
import menuIcon from './img/menu_button.svg';
import { getTransition } from '@/utils/styles/getTransition';
import { getShowStyles } from '../generalStyles';

export const Container = styled.div<TSharedProps>`
  position: fixed;
  top: 0;
  z-index: 9;
  left: var(--aside-width);
  right: 0;
  height: var(--top-height);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${getMediaQuery('xs')} {
    justify-content: center;
  }

  ${({ appearAnimation }) =>
    appearAnimation.use
      ? css`
          ${getTransition('background-color 0.5s', '0.25s')}
          background-color: ${appearAnimation.on && 'var(--color-grey-1200)'};
        `
      : css`
          background-color: var(--color-grey-1200);
        `}

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-grey-800);

    ${({ appearAnimation }) =>
      appearAnimation.use &&
      css`
        transform: scale(${appearAnimation.on ? 1 : 0}, 1);
        transform-origin: top left;
        ${getTransition('transform 1s')}
      `}
  }
`;

export const Logo = styled.a<TSharedProps>`
  ${getShowStyles}

  position: absolute;
  top: 0;
  left: calc(var(--aside-width) * -1);
  display: block;
  flex-shrink: 0;
  width: var(--aside-width);
  height: var(--top-height);

  background-image: url('${logoFull}');
  background-position: left 40px center;
  background-repeat: no-repeat;
  background-size: 60% auto;

  @media ${getMediaQuery('mdMax')} {
    background-image: url('${logoMini}');
    background-size: 45% auto;
    background-position: center;
  }

  @media ${getMediaQuery('xs')} {
    left: auto;
    right: 0;
    width: var(--top-height);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 40px;

  @media ${getMediaQuery('smMin')} {
    width: 100%;
  }
`;

export const Title = styled.h1<TSharedProps>`
  ${getShowStyles}

  margin: 0;
  font-family: 'NB Architekt R';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.4em;

  @media ${getMediaQuery('xs')} {
    font-size: 16px;
  }
`;

export const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  @media ${getMediaQuery('xs')} {
    display: none;
  }
`;

export const MenuButton = styled.button<TSharedProps>`
  ${resetButtonStyles}
  ${getShowStyles}

  position: absolute;
  top: 0;
  left: 0;
  height: var(--top-height);
  width: var(--top-height);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 31px auto;
  background-image: url('${menuIcon}');

  @media ${getMediaQuery('smMin')} {
    display: none;
  }
`;
