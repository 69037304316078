import React, { FC } from 'react';

export const Icon: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3608 2.40039L11.9983 8.69984L9.89643 6.59798L3.59961 12.9622L5.70147 15.0641L9.90519 10.8017L12.0071 12.9036L20.397 4.50225L18.3608 2.40039ZM3.59961 17.0985V19.2004H20.397V17.0985H3.59961Z"
      fill="#00FFF4"
    />
  </svg>
);
