import React, { forwardRef, useId } from 'react';
import { FormCheckbox } from '..';
import { Label } from './styles';
import { IProps } from './types';

export const FormCheckboxLabelled = forwardRef<HTMLInputElement, IProps>(
  ({ label, isReversed, id: idProp, className, style, ...tagProps }, ref) => {
    const dynamicId = useId();
    const id = idProp || dynamicId;

    return (
      <Label
        htmlFor={id}
        className={className}
        style={style}
        isReversed={isReversed}
      >
        <span>{label}</span>
        <FormCheckbox ref={ref} {...tagProps} id={id} />
      </Label>
    );
  }
);
