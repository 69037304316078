import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const Content = styled.div`
  flex-shrink: 0;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;

  @media ${getMediaQuery('smMin')} {
    padding: 26px 30px 0;
  }
  @media ${getMediaQuery('xs')} {
    padding: 22px 16px 0;
  }
`;

export const Value = styled.div`
  font-weight: 600;
  color: var(--color-white);

  @media ${getMediaQuery('smMin')} {
    font-size: 36px;
    line-height: 48px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-weight: 600;
  line-height: 24px;
  color: var(--color-grey-600);

  @media ${getMediaQuery('smMin')} {
    font-size: 16px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 12px;
  }
`;

export const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 246px;
  height: 100%;
`;
