import styled from 'styled-components';
import { ChartPie } from '@/internal/charts/pie';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { BoxOutline } from '@/general/box/outline';

export const Layout = styled(BoxOutline)`
  min-height: 260px;
  padding: 26px 40px 36px 34px;

  @media ${getMediaQuery('smMin')} {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }

  @media ${getMediaQuery('md')} {
    padding: 26px 34px 36px;
  }

  @media ${getMediaQuery('sm')} {
    padding: 26px 26px 32px;
  }

  @media ${getMediaQuery('xs')} {
    padding: 16px 16px 32px;

    > * + * {
      margin-top: 24px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-grey-600);

  @media ${getMediaQuery('smMax')} {
    font-size: 14px;
  }

  > * + * {
    margin-left: 8px;
  }
`;

export const Value = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: var(--color-white);

  @media ${getMediaQuery('md')} {
    font-size: 30px;
  }

  @media ${getMediaQuery('sm')} {
    font-size: 24px;
  }

  @media ${getMediaQuery('xs')} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const Date = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-white);

  @media ${getMediaQuery('smMax')} {
    font-size: 12px;
  }
`;

export const Content = styled.div`
  @media ${getMediaQuery('smMin')} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  @media ${getMediaQuery('xs')} {
    position: relative;

    ${Date} {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  * + ${Value} {
    margin-top: 10px;

    @media ${getMediaQuery('xs')} {
      margin-top: 8px;
    }
  }
`;

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media ${getMediaQuery('smMin')} {
    padding-top: 16px;
  }
`;

export const Chart = styled(ChartPie)`
  position: relative;
  width: 180px;
  height: 180px;
`;

export const AnchorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  > * {
    width: 100%;

    + * {
      margin-top: 18px;
    }
  }
`;
