import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Portal } from 'react-portal';
import { Timeline } from 'vevet';
import { addEventListener } from 'vevet-dom';
import { useOutsideClick } from '@/utils/useOutsideClick';
import {
  Modal as Parent,
  ModalCloseButton,
  ModalContainer,
  ModalContent,
  ModalScrollWrapper,
} from './styles';
import { IProps } from './types';

export const Modal: FC<PropsWithChildren<IProps>> = ({
  hasPadding = true,
  hasStyles = true,
  isOpen,
  onClose,
  children,
  ...tagProps
}) => {
  const [canRender, setCanRender] = useState(false);

  const parentRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  // hide on escape
  useEffect(() => {
    if (!isOpen) {
      return undefined;
    }
    const escapeListener = addEventListener(window, 'keydown', (evt) => {
      if (evt.keyCode === 27) {
        onClose();
      }
    });
    return () => {
      escapeListener.remove();
    };
  }, [isOpen, onClose]);

  // close on outside click
  useOutsideClick(contentRef, onClose);

  // create animation timeline
  const [timeline, setTimeline] = useState<Timeline>();
  useEffect(() => {
    const tm = new Timeline({ duration: 350 });
    tm.addCallback('progress', ({ progress, easing }) => {
      const parent = parentRef.current;
      const content = contentRef.current;
      if (parent) {
        parent.style.opacity = `${easing}`;
        parent.style.visibility = progress > 0 ? 'visible' : 'hidden';
      }
      if (content) {
        content.style.transform = `scale(${0.1 * easing + 0.9})`;
      }

      if (progress === 0 && tm.isReversed) {
        setCanRender(false);
      }
    });
    setTimeline(tm);

    return () => tm.destroy();
  }, []);

  // animate the modal
  useEffect(() => {
    if (isOpen) {
      setCanRender(true);
      timeline?.play();
    } else if (timeline?.progress !== 0) {
      timeline?.reverse();
    }
  }, [isOpen, timeline]);

  return canRender ? (
    <Portal>
      <Parent
        ref={parentRef}
        {...tagProps}
        role="dialog"
        aria-modal
        aria-hidden={!isOpen}
      >
        <ModalContainer>
          <ModalScrollWrapper>
            <ModalContent
              ref={contentRef}
              hasPadding={hasPadding}
              hasStyles={hasStyles}
            >
              <ModalCloseButton
                type="button"
                onClick={() => {
                  onClose();
                }}
                aria-label="Close"
                hasPadding={hasPadding}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.865615 0.881611C1.09979 0.647272 1.41735 0.515627 1.74848 0.515627C2.0796 0.515627 2.39716 0.647272 2.63134 0.881611L7.99219 6.24786L13.353 0.881611C13.4682 0.762223 13.606 0.666995 13.7584 0.601484C13.9107 0.535972 14.0746 0.501489 14.2404 0.500047C14.4062 0.498605 14.5706 0.530232 14.7241 0.593083C14.8776 0.655934 15.017 0.74875 15.1343 0.866116C15.2515 0.983482 15.3442 1.12305 15.407 1.27667C15.4698 1.43029 15.5014 1.59489 15.5 1.76086C15.4985 1.92684 15.4641 2.09086 15.3986 2.24337C15.3332 2.39587 15.238 2.5338 15.1188 2.64911L9.75792 8.01536L15.1188 13.3816C15.3462 13.6174 15.4721 13.9331 15.4693 14.2609C15.4664 14.5886 15.3351 14.9021 15.1036 15.1339C14.872 15.3656 14.5588 15.4971 14.2314 15.5C13.904 15.5028 13.5886 15.3768 13.353 15.1491L7.99219 9.78286L2.63134 15.1491C2.39582 15.3768 2.08039 15.5028 1.75297 15.5C1.42555 15.4971 1.11235 15.3656 0.880827 15.1339C0.649299 14.9021 0.51797 14.5886 0.515125 14.2609C0.51228 13.9331 0.638147 13.6174 0.865615 13.3816L6.22647 8.01536L0.865615 2.64911C0.631512 2.4147 0.5 2.09682 0.5 1.76536C0.5 1.43391 0.631512 1.11602 0.865615 0.881611V0.881611Z"
                    fill="#E2E2E2"
                  />
                </svg>
              </ModalCloseButton>
              {children}
            </ModalContent>
          </ModalScrollWrapper>
        </ModalContainer>
      </Parent>
    </Portal>
  ) : null;
};
