import React, { FC } from 'react';
import {
  Banner,
  Container,
  Content,
  Description,
  Modal,
  Title,
  Wrapper,
  Button,
  BannerImage,
} from './styles';
import bannerImage from './img/banner.png';

export interface IModalStaticErrorProps {
  title?: string;
  desc?: string;
  buttonName?: string;
  onButtonClick?: () => void;
}

export const ModalStaticError: FC<IModalStaticErrorProps> = ({
  title,
  desc,
  buttonName,
  onButtonClick,
}) => (
  <Modal>
    <Wrapper>
      <Container>
        <Banner>
          <BannerImage src={bannerImage} alt="Banner" />
        </Banner>
        <Content>
          {title && <Title>{title}</Title>}
          {desc && <Description>{desc}</Description>}
          {buttonName && (
            <Button tag="button" onClick={onButtonClick}>
              {buttonName}
            </Button>
          )}
        </Content>
      </Container>
    </Wrapper>
  </Modal>
);
