import React, { forwardRef, useState } from 'react';
import { useTimeoutPropState } from '@/utils/useTimeoutPropState';
import { NumberCounter } from '@/general/number/counter';
import { NumberFormat } from '@/general/number/format';
import { Icon } from './Icon';
import {
  AnchorsWrapper,
  Chart,
  ChartWrapper,
  Content,
  Date,
  Layout,
  Title,
  Value,
} from './styles';
import { IProps } from './types';
import { Anchor } from './anchor';

export const FinancePieChart = forwardRef<HTMLDivElement, IProps>(
  (
    {
      className,
      style,
      isAppearAnimation,
      data,
      title,
      value,
      numberFormat,
      date,
    },
    ref
  ) => {
    const isChildrenAppearAnimation = useTimeoutPropState(
      isAppearAnimation,
      !isAppearAnimation ? 0 : 500
    );

    const [activeKey, setActiveKey] = useState<string | undefined>(undefined);

    return (
      <Layout
        ref={ref}
        aria-label={title}
        isAppearAnimation={isAppearAnimation}
        className={className}
        style={style}
      >
        <Content>
          <div>
            {title && (
              <Title>
                <Icon />
                <span>{title}</span>
              </Title>
            )}
            {typeof value !== 'undefined' && (
              <Value>
                {typeof isChildrenAppearAnimation === 'boolean' ? (
                  <NumberCounter
                    animation={isChildrenAppearAnimation}
                    prefix="$"
                    value={value}
                    {...numberFormat}
                  />
                ) : (
                  <NumberFormat prefix="$" value={value} {...numberFormat} />
                )}
              </Value>
            )}
          </div>
          {date && <Date>{date}</Date>}
        </Content>

        <ChartWrapper>
          <Chart
            data={data}
            isAppearAnimation={isChildrenAppearAnimation}
            activeKey={activeKey}
          />
        </ChartWrapper>

        <AnchorsWrapper onMouseLeave={() => setActiveKey(undefined)}>
          {data.map(({ key, ...item }) => (
            <Anchor
              key={key}
              {...item}
              isAppearAnimation={isChildrenAppearAnimation}
              numberFormat={numberFormat}
              onMouseEnter={() => setActiveKey(key)}
              isUnactive={!!activeKey && activeKey !== key}
            />
          ))}
        </AnchorsWrapper>
      </Layout>
    );
  }
);

FinancePieChart.displayName = 'FinancePieChart';
