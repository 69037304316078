import styled, { css } from 'styled-components';
import { Icon as IconComponent } from './Icon';
import { IStyleProps } from './types';

export const Icon = styled(IconComponent)`
  display: block;
`;

export const Layout = styled.div<IStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: max-content;

  background: var(--color-grey-900);
  box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.04),
    inset 0px 1px 0px rgba(255, 255, 255, 0.05);
  border-radius: 4px;

  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  > * + * {
    margin-left: 4px;
  }

  ${({ isNegative }) =>
    isNegative &&
    css`
      color: var(--color-danger);

      ${Icon} {
        transform: rotate(180deg);
      }
    `}

  ${({ isPositive }) =>
    isPositive &&
    css`
      color: var(--color-success);
    `}
`;
