import React, { FC, useEffect, useRef, useState } from 'react';
import { useLottieScript } from '@/utils/useLottieScript';
import { Container } from './styles';
import { IProps } from './types';
import lottieJSON from './lottie.json';

export const LoadingFirework: FC<IProps> = ({ isPlaying, ...props }) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = useState<any | undefined>();

  useLottieScript((lottie) => {
    if (!parentRef.current) {
      return () => {};
    }
    let instance: any | undefined;
    try {
      instance = lottie.loadAnimation({
        container: parentRef.current,
        animationData: lottieJSON,
        autoplay: false,
        loop: false,
        rendererSettings: {
          viewBoxOnly: false,
        },
      });
      setAnimation(instance);
    } catch (e) {
      //
    }
    return () => {
      instance?.destroy();
    };
  });

  useEffect(() => {
    if (isPlaying) {
      animation?.stop();
      animation?.play();
    } else {
      animation?.pause();
    }
  }, [animation, isPlaying]);

  return <Container ref={parentRef} isShown={isPlaying} {...props} />;
};
