import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const Footer = styled.footer`
  border-top: 1px solid var(--color-grey-800);
  background-color: var(--color-grey-1200);

  @media ${getMediaQuery('xxl')} {
    &::after {
      content: '';
    }
  }

  @media ${getMediaQuery('smMin')} {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    padding-left: 40px;
    padding-right: 40px;
    padding-top: 36px;
    padding-bottom: 70px;
  }

  @media ${getMediaQuery('xs')} {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 64px;
    padding-bottom: 40px;
  }
`;

export const Info = styled.div`
  > * + * {
    @media ${getMediaQuery('smMin')} {
      margin-top: 15px;
    }
    @media ${getMediaQuery('xs')} {
      margin-top: 20px;
    }
  }

  @media ${getMediaQuery('xs')} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const Description = styled.div`
  @media ${getMediaQuery('smMin')} {
    max-width: 174px;
  }
  @media ${getMediaQuery('xs')} {
    max-width: 240px;
  }

  font-size: 12px;
  line-height: 16px;
  color: var(--color-grey-600);
`;
