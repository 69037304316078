import React, {
  Children,
  FC,
  memo,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import SwiperClass, { Scrollbar, Navigation } from 'swiper';
import classNames from 'classnames';
import { vevetApp } from '@/utils/vevet';
import { IProps } from './types';
import { Container, NavButton, Slide, Swiper } from './styles';

const SlideMemo = memo(Slide);
SlideMemo.displayName = 'SwiperSlide';

export const SliderSwiperScroll: FC<PropsWithChildren<IProps>> = ({
  children,
  className,
  style,
  isScrollBar = true,
  isStretchHeight = false,
  ...swiperProps
}) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [isShowNav, setIsShowNav] = useState(false);

  useEffect(() => {
    if (vevetApp.isMobile) {
      setIsShowNav(true);
    }
  }, []);

  const slides = Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return (
        <SlideMemo
          className={classNames(isStretchHeight && 'stretch_height')}
          style={{ width: 'max-content' }}
        >
          {child}
        </SlideMemo>
      );
    }
    return null;
  });

  return (
    <Container
      isReady={!!swiper}
      onMouseEnter={() => !vevetApp.isMobile && setIsShowNav(true)}
      onMouseLeave={() => !vevetApp.isMobile && setIsShowNav(false)}
    >
      <Swiper
        modules={[Scrollbar, Navigation]}
        speed={400}
        slidesPerView="auto"
        spaceBetween={18}
        {...swiperProps}
        onSwiper={(val) => {
          setSwiper(val);
        }}
        style={style}
        className={classNames(className, isStretchHeight && 'stretch_height')}
        scrollbar={
          isScrollBar
            ? {
                draggable: true,
                dragSize: 20,
              }
            : undefined
        }
        navigation={{
          prevEl: '.nav_button_prev',
          nextEl: '.nav_button_next',
        }}
      >
        {slides}
      </Swiper>
      <NavButton isShowNav={isShowNav} isPrev>
        <button
          type="button"
          aria-label="Previous slide"
          className="nav_button_prev"
        />
      </NavButton>
      <NavButton isShowNav={isShowNav} isNext>
        <button
          type="button"
          aria-label="Next slide"
          className="nav_button_next"
        />
      </NavButton>
    </Container>
  );
};
