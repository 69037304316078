import React, { FC } from 'react';
import { useOpenAnimationState } from '@/utils/useOpenAnimationState';
import {
  Buttons,
  CancelButton,
  Container,
  ContinueButton,
  Title,
  Description,
} from './styles';
import { IProps } from './types';
import { Banner } from './banner';

export const Confirm: FC<IProps> = ({
  isShown,
  title,
  description,
  onCancel,
  onContinue,
  continueName,
}) => {
  const animationState = useOpenAnimationState(isShown, {
    opening: 350,
    closing: 350,
  });

  return (
    <Container animationState={animationState}>
      <Banner />

      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}

      <Buttons>
        <CancelButton tag="button" onClick={onCancel}>
          Close
        </CancelButton>
        <ContinueButton tag="button" onClick={onContinue}>
          {continueName}
        </ContinueButton>
      </Buttons>
    </Container>
  );
};
