import React, { forwardRef } from 'react';
import { Check, CheckboxContainer, Input } from './styles';
import { IProps } from './types';

export const FormCheckbox = forwardRef<HTMLInputElement, IProps>(
  (props, ref) => (
    <CheckboxContainer>
      <Input ref={ref} {...props} />
      <Check />
    </CheckboxContainer>
  )
);
