import React, { forwardRef, useMemo } from 'react';
import { isNonNullable } from '@/utils/isNonNulable';
import { NumberFormat } from '@/general/number/format';
import { Tooltip } from '@/general/tooltip';
import { Container, IndicatorSVG, LFI, Value } from './styles';
import { IProps } from './types';

export const PoolApy = forwardRef<HTMLDivElement, IProps>(
  ({ value, lfiValue, tooltip, className, ...tagProps }, ref) => {
    const Component = useMemo(
      () => (
        <Container ref={ref} {...tagProps}>
          {isNonNullable(value) && (
            <Value>
              <NumberFormat value={value} suffix="%" />
              {(value > 0 || value < 0) && (
                <IndicatorSVG
                  isNegative={value < 0}
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.13964 0.204295L9.08763 4.15228C9.36051 4.42516 9.36051 4.86641 9.08763 5.13638L8.43157 5.79244C8.15869 6.06532 7.71745 6.06532 7.44747 5.79244L4.64614 2.99692L1.84772 5.79534C1.57484 6.06822 1.1336 6.06822 0.863622 5.79534L0.204657 5.13928C-0.0682189 4.86641 -0.0682189 4.42516 0.204657 4.15519L4.15265 0.207198C4.42552 -0.0685803 4.86677 -0.0685804 5.13964 0.204295Z" />
                </IndicatorSVG>
              )}
            </Value>
          )}
          {isNonNullable(lfiValue) && (
            <LFI aria-label="LFI">
              <NumberFormat value={lfiValue} suffix="%" hasSignPrefix />
            </LFI>
          )}
        </Container>
      ),
      [lfiValue, ref, tagProps, value]
    );

    if (tooltip) {
      return (
        <Tooltip trigger={Component} showEvent="hover">
          {tooltip}
        </Tooltip>
      );
    }

    return Component;
  }
);
PoolApy.displayName = 'PoolApy';
