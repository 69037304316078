import styled from 'styled-components';
import { ButtonSvgCircleFill } from '@/general/button/svg-circle-fill';

export const Button = styled(ButtonSvgCircleFill).attrs({
  variant: 'primary_unactive',
})`
  padding-left: 18px;
  padding-right: 18px;
`;

export const IconSvg = styled.svg`
  width: 17px;
  height: auto;
  margin-right: 12px;
  color: var(--color-white);
`;
