import styled, { css } from 'styled-components';
import { BoxOutline } from '@/general/box/outline';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { IconLunaFi } from '@/internal/icons/LunaFi';
import bonusImage from './img/bonus.png';
import backgroundImage from './img/background.png';
import {
  IButtonsProps,
  ISubmitProps,
  IStatusButtonWrapperProps,
  IStatusProps,
  ILottieContainerProps,
} from './types';
import { ButtonSimple } from '@/general/button/simple';
import { LazyImage } from '@/internal/Image/Lazy';

export const Box = styled(BoxOutline)`
  @media ${getMediaQuery('mdMin')} {
    padding: 10px 42px 28px;
  }
  @media ${getMediaQuery('sm')} {
    padding: 30px 42px 28px;
  }
  @media ${getMediaQuery('xs')} {
    padding: 30px 12px 13px;
  }
`;

export const BackgroundImage = styled(LazyImage).attrs({
  src: backgroundImage,
  alt: 'background image',
  pos: 'fullabs',
})``;

export const LottieContainer = styled.div<ILottieContainerProps>`
  position: absolute;
  top: 0;
  width: 72%;
  height: 100%;

  ${({ pos }) =>
    pos === 'right'
      ? css`
          right: 50%;
        `
      : css`
          left: 50%;
        `}

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MainImage = styled(LazyImage).attrs({
  src: bonusImage,
  alt: 'main image',
  pos: false,
  width: 201,
  height: 167,
})`
  display: block;
  width: 206px;
  height: auto;
`;

export const Head = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${getMediaQuery('mdMin')} {
    top: 22px;
    align-items: center;
  }
  @media ${getMediaQuery('sm')} {
    top: 0px;
    align-items: center;
  }
  @media ${getMediaQuery('xs')} {
    top: -14px;
    align-items: flex-end;
  }
`;

export const Token = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Icon = styled(IconLunaFi).attrs({ useOutline: true })`
  @media ${getMediaQuery('smMin')} {
    width: 40px;
    height: 40px;
  }
  @media ${getMediaQuery('xs')} {
    width: 30px;
    height: 30px;
  }

  circle {
    &:last-child {
      stroke: var(--color-blue);
    }
  }
`;

export const Name = styled.p`
  margin: 0;
  margin-left: 14px;

  font-style: normal;
  font-weight: 600;

  @media ${getMediaQuery('mdMin')} {
    font-size: 38px;
  }
  @media ${getMediaQuery('sm')} {
    font-size: 36px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 28px;
  }
`;

export const Rewards = styled.p`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;

  > * + * {
    @media ${getMediaQuery('smMin')} {
      margin-top: 10px;
    }
    @media ${getMediaQuery('xs')} {
      margin-top: 8px;
    }
  }
`;

export const Title = styled.span`
  font-weight: 500;
  color: var(--color-grey-400);

  @media ${getMediaQuery('smMin')} {
    font-size: 14px;
    line-height: 13px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 10px;
    line-height: 10px;
  }
`;

export const Value = styled.span`
  font-weight: 700;

  @media ${getMediaQuery('mdMin')} {
    font-size: 30px;
    line-height: 28px;
  }
  @media ${getMediaQuery('sm')} {
    font-size: 26px;
    line-height: 28px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const Metric = styled.span`
  color: var(--color-blue);
`;

export const Buttons = styled.div<IButtonsProps>`
  margin-top: 20px;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  transition: opacity 0.25s;

  ${({ isHide }) =>
    isHide &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

export const Submit = styled.div<ISubmitProps>`
  transition: transform 0.5s;

  ${({ isHide }) =>
    isHide &&
    css`
      transform: scale(0.99);
      pointer-events: none;
    `}
`;

export const StatusButtonWrapper = styled.div<IStatusButtonWrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate(-101%, 0);
  transition: transform 0.5s;

  ${({ isShow }) =>
    isShow &&
    css`
      transform: translate(0, 0);
    `}
`;

export const StatusButton = styled(ButtonSimple).attrs({
  variant: 'gold',
  hasBorder: true,
  fullWidth: true,
})`
  padding: 0;

  > * {
    width: 100%;
    height: var(--button-size);
  }
`;

export const StatusContainer = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StatusConfirm = styled.span<IStatusProps>`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s;

  ${({ isActive }) =>
    !isActive &&
    css`
      transform: translate(0, -100%);
    `}
`;

export const StatusPending = styled.span<IStatusProps>`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  transform: translate(0, 100%);
  transition: transform 0.5s;

  ${({ isActive }) =>
    isActive &&
    css`
      transform: none;
    `}
`;
