import React, { FC, useEffect, useId, useRef } from 'react';
import { LoadingOutline, SvgElement } from './styles';
import { TSvgProps } from '../types';
import { useSvg } from './useSvg';
import { useVariantColors } from '../utils/useVariantColors';

export const Svg: FC<TSvgProps> = ({
  isAppearAnimation,
  parentRef,
  contentRef,
  isHovered,
  variant,
  variantColors,
  hasStaticFill,
  borderRadius,
  isLoading,
  strokeWidth,
}) => {
  const id = useId();
  const colors = useVariantColors({ variant, variantColors, hasStaticFill });

  // elements
  const svgRef = useRef<SVGSVGElement>(null);
  const outlineRef = useRef<SVGRectElement>(null);
  const bgPlaneRef = useRef<SVGRectElement>(null);
  const leftHoverPlaneRef = useRef<SVGRectElement>(null);
  const rightHoverPlaneRef = useRef<SVGRectElement>(null);

  // svg
  const { width, height, outlineLength, setHover } = useSvg({
    isAppearAnimation,
    parentRef,
    svgRef,
    outlineRef,
    bgPlaneRef,
    leftHoverPlaneRef,
    rightHoverPlaneRef,
    contentRef,
  });
  const rx = borderRadius === 'arc' ? height / 2 : borderRadius;

  // hover
  useEffect(() => {
    setHover(isHovered);
  }, [isHovered, setHover]);

  return (
    <SvgElement
      ref={svgRef}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      aria-hidden
      style={{
        // @ts-ignore
        '--button-outline-length': outlineLength,
      }}
    >
      {/* static outline */}
      <rect
        ref={outlineRef}
        x={strokeWidth / 2}
        y={strokeWidth / 2}
        width={width - strokeWidth}
        height={height - strokeWidth}
        rx={rx - strokeWidth / 2}
        strokeWidth={strokeWidth}
        stroke={`url(#${id}-gradient-static)`}
      />

      {/* masked elements */}
      <g mask={`url(#${id}-mask)`}>
        {hasStaticFill && (
          <rect
            ref={bgPlaneRef}
            x="0"
            y="0"
            width={width}
            height={height}
            rx={rx}
            fill={`url(#${id}-gradient-static)`}
          />
        )}
        <rect
          ref={leftHoverPlaneRef}
          x={-(width / 2 + height)}
          y="0"
          width={width / 2 + height}
          height={height}
          rx={height / 2}
          fill={`url(#${id}-gradient-hover)`}
        />
        <rect
          ref={rightHoverPlaneRef}
          x={width}
          y="0"
          width={width / 2 + height}
          height={height}
          rx={height / 2}
          fill={`url(#${id}-gradient-hover)`}
        />
      </g>

      {/* loading */}
      {isLoading && (
        <LoadingOutline
          x={strokeWidth / 2}
          y={strokeWidth / 2}
          width={width - strokeWidth}
          height={height - strokeWidth}
          rx={rx - 1}
          strokeWidth={strokeWidth}
          stroke="#00FFF4"
        />
      )}

      <defs>
        <mask id={`${id}-mask`}>
          <rect
            x={strokeWidth / 2}
            y={strokeWidth / 2}
            width={width - strokeWidth}
            height={height - strokeWidth}
            rx={rx - strokeWidth / 2}
            fill="#fff"
          />
        </mask>
        <linearGradient
          id={`${id}-gradient-static`}
          x1={width * 0}
          y1={height * 1}
          x2={width * 1}
          y2={height * 0}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={colors.staticColor1} />
          <stop offset="1" stopColor={colors.staticColor2} />
        </linearGradient>
        <linearGradient
          id={`${id}-gradient-hover`}
          x1={width * 0}
          y1={height * 1}
          x2={width * 1}
          y2={height * 0}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={colors.hoverColor1} />
          <stop offset="1" stopColor={colors.hoverColor2} />
        </linearGradient>
      </defs>
    </SvgElement>
  );
};
