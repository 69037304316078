import React, { FC, useEffect } from 'react';
import { NumberFormat } from '@/general/number/format';
import { TRechartsData } from '../types';
import { IProps } from './types';
import { Color, Info, Layout } from './styles';

export const Tooltip: FC<IProps> = ({
  active,
  payload,
  tooltipText,
  tooltipColor,
  onActive,
  numberFormat,
}) => {
  useEffect(() => {
    if (payload && payload[0] && payload[0].payload) {
      onActive(payload[0].payload);
    }
  }, [active, onActive, payload]);

  if (active && payload && payload[0] && payload[0].payload) {
    const data = payload[0].payload as TRechartsData;

    return (
      <Layout>
        <span>{data.tooltipKey}</span>
        <Info>
          <Color style={{ backgroundColor: tooltipColor }} />
          <span>
            {tooltipText && `${tooltipText}: `}
            <NumberFormat value={data.value} {...numberFormat} />
          </span>
        </Info>
      </Layout>
    );
  }
  return null;
};
