import styled, { css } from 'styled-components';
import { getTransition } from '@/utils/styles/getTransition';
import { TSharedProps } from './types';

export const Layout = styled.div<TSharedProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ appearAnimation }) =>
    appearAnimation.use &&
    css`
      opacity: 0;

      ${appearAnimation.on &&
      css`
        opacity: 1;
        ${getTransition('opacity 1s')}
      `}
    `}

  svg {
    overflow: visible;
  }

  div {
    outline: none;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
