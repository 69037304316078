import React, { FC } from 'react';
import { Image } from './Image';
import {
  Background,
  Button,
  Container,
  Content,
  Description,
  Layout,
  Title,
} from './styles';
import { ISlideProps } from './types';

export const Slide: FC<ISlideProps> = ({
  className,
  style,
  image,
  link,
  description,
  title,
  buttonVariant,
}) => (
  <Layout className={className} style={style}>
    {image && (
      <Background>
        <Image source={image} />
      </Background>
    )}

    <Container>
      {title && <Title>{title}</Title>}
      {(description || link) && (
        <Content>
          {description && <Description>{description}</Description>}
          {link && (
            <Button tag="a" href={link.href} variant={buttonVariant || 'light'}>
              {link.name}
            </Button>
          )}
        </Content>
      )}
    </Container>
  </Layout>
);
