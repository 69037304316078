import styled, { css } from 'styled-components';
import { Swiper as SwiperFC, SwiperSlide } from 'swiper/react';
import { resetButtonStyles } from '@/utils/styles/resetButtonStyles';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { IContainerProps, INavButtonProps } from './types';
import arrowIcon from './arrow.svg';

export const Container = styled.div<IContainerProps>`
  position: relative;

  opacity: 0;
  ${({ isReady }) =>
    isReady &&
    css`
      opacity: 1;
    `}

  .swiper-scrollbar {
    position: absolute;
    right: 0;
    left: auto;
    bottom: -20px;
    width: 40px;
    height: 4px;
    background-color: #fff;

    @media ${getMediaQuery('smMin')} {
      display: none !important;
    }
  }

  .swiper-scrollbar-drag {
    border-radius: 2px;
    background-color: #6d6d6d;
  }
`;

export const Swiper = styled(SwiperFC)`
  user-select: none;
  overflow: visible;

  .swiper-pointer-events {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  &.stretch_height {
    .swiper-wrapper {
      align-items: stretch;
    }
  }
`;

export const Slide = styled(SwiperSlide)`
  &.stretch_height {
    height: auto;

    > * {
      height: 100%;
    }
  }
`;

export const NavButton = styled.div<INavButtonProps>`
  button {
    ${resetButtonStyles}
    border-radius: 50%;

    position: absolute;
    z-index: 1;
    top: 50%;
    width: 54px;
    height: 54px;
    margin-top: -27px;

    background-color: rgba(var(--color-black--rgb), 0.5);
    background-image: url('${arrowIcon}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;

    opacity: 0;
    transition: background-color 0.25s, opacity 0.25s;

    &:hover {
      background-color: rgba(var(--color-black--rgb), 0.75);
    }

    ${({ isPrev }) =>
      isPrev &&
      css`
        left: 0;
        transform: rotate(-180deg);
      `}

    ${({ isNext }) =>
      isNext &&
      css`
        right: 0;
      `}
  
    ${({ isShowNav }) =>
      isShowNav &&
      css`
        opacity: 1;

        &.swiper-button-disabled {
          opacity: 0;
          pointer-events: none;
        }
      `}
  }
`;
