import React, { FC } from 'react';
import { StyledSlide, StyledTitle } from './styles';
import { IProps } from './types';

import imageXS from './img/xs.png';
import imageSM from './img/sm.png';
import imageMD from './img/md.png';
import imageLG from './img/lg.png';
import imageXL from './img/xl.png';
import imageXXL from './img/xxl.png';

export const Rewards: FC<IProps> = ({ ...props }) => (
  <StyledSlide
    title={<StyledTitle />}
    image={{
      xs: imageXS,
      sm: imageSM,
      md: imageMD,
      lg: imageLG,
      xl: imageXL,
      xxl: imageXXL,
    }}
    buttonVariant="primary"
    {...props}
  />
);
