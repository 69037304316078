import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { resetUl } from '@/utils/styles/resetUl';

export const List = styled.ul`
  ${resetUl}
  display: flex;

  @media ${getMediaQuery('smMin')} {
    margin-left: 40px;
    margin-top: 4px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media ${getMediaQuery('xs')} {
    margin-top: 58px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const Group = styled.li`
  @media ${getMediaQuery('xlMin')} {
    min-width: 160px;
  }
  @media ${getMediaQuery('smMin')} {
    @media ${getMediaQuery('lgMax')} {
      min-width: 100px;
    }
  }

  + * {
    @media ${getMediaQuery('smMin')} {
      margin-left: 30px;
    }
    @media ${getMediaQuery('xs')} {
      margin-top: 32px;
    }
  }
`;

export const Title = styled.span`
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: capitalize;

  @media ${getMediaQuery('smMin')} {
    margin-bottom: 12px;
  }
  @media ${getMediaQuery('xs')} {
    margin-bottom: 8px;
  }
`;

export const Links = styled.ul`
  ${resetUl}
  font-size: 0;

  > * {
    + * {
      @media ${getMediaQuery('smMin')} {
        margin-top: 12px;
      }
      @media ${getMediaQuery('xs')} {
        margin-top: 4px;
      }
    }
  }
`;

export const Link = styled.a`
  ${resetUl}

  display: inline-block;
  width: max-content;

  font-weight: 400;
  font-size: 12px;
  text-decoration: none;

  transition: color 0.35s;

  @media ${getMediaQuery('smMin')} {
    color: var(--color-grey-500);
    line-height: 20px;
  }
  @media ${getMediaQuery('xs')} {
    color: var(--color-grey-600);
    line-height: 22px;
  }

  &:hover {
    color: var(--color-white);
  }
`;
