import React, { FC, useRef } from 'react';
import { FarmItemForm } from './form/Form';
import { FarmItemInfo } from './info/Info';
import { FarmItemRewards } from './rewards/Rewards';
import { IFarmItemFormHandle } from './form/types';
import { Button, Container, FormContainer, FormsWrapper } from './styles';
import { IFarmItemProps } from './types';

export const FarmItem: FC<IFarmItemProps> = ({
  icon,
  name,
  label,
  network,
  deposited,
  tvl,
  dailyRewards,
  apy,
  form,
  withdraw,
  rewards,
}) => {
  const formFormRef = useRef<IFarmItemFormHandle>(null);
  const withdrawFormRef = useRef<IFarmItemFormHandle>(null);

  return (
    <Container>
      <FarmItemInfo
        icon={icon}
        name={name}
        label={label}
        network={network}
        deposited={deposited}
        tvl={tvl}
        dailyRewards={dailyRewards}
        apy={apy}
      />
      <FormsWrapper>
        <FormContainer aria-label={form.submitText}>
          <FarmItemForm
            ref={formFormRef}
            label={label}
            balance={form.balance}
            min={form.min}
            max={form.max}
            icon={icon}
            renderButton={() => (
              <Button
                tag="button"
                type="submit"
                variant="primary"
                disabled={form.disabled}
              >
                {form.submitText}
              </Button>
            )}
            onSubmit={form.onSubmit}
          />
        </FormContainer>

        <FormContainer aria-label={form.submitText}>
          <FarmItemForm
            ref={withdrawFormRef}
            label={label}
            balance={withdraw.balance}
            min={withdraw.min}
            max={withdraw.max}
            icon={icon}
            renderButton={(val) => (
              <Button
                tag="button"
                type="submit"
                variant="secondary"
                disabled={!val}
              >
                Withdraw
              </Button>
            )}
            onSubmit={withdraw.onWithdraw}
          />
        </FormContainer>

        <FormContainer>
          <FarmItemRewards {...rewards} />
        </FormContainer>
      </FormsWrapper>
    </Container>
  );
};
