import { RefObject, useEffect, useRef } from 'react';
import { addEventListener } from 'vevet-dom';

const getAnchor = (el: any): HTMLAnchorElement | null => {
  if (el instanceof HTMLAnchorElement) {
    return el;
  }
  if (el.parentElement) {
    return getAnchor(el.parentElement);
  }
  return null;
};

export function useOnAnchorClick(
  ref: RefObject<HTMLElement>,
  callback?: (el: HTMLAnchorElement, evt: MouseEvent) => void
) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    if (!ref.current) {
      return undefined;
    }
    const listener = addEventListener(ref.current, 'click', (evt) => {
      const anchor = getAnchor(evt.target);
      if (anchor) {
        callbackRef.current?.(anchor, evt);
      }
    });
    return () => listener.remove();
  }, [ref]);
}
