import styled from 'styled-components';
import checked from './checked.svg';

export const CheckboxContainer = styled.span`
  position: relative;
  display: inline-block;
  width: 14px;
  height: 14px;
`;

export const Check = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;

  box-sizing: border-box;
  border: 1px solid var(--color-grey-500);

  background-color: var(--color-grey-825);
  transition: background 0.25s;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url('${checked}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 6px auto;

    opacity: 0;
    transform: scale(0.5);
    transition: opacity 0.25s, transform 0.25s;
  }
`;

export const Input = styled.input.attrs({
  type: 'checkbox',
})`
  margin: 0;
  padding: 0;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  opacity: 0;

  &:checked + ${Check} {
    background-color: var(--color-blue);

    &::after {
      opacity: 1;
      transform: scale(1) translateZ(0);
    }
  }
`;
