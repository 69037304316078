import React, { FC, useId, useMemo, useState } from 'react';
import { useOnResize } from '@/utils/resize';
import { vevetApp } from '@/utils/vevet';
import { CoinLFIButton } from './button/Button';
import { CoinLFIInfo } from './info/Info';
import { TProps } from './types';
import { Modal, Popup } from './styles';

export const CoinLFI: FC<TProps> = ({ isAppearAnimation }) => {
  const id = useId();

  const [usePopup, setUsePopup] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useOnResize(() => {
    setUsePopup(vevetApp.viewport.width > 1024);
  }, []);

  const Button = useMemo(
    () => (
      <CoinLFIButton
        isAppearAnimation={isAppearAnimation}
        onClick={() => {
          setIsModalOpen(true);
        }}
        id={`${id}-button`}
        aria-controls={`${id}-modal`}
      />
    ),
    [isAppearAnimation, id]
  );

  if (usePopup) {
    return (
      <Popup trigger={Button} id={id} direction="auto" alignment="start">
        <CoinLFIInfo />
      </Popup>
    );
  }

  return (
    <>
      {Button}
      <Modal
        id={`${id}-modal`}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <CoinLFIInfo />
      </Modal>
    </>
  );
};
