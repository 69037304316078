import React, { FC, PropsWithChildren } from 'react';
import { Head, Heading, Nav } from './styles';
import { IProps } from './types';

export const BoxHeading: FC<PropsWithChildren<IProps>> = ({
  heading,
  nav,
  children,
}) => (
  <section>
    <Head>
      <Heading>{heading}</Heading>
      {nav && <Nav>{nav}</Nav>}
    </Head>
    {children}
  </section>
);
