import React, { forwardRef } from 'react';
import { Icon, Link } from './styles';
import { IProps } from './types';

export const LinkExplore = forwardRef<HTMLAnchorElement, IProps>(
  ({ href, children, ...tagProps }, ref) => (
    <Link ref={ref} {...tagProps} href={href}>
      {children}
      <Icon alt="" />
    </Link>
  )
);
