import gsap from 'gsap';
import React, {
  forwardRef,
  PropsWithChildren,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { utils } from 'vevet';
import { IconError } from '@/internal/icons/IconError';
import { IconInfo } from '@/internal/icons/IconInfo';
import { Popup } from '@/general/popup';
import { Tooltip } from '@/general/tooltip';
import { useOnElementResize } from '@/utils/resize';
import styles from './Box.module.scss';
import { IProps } from './types';
import { LazyImage } from '@/internal/Image/Lazy';

const borderRadius = 8;

export const InputBox = forwardRef<HTMLDivElement, PropsWithChildren<IProps>>(
  (
    {
      isFocused,
      isFilled,
      label,
      icon,
      className,
      id: propId,
      info,
      error,
      children,
    },
    ref
  ) => {
    const parentRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => parentRef.current!);
    const dynamicId = useId();
    const id = propId || dynamicId;

    const classNames = [
      isFocused ? styles.focused : '',
      isFilled ? styles.filled : '',
      icon ? styles.has_icon : '',
      error ? styles.has_error : '',
    ].join(' ');

    // elements
    const focusRectRef = useRef<SVGRectElement>(null);
    const focusBgRef = useRef<HTMLDivElement>(null);

    // states
    const [svgWidth, setSvgWidth] = useState(4);
    const [svgHeight, setSvgHeight] = useState(4);

    /**
     * Resize the input
     */
    const resize = useCallback(() => {
      const parent = parentRef.current as HTMLElement;
      if (!parent) {
        return;
      }
      const parentBounding = parent.getBoundingClientRect();
      //
      const w = parentBounding.width;
      setSvgWidth(w);
      //
      const h = parentBounding.height;
      setSvgHeight(h);
    }, []);

    // resize event
    useOnElementResize(
      parentRef,
      () => {
        resize();
      },
      [resize]
    );

    // focus animation
    const focusProgress = useRef(isFocused ? 1 : 0);
    useEffect(() => {
      const focusRect = focusRectRef.current;
      const focusBg = focusBgRef.current;
      if (!focusRect || !focusBg) {
        return;
      }

      const direction = isFocused;
      resize();

      gsap.to(focusProgress, {
        current: direction ? 1 : 0,
        duration: 1,
        onUpdate: () => {
          const progress = focusProgress.current;

          // render rect
          let rectLength = 0;
          try {
            rectLength = focusRect.getTotalLength();
          } catch (e) {
            rectLength = 0;
          }
          const offset = rectLength * -0.5 - rectLength * progress;
          focusRect.style.opacity = progress === 0 ? '0' : '1';
          focusRect.style.strokeDasharray = `${rectLength * progress} ${
            rectLength * (1 - progress)
          }`;
          focusRect.style.strokeDashoffset = `${offset}`;

          // render bg
          const bgProgress = utils.math.clampScope(progress, [0.3, 1]);
          focusBg.style.transform = `scale(${bgProgress}, 1)`;
          focusBg.style.borderRadius = `${utils.math.lerp(
            100,
            borderRadius,
            bgProgress
          )}px`;
        },
      });
    }, [isFocused, resize]);

    return (
      <div
        ref={parentRef}
        className={`${styles.input_box} ${classNames} ${className}`}
      >
        <div ref={focusBgRef} className={styles.focus_bg} />

        <svg
          className={styles.svg}
          width={svgWidth}
          height={svgHeight}
          viewBox={`0 0 ${svgWidth} ${svgHeight}`}
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden
        >
          <rect
            ref={focusRectRef}
            x="0"
            y="0"
            width={svgWidth}
            height={svgHeight}
            rx={borderRadius}
            strokeWidth={2}
            className={styles.rect_focus}
            stroke={id ? `url('#${id}_gradient')` : undefined}
          />
          <defs>
            <linearGradient
              id={id ? `${id}_gradient` : undefined}
              x1="34.7143"
              y1="10.3704"
              x2="288.141"
              y2="165.513"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#00FFFF" />
              <stop offset="1" stopColor="#00A3FF" />
            </linearGradient>
          </defs>
        </svg>

        <label htmlFor={id} className={styles.label}>
          <div className={styles.input}>{children}</div>
          <span className={`${styles.label__text} ${classNames}`}>{label}</span>
        </label>

        {icon && (
          <LazyImage
            pos={false}
            src={icon}
            className={styles.icon}
            alt="icon"
            aria-hidden
          />
        )}

        {error && <IconError className={styles.error_icon} />}

        {info && (
          <Popup
            rectRef={parentRef}
            viewSizeRef={parentRef}
            show={info.show}
            className={styles.info_tooltip}
            useStyles={false}
            gap={0}
            direction="down"
            alignment="start"
            aria-hidden
          >
            <div className={styles.info_tooltip__content}>
              <IconInfo className={styles.info_tooltip__icon} />
              <div>{info.message}</div>
            </div>
          </Popup>
        )}

        {error && (
          <Tooltip
            rectRef={parentRef}
            viewSizeRef={parentRef}
            show={error.show}
            className={styles.error_tooltip}
            gap={3}
            viewGap={0}
            direction="down"
            alignment="center"
            useTextStyles={false}
            aria-hidden
          >
            {error.message}
          </Tooltip>
        )}
      </div>
    );
  }
);
