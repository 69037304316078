import React, { forwardRef } from 'react';
import { Button } from './styles';
import { TButtonBaseProps } from './types';

export const ButtonBase = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  TButtonBaseProps
>(({ children, size = 'medium', ...tagProps }, ref) => (
  <Button ref={ref as any} size={size} {...(tagProps as any)}>
    {children}
  </Button>
));
ButtonBase.displayName = 'ButtonBase';
