import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Timeline, utils } from 'vevet';
import { Wrapper } from './styles';
import { IProps } from './types';

export const AnimatedContent: FC<PropsWithChildren<IProps>> = ({
  isShown,
  className,
  style,
  children,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);

  // render the scene
  const progressRef = useRef(0);
  const render = useCallback(() => {
    const parent = parentRef.current;
    if (parent) {
      parent.style.opacity = `${utils.math.easing(progressRef.current)}`;
    }
  }, []);

  // animation timeline
  const timelineRef = useRef<Timeline | null>(null);
  useEffect(
    () => () => {
      timelineRef.current?.destroy();
      timelineRef.current = null;
    },
    []
  );

  // animate
  useEffect(() => {
    // create animation timeline if it does't exist yet
    if (!timelineRef.current) {
      timelineRef.current = new Timeline({ duration: 1000 });
      timelineRef.current.addCallback('progress', ({ progress }) => {
        progressRef.current = progress;
        render();
      });
    }

    // define animation direction
    if (isShown) {
      timelineRef.current.play();
    } else if (timelineRef.current.progress > 0) {
      timelineRef.current.reverse();
    }
  }, [isShown, render]);

  return (
    <Wrapper ref={parentRef} className={className} style={style}>
      {children}
    </Wrapper>
  );
};
