import React, { FC, useEffect, useRef, useState } from 'react';
import { useLottieScript } from '@/utils/useLottieScript';
import { LottieContainer } from './styles';
import lottieJSON from './img/success-lottie.json';
import { ILottieContainerProps } from './types';

export const LottieBackground: FC<ILottieContainerProps> = ({
  isActive,
  pos,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = useState<any | undefined>();

  useLottieScript((lottie) => {
    if (!parentRef.current) {
      return () => {};
    }
    let instance: any | undefined;
    try {
      instance = lottie.loadAnimation({
        container: parentRef.current,
        animationData: lottieJSON,
        autoplay: false,
        loop: false,
        rendererSettings: {
          viewBoxOnly: true,
        },
      });
      setAnimation(instance);
    } catch (e) {
      //
    }
    return () => {
      instance?.destroy();
    };
  });

  useEffect(() => {
    if (isActive) {
      animation?.play();
    }
  }, [animation, isActive]);

  return <LottieContainer ref={parentRef} pos={pos} />;
};
