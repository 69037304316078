import React, { FC } from 'react';
import { Container, Image, Label, Name } from './styles';
import { IPoolHeaderProps } from './types';

export const PoolHeader: FC<IPoolHeaderProps> = ({ img, name, label }) => (
  <Container>
    {img && <Image src={img} alt={name} />}
    <Name>{name}</Name>
    {label && <Label>{label}</Label>}
  </Container>
);
