import { useEffect, useState } from 'react';

export type TOpenAnimationState = 'closed' | 'opening' | 'opened' | 'closing';

export function useOpenAnimationState(
  bool: boolean,
  duration: {
    opening: number;
    closing: number;
  }
) {
  const [openState, setOpenState] = useState<TOpenAnimationState>(
    bool ? 'opened' : 'closed'
  );

  useEffect(() => {
    setOpenState((currentState) => {
      if (bool) {
        return currentState === 'opened' ? currentState : 'opening';
      }

      return currentState === 'closed' ? currentState : 'closing';
    });
  }, [bool]);

  useEffect(() => {
    let timerId: number | undefined;

    if (openState === 'opening') {
      timerId = window.setTimeout(() => {
        setOpenState('opened');
      }, duration.opening);
    } else if (openState === 'closing') {
      timerId = window.setTimeout(() => {
        setOpenState('closed');
      }, duration.closing);
    }

    return () => {
      window.clearTimeout(timerId);
    };
  }, [openState, duration]);

  return openState;
}
