import { RefObject } from 'react';
import { vevetApp } from '@/utils/vevet';
import { TPopupAlignment, TPopupDirection } from './types';

export function getPopupAutoDirection(
  triggerRect: DOMRect | undefined
): Exclude<TPopupDirection, 'auto'> | undefined {
  if (!triggerRect) {
    return undefined;
  }
  if (triggerRect.top > window.innerHeight / 2) {
    return 'up';
  }
  return 'down';
}

//

export function getPopupAutoAlignment(
  triggerRect: DOMRect | undefined
): Exclude<TPopupAlignment, 'auto'> | undefined {
  if (!triggerRect) {
    return undefined;
  }
  const xCenter = window.innerWidth / 2;
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  if (Math.abs(triggerCenter - xCenter) < 1) {
    return 'center';
  }
  if (triggerCenter < xCenter) {
    return 'start';
  }
  return 'end';
}

//

interface IDirectionProps {
  direction: Exclude<TPopupDirection, 'auto'>;
  triggerRect: DOMRect;
  gap: number;
}

interface IDirection {
  top: string;
  bottom: string;
  y: string;
}

export function getPopupDirectionStyles({
  direction,
  triggerRect,
  gap,
}: IDirectionProps): IDirection | undefined {
  if (!triggerRect) {
    return undefined;
  }
  if (direction === 'down') {
    return {
      top: `${triggerRect.bottom + gap}px`,
      bottom: 'auto',
      y: '0',
    };
  }
  if (direction === 'up') {
    return {
      top: 'auto',
      bottom: `${vevetApp.viewport.height - triggerRect.top + gap}px`,
      y: '0',
    };
  }
  if (direction === 'center') {
    return {
      top: `${triggerRect.top + triggerRect.height / 2}px`,
      bottom: 'auto',
      y: '-50%',
    };
  }
  return undefined;
}

//

interface IAlignmentProps {
  alignment: Exclude<TPopupAlignment, 'auto'>;
  triggerRect: DOMRect;
  gap: number;
  viewGap: number;
  viewSizeRef?: RefObject<any>;
}

interface IAlignment {
  left: string;
  right: string;
  x: string;
  maxWidth: string;
}

export function getPopupAlignmentStyles({
  alignment,
  triggerRect,
  gap,
  viewGap,
  viewSizeRef,
}: IAlignmentProps): IAlignment | undefined {
  if (!triggerRect) {
    return undefined;
  }

  // get view dom rect
  const vWidth = vevetApp.viewport.width;
  const viewEl = viewSizeRef?.current as Element;
  const viewRect = viewEl
    ? viewEl.getBoundingClientRect()
    : {
        left: 0,
        width: vWidth,
        right: vWidth,
      };

  if (alignment === 'start') {
    const { left } = triggerRect;
    const maxWidth = viewRect.right - left - viewGap;
    return {
      left: `${left}px`,
      right: 'auto',
      x: '0',
      maxWidth: `${maxWidth}px`,
    };
  }
  if (alignment === 'center') {
    const maxWidth =
      Math.min(
        triggerRect.left + triggerRect.width / 2 - viewRect.left,
        viewRect.right - (triggerRect.left + triggerRect.width / 2)
      ) *
        2 -
      viewGap * 2;
    return {
      left: `${triggerRect.left + triggerRect.width / 2}px`,
      right: 'auto',
      x: '-50%',
      maxWidth: `${maxWidth}px`,
    };
  }
  if (alignment === 'end') {
    const right = vWidth - triggerRect.right;
    const maxWidth = triggerRect.right - viewRect.left - viewGap;
    return {
      left: 'auto',
      right: `${right}px`,
      x: '0',
      maxWidth: `${maxWidth}px`,
    };
  }
  if (alignment === 'left') {
    const right = vevetApp.viewport.width - triggerRect.left + gap;
    const maxWidth = triggerRect.left - viewRect.left - gap - viewGap;
    return {
      left: 'auto',
      right: `${right}px`,
      x: '0',
      maxWidth: `${maxWidth}px`,
    };
  }
  if (alignment === 'right') {
    const left = triggerRect.right + gap;
    const maxWidth = viewRect.right - triggerRect.right - gap - viewGap;
    return {
      left: `${left}px`,
      right: 'auto',
      x: '0',
      maxWidth: `${maxWidth}px`,
    };
  }
  return undefined;
}
