import styled, { css } from 'styled-components';
import lfiIcon from './lfi.svg';
import { TIndicatorProps } from './types';

export const Container = styled.div`
  width: max-content;
`;

export const LFI = styled.div`
  margin: 0 auto;

  display: flex;
  width: max-content;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  background-color: rgba(var(--color-blue--rgb), 0.2);
  padding: 4px;

  font-size: 10px;
  font-weight: 600;
  line-height: 100%;
  color: var(--color-grey-200);

  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: url('${lfiIcon}') center no-repeat;
    background-size: 100%;
    margin-left: 2px;
  }
`;

export const Value = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 4px;
  gap: 4px;

  font-size: 14px;
  font-weight: 600;
  line-height: 100%;

  + ${LFI} {
    margin-top: 6px;
  }
`;

export const IndicatorSVG = styled.svg<TIndicatorProps>`
  path {
    fill: var(--color-success);
  }

  ${({ isNegative }) =>
    isNegative &&
    css`
      transform: rotate(-180deg);

      path {
        fill: var(--color-danger);
      }
    `}
`;
