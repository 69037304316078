import styled from 'styled-components';
import { resetButtonStyles } from '@/utils/styles/resetButtonStyles';
import { Icon as IconSVG } from './Icon';
import { Button as Btn } from '../index';

export const Button = styled(Btn)`
  ${resetButtonStyles}

  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-decoration: none;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: var(--color-white);

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transition: transform 0.35s;
    transform: scale(0, 1);
  }

  &:hover {
    &::before {
      transform: scale(1, 1);
    }
  }
`;

export const Icon = styled(IconSVG)`
  margin-top: -0.16em;
  margin-left: 0.5em;
  width: 1.33em;
  height: auto;
`;
