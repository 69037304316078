import styled from 'styled-components';
import { Popup as PopupFC } from '@/general/popup';

export const Popup = styled(PopupFC)`
  width: 100vw;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const List = styled.div`
  margin-top: 20px;
  margin-bottom: 8px;

  > * {
    + * {
      margin-top: 12px;
    }
  }
`;
