import React, { forwardRef } from 'react';
import { useTimeoutPropState } from '@/utils/useTimeoutPropState';
import { IProps } from './types';
import {
  Button as StyledButton,
  CloseIcon,
  Dot,
  DotsWrapper,
  Wrapper,
} from './styles';

export const Button = forwardRef<HTMLButtonElement, IProps>(
  ({ isAppearAnimation, className, style, ...props }, ref) => {
    const isChildrenAppearAnimation = useTimeoutPropState(
      isAppearAnimation,
      !isAppearAnimation ? 0 : 1200
    );

    return (
      <StyledButton
        ref={ref}
        tag="button"
        isAppearAnimation={isAppearAnimation}
        className={className}
        style={style}
        aria-label="Social links"
        {...props}
      >
        <Wrapper>
          <DotsWrapper>
            <Dot isAppearAnimation={isChildrenAppearAnimation} />
            <Dot isAppearAnimation={isChildrenAppearAnimation} />
            <Dot isAppearAnimation={isChildrenAppearAnimation} />
          </DotsWrapper>
          <CloseIcon />
        </Wrapper>
      </StyledButton>
    );
  }
);
