import React, { forwardRef } from 'react';
import { isNonNullable } from '@/utils/isNonNulable';
import { useTimeoutPropState } from '@/utils/useTimeoutPropState';
import { NumberCounter } from '@/general/number/counter';
import { NumberPercent } from '@/general/number/percent';
import { NumberFormat } from '@/general/number/format';
import { BoxOutline } from '@/general/box/outline';
import { Container, Icon, Row, Title, Network, Value } from './styles';
import { ITokenLfiAnalyticsProps } from './types';

export const TokenLfiAnalytics = forwardRef<
  HTMLDivElement,
  ITokenLfiAnalyticsProps
>(
  (
    {
      className,
      style,
      isAppearAnimation,
      icon,
      title,
      network,
      value,
      percent,
    },
    ref
  ) => {
    const isChildrenAppearAnimation = useTimeoutPropState(
      isAppearAnimation,
      !isAppearAnimation ? 0 : 500
    );

    return (
      <BoxOutline
        ref={ref}
        className={className}
        style={style}
        isAppearAnimation={isAppearAnimation}
      >
        <Container>
          <Row align="start">
            <Title>
              {title && (
                <>
                  {icon || <Icon />}
                  <span>{title}</span>
                </>
              )}
            </Title>
            {network && <Network {...network} />}
          </Row>

          <Row align="center">
            {isNonNullable(value) ? (
              <Value>
                {typeof isChildrenAppearAnimation === 'boolean' ? (
                  <NumberCounter
                    animation={isChildrenAppearAnimation}
                    {...value}
                    value={value?.value || 0}
                  />
                ) : (
                  <NumberFormat {...value} value={value?.value || 0} />
                )}
              </Value>
            ) : (
              <span />
            )}
            {isNonNullable(percent) && <NumberPercent value={percent} />}
          </Row>
        </Container>
      </BoxOutline>
    );
  }
);
TokenLfiAnalytics.displayName = 'TokenLfiAnalytics';
