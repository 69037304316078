import { useEffect, useState } from 'react';

export function useTimeoutPropState<T>(prop: T, time: number): T {
  const [state, setState] = useState<T>(prop);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setState(prop);
    }, time);
    return () => clearTimeout(timeout);
  }, [prop, time]);

  return state;
}
