import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';
import { Item, IItemProps } from './Item';
import styles from './styles.module.scss';
import { IProps, ITabContentItem } from './types';

export const TabContent = forwardRef<HTMLDivElement, IProps>(
  ({ items, selectedKey, duration = 500, className, ...tagProps }, ref) => {
    const parentRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => parentRef.current!);

    const [keyStates, setKeyStates] = useState<{
      prev: ITabContentItem['key'] | null;
      next: ITabContentItem['key'] | null;
    }>({ prev: null, next: selectedKey });

    // update keys
    useEffect(() => {
      setKeyStates((val) => {
        const saveNext = val.next;
        return {
          prev: saveNext,
          next: selectedKey,
        };
      });
    }, [selectedKey]);

    return (
      <div
        ref={parentRef}
        {...tagProps}
        className={['tab-content', styles.container, className].join(' ')}
        role="tablist"
      >
        {items.map((item) => {
          const { key } = item;
          let state: IItemProps['state'] = null;
          if (
            item.key === keyStates.next &&
            keyStates.prev === keyStates.next
          ) {
            state = 'selected';
          } else {
            if (key === keyStates.prev) {
              state = 'prev';
            }
            if (key === keyStates.next) {
              state = keyStates.prev === null ? 'selected' : 'next';
            }
          }

          return (
            <Item
              {...item}
              key={key}
              state={state}
              onShown={() => {}}
              onHidden={() => {
                setKeyStates((val) => {
                  if (val.prev === key) {
                    return {
                      ...val,
                      prev: null,
                    };
                  }
                  return val;
                });
              }}
              duration={duration}
            />
          );
        })}
      </div>
    );
  }
);
