import React, { FC, useRef } from 'react';
import { useHover } from '@/utils/useHover';
import { icons } from './icons';
import { IProps } from './types';
import {
  IconImgStatic,
  IconImgActive,
  IconWrapper,
  Link as LinkStyled,
  Name,
} from './styles';

export const Link: FC<IProps> = ({
  href,
  name,
  icon,
  iconActive,
  isUnactive,
  isActive,
  isAdaptive,
}) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const isHovered = useHover(ref);

  // @ts-ignore
  const iconSrc = (!!icon && icon in icons ? icons[icon] : '') || icon || '';
  const iconActiveSrc =
    // @ts-ignore
    (!!iconActive && iconActive in icons ? icons[iconActive] : '') ||
    iconActive ||
    '';

  return (
    <LinkStyled
      ref={ref}
      href={href}
      aria-label={name}
      role="menuitem"
      isUnactive={isUnactive}
      isActive={isActive}
      isAdaptive={isAdaptive}
      isHovered={isHovered}
    >
      <IconWrapper isActive={isActive}>
        {iconSrc && <IconImgStatic src={iconSrc} alt={name} />}
        {iconActiveSrc && <IconImgActive src={iconActiveSrc} alt={name} />}
      </IconWrapper>
      <Name isAdaptive={isAdaptive}>{name}</Name>
    </LinkStyled>
  );
};
