import styled from 'styled-components';
import { LazyImage } from '@/internal/Image/Lazy';

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 49.85%;
  margin-bottom: 48px;
`;

export const Image = styled(LazyImage).attrs({ pos: 'cover' })``;

export const IconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 100%;
  left: calc(50% - 7px);
  width: 64px;
  height: 64px;
  transform: translate(-50%, -50%);
  border-radius: 50%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: #21252a;
  border: 1px solid rgba(255, 255, 255, 0.25);

  > * {
    width: 30px;
    height: 30px;
    background: #ec0000;
    border-radius: 50%;
    box-shadow: 0 10px 20px 10px #21252a;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;
