import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { Portal } from 'react-portal';
import { ScrollBar } from 'vevet';
import { ConnectGrid } from '@/composed/connect/grid';
import { ConnectWallet } from '@/composed/connect/wallet';
import { ModalStaticError } from '@/internal/modal/static-error';
import { Provider } from '@/store/context';
import { vevetApp } from '@/utils/vevet';
import styles from './styles.module.scss';
import { Top } from './top/Top';
import { TProps } from './types';
import {
  Container,
  Content,
  ContentWrapper,
  Footer,
  MenuModal,
} from './styles';
import { LayoutPreloader } from '../preloader';
import { Aside } from './aside/Aside';
import { useOnAnchorClick } from './hooks/useOnAnchorClick';
import { useContainerStates } from './hooks/useContainerStates';

export const LayoutContainer: FC<PropsWithChildren<TProps>> = ({
  asideChildren,
  onAnchorClick,
  storeProps,
  menuLinks,
  pageTitle,
  footer,
  children,
}) => {
  const { connectNetwork } = storeProps;

  const containerRef = useRef<HTMLDivElement>(null);
  useOnAnchorClick(containerRef, onAnchorClick);

  const { isLayoutReady, isLayoutAppearAnimation, contentAppearAnimation } =
    useContainerStates(storeProps.states);

  // add scrollbar
  useEffect(() => {
    if (vevetApp.isMobile) {
      return undefined;
    }
    const scrollbar = new ScrollBar({
      container: window,
    });
    return () => {
      scrollbar.destroy();
    };
  }, []);

  return (
    <Provider value={storeProps}>
      <Container ref={containerRef} className={styles.container}>
        <Top
          isAppearAnimation={isLayoutAppearAnimation}
          title={pageTitle}
          social={footer?.social}
        />

        {/* aside */}
        <Aside
          isAppearAnimation={isLayoutAppearAnimation}
          menuLinks={menuLinks}
        >
          {asideChildren}
        </Aside>

        {/* main */}
        <Content appearAnimation={contentAppearAnimation}>
          <ContentWrapper>{children}</ContentWrapper>
          {footer && <Footer {...footer} />}
        </Content>

        {/* menu */}
        <Portal>
          <MenuModal links={menuLinks}>
            {asideChildren}
            <ConnectGrid />
          </MenuModal>
        </Portal>

        {/* connect wallet modal */}
        <ConnectWallet />

        {/* wrong network */}
        {connectNetwork.wrongNetwork && connectNetwork.wrongNetwork.modal && (
          <ModalStaticError {...connectNetwork.wrongNetwork.modal} />
        )}
      </Container>

      {typeof isLayoutReady !== 'undefined' && (
        <LayoutPreloader isHidden={isLayoutReady} />
      )}
    </Provider>
  );
};
