import React, { FC } from 'react';
import { ButtonSimple } from '@/general/button/simple';
import { Amount } from './Amount';
import styles from './index.module.scss';
import { Info } from './Info';
import { IProps } from './types';

export const TokenUpgrade: FC<IProps> = ({
  className,
  title,
  oldAmount,
  newAmount,
  tooltip,
  description,
  submit,
}) => (
  <section className={`${styles.token_upgrade} ${className || ''}`}>
    {title && <h2 className={styles.title}>{title}</h2>}

    <div className={styles.container}>
      <div className={styles.exchange}>
        <Amount value={oldAmount} title="Send" isNew={false} />
        <Amount value={newAmount} title="RECEIVE" isNew />
      </div>

      <Info className={styles.info} value={newAmount} tooltip={tooltip} />

      {description && <div className={styles.description}>{description}</div>}

      <ButtonSimple
        tag="button"
        onClick={submit.onSubmit}
        size="large"
        fullWidth
        className={styles.submit}
        disabled={submit.disabled}
      >
        {submit.name || 'Upgrade'}
      </ButtonSimple>
    </div>
  </section>
);
