import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Timeline, utils } from 'vevet';
import gsap from 'gsap';
import { clampScopeEasing } from '@/utils/clampScopeEasing';
import { useOnElementResize } from '@/utils/resize';
import { useStateToRef } from '@/utils/useStateToRef';
import { useAppearAnimation } from '@/utils/useAppearAnimation';

interface IProps {
  isAppearAnimation?: boolean;
  parentRef: RefObject<HTMLElement>;
  svgRef: RefObject<SVGSVGElement>;
  outlineRef: RefObject<SVGRectElement>;
  bgPlaneRef: RefObject<SVGRectElement>;
  leftHoverPlaneRef: RefObject<SVGRectElement>;
  rightHoverPlaneRef: RefObject<SVGRectElement>;
  contentRef: RefObject<HTMLElement>;
}

export function useSvg({
  isAppearAnimation,
  parentRef,
  svgRef,
  outlineRef,
  bgPlaneRef,
  leftHoverPlaneRef,
  rightHoverPlaneRef,
  contentRef,
}: IProps) {
  const appearAnimation = useAppearAnimation(isAppearAnimation);
  const showProgressRef = useRef(0);
  const hoverProgressRef = useRef(0);

  // states are used for html
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(100);
  const [outlineLength, setOutlineLength] = useState(0);

  // and refs are used in callbacks for better performance
  // to avoid multiple re-renders
  const widthRef = useStateToRef(width);
  const heightRef = useStateToRef(height);

  /**
   * Render the scene
   */
  const render = useCallback(() => {
    const showProgress = showProgressRef.current;
    const hoverProgress = hoverProgressRef.current;

    // elements
    const svg = svgRef.current;
    const outline = outlineRef.current;
    const bgPlane = bgPlaneRef.current;
    const leftHoverPlane = leftHoverPlaneRef.current;
    const rightHoverPlane = rightHoverPlaneRef.current;
    const content = contentRef.current;

    // show svg
    if (svg) {
      svg.style.opacity = `${utils.math.clampScope(showProgress, [0, 0.25])}`;
    }

    // render outline
    if (outline) {
      const progress = clampScopeEasing(showProgress, [0, 0.7]);

      let totalLength = 0;
      try {
        totalLength = outline?.getTotalLength() || 0;
      } catch (e) {
        totalLength = 0;
      }
      const dashArrayStart = 0;
      const dashArrayEnd = totalLength;
      const showOffset =
        heightRef.current * 2 + widthRef.current - totalLength * progress;
      outline.style.strokeDasharray = `
        ${utils.math.lerp(dashArrayStart, dashArrayEnd, progress)}
        ${utils.math.lerp(dashArrayEnd, dashArrayStart, progress)}
      `;
      outline.style.strokeDashoffset = `${showOffset}`;
    }

    // render bg plane
    if (bgPlane) {
      const progress = clampScopeEasing(showProgress, [0.6, 1]);
      bgPlane.style.transform = `translate3d(0, ${(1 - progress) * 100}%, 0)`;
    }

    // render content
    if (content) {
      const progress = clampScopeEasing(showProgress, [0.6, 1]);
      content.style.opacity = `${progress}`;
    }

    // render hover planes
    if (leftHoverPlane) {
      leftHoverPlane.setAttribute(
        'x',
        `${utils.math.lerp(
          -(widthRef.current / 2 + heightRef.current),
          -(heightRef.current / 2),
          hoverProgress
        )}`
      );
    }
    if (rightHoverPlane) {
      rightHoverPlane.setAttribute(
        'x',
        `${utils.math.lerp(
          widthRef.current,
          widthRef.current / 2 - heightRef.current / 2,
          hoverProgress
        )}`
      );
    }
  }, [
    bgPlaneRef,
    contentRef,
    heightRef,
    leftHoverPlaneRef,
    outlineRef,
    rightHoverPlaneRef,
    svgRef,
    widthRef,
  ]);

  /**
   * Resize the scene
   */
  const resize = () => {
    if (!svgRef.current) {
      return;
    }
    setWidth(utils.math.clamp(svgRef.current.clientWidth, [4, Infinity]));
    setHeight(utils.math.clamp(svgRef.current.clientHeight, [4, Infinity]));
    render();
  };

  // set resize event listener
  useOnElementResize(
    parentRef,
    () => {
      resize();
    },
    []
  );
  useLayoutEffect(() => {
    if (width) {
      let totalLength = 0;
      try {
        totalLength = outlineRef.current?.getTotalLength() || 0;
      } catch (e) {
        //
      }
      setOutlineLength(totalLength);
    }
  }, [outlineRef, width]);

  /**
   * Set show progress and render
   */
  const setShowProgress = useCallback(
    (val: number) => {
      showProgressRef.current = val;
      render();
    },
    [render]
  );

  // render appear animation
  useEffect(() => {
    if (!appearAnimation.use) {
      setShowProgress(1);
      return undefined;
    }

    if (!appearAnimation.on) {
      setShowProgress(0);
      return undefined;
    }

    const tm = new Timeline({ duration: 2000 });
    tm.addCallback('progress', ({ progress }) => setShowProgress(progress));
    tm.play();

    return () => {
      tm.destroy();
    };
  }, [appearAnimation.on, appearAnimation.use, setShowProgress]);

  /**
   * Enable/disable hover state
   */
  const setHover = (bool: boolean) => {
    gsap.to(hoverProgressRef, {
      current: bool ? 1 : 0,
      duration: 0.5,
      onUpdate: () => {
        render();
      },
    });
  };

  return {
    width,
    height,
    outlineLength,
    setHover,
  };
}
