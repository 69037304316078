import React, { FC, useState } from 'react';
import { TooltipInfo } from '@/general/tooltip/info';
import { Container } from './styles';
import styles from './styles.module.scss';
import { IProps } from './types';
import { TabButtons } from '@/general/tab/buttons';
import { NumberFormat } from '@/general/number/format';
import { RechartsLinear } from '@/internal/charts/linear';

export const FinanceTabChart: FC<IProps> = ({
  title,
  tabs,
  info,
  chartProps,
  numberFormat,
}) => {
  const tablist = tabs;
  const [tabKey, setTabKey] = useState(tablist[0].key);
  const tab = tablist.find(({ key }) => key === tabKey) || null;
  const value = tab?.value;

  let hasAxisY = false;
  if (typeof chartProps?.axis === 'boolean') {
    hasAxisY = true;
  } else if (chartProps?.axis?.y) {
    hasAxisY = true;
  }

  return (
    <Container aria-label={title} className={styles.root}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.head}>
            {title && (
              <div className={styles.title}>
                {title}
                {info && <TooltipInfo>{info}</TooltipInfo>}
              </div>
            )}
            {tablist && tablist.length > 1 && (
              <TabButtons
                styles="mininav"
                items={tablist}
                selectedKey={tabKey}
                onSelect={setTabKey}
              />
            )}
          </div>

          {typeof value === 'number' && (
            <div className={styles.value}>
              <NumberFormat value={value} {...numberFormat} />
            </div>
          )}
        </div>

        <div
          className={[styles.chart, hasAxisY ? styles.has_axis_y : ''].join(
            ' '
          )}
        >
          {tab && (
            <RechartsLinear
              data={tab.data}
              grid={{ x: false, y: false }}
              axis={{ x: false, y: false }}
              dot={{
                r: 6,
                fill: '#FFFFFF',
                stroke: '#2320C9',
                strokeWidth: 3,
              }}
              {...chartProps}
              numberFormat={numberFormat}
            />
          )}
        </div>
      </div>
    </Container>
  );
};
