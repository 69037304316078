import styled, { css } from 'styled-components';
import { LazyImage } from '@/internal/Image/Lazy';
import { TSharedProps } from './types';

export const Label = styled.label<TSharedProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  font-size: 16px;
  line-height: 24px;

  transition: opacity 0.25s;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.25;
      cursor: default;
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 700;
    `}
`;

export const IconWrapper = styled.span`
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 12px;
  flex-shrink: 0;

  img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
`;

export const Icon = styled(LazyImage).attrs({ pos: false })`
  width: 30px;
  height: 30px;
  object-fit: contain;
`;

export const Check = styled.span<TSharedProps>`
  display: block;
  margin-left: 12px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-success);
  opacity: 0;
  transition: opacity 0.25s;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
    `}
`;

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
`;
