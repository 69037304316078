import styled from 'styled-components';
import { LazyImage } from '@/internal/Image/Lazy';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media ${getMediaQuery('smMin')} {
    gap: 20px;
  }
  @media ${getMediaQuery('xs')} {
    gap: 18px;
  }
`;

export const Image = styled(LazyImage).attrs({ pos: false })`
  @media ${getMediaQuery('smMin')} {
    width: 44px;
    height: 44px;
  }
  @media ${getMediaQuery('xs')} {
    width: 36px;
    height: 36px;
  }
`;

export const Name = styled.h2`
  margin: 0;

  @media ${getMediaQuery('smMin')} {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
  }
  @media ${getMediaQuery('xs')} {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Label = styled.div`
  color: var(--color-grey-600);

  @media ${getMediaQuery('smMin')} {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
  }
  @media ${getMediaQuery('xs')} {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
`;
