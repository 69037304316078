import styled, { css } from 'styled-components';
import { TProps } from './types';
import { Button as Btn } from '../index';
import { resetButtonStyles } from '@/utils/styles/resetButtonStyles';

const getSharedStyles = ({ size, fullWidth }: TProps) => css`
  ${resetButtonStyles}

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: ${fullWidth ? '100%' : 'max-content'};
  min-height: var(--button-size);

  ${size === 'large' &&
  css`
    --button-size: 54px;
    font-size: 16px;
    font-weight: 500;
  `}

  ${size === 'medium' &&
  css`
    --button-size: 44px;
    font-size: 14px;
    font-weight: 500;
  `}

  ${size === 'small' &&
  css`
    --button-size: 34px;
    font-size: 12px;
    font-weight: 600;
  `}
`;

export const Button = styled(Btn)`
  ${getSharedStyles}
`;
