import React, { FC } from 'react';
import styles from './Label.module.scss';

interface IProps {
  isNew: boolean;
  className?: string;
}

export const Label: FC<IProps> = ({ isNew, className }) => (
  <div className={[styles.label, isNew ? styles.new : '', className].join(' ')}>
    {isNew ? 'New' : 'Old'}
  </div>
);
