import { Discord } from './Discord';
import { FAQ } from './FAQ';
import { Rewards } from './Rewards';

Discord.displayName = 'BannerSliderItem.Discord';
FAQ.displayName = 'BannerSliderItem.FAQ';
Rewards.displayName = 'BannerSliderItem.Rewards';

export const BannerSliderItem = {
  Discord,
  FAQ,
  Rewards,
};
