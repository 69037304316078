import React, { FC, useId } from 'react';
import { AreaChart, Area } from 'recharts';
import { Recharts } from '../recharts';
import { IRechartsLinear } from './types';

export const RechartsLinear: FC<IRechartsLinear> = ({
  bg = {
    colorStart: '#00FFF4',
    colorStartAlpha: 1,
    colorEnd: '#00FFF4',
    colorEndAlpha: 0.1,
  },
  line = {
    colorStart: '#00FFF4',
    colorStartAlpha: 1,
    colorEnd: '#00FFF4',
    colorEndAlpha: 1,
  },
  dot = {
    r: 6,
    fill: '#00FFF4',
    stroke: '#fff',
    strokeWidth: 2,
  },
  ...rechartsProps
}) => {
  const id = useId();
  const baseValue =
    [...rechartsProps.data]
      .filter((item) => item.value < 0)
      .sort((a, b) => a.value - b.value)[0]?.value || 0;

  return (
    <Recharts
      {...rechartsProps}
      render={({ width, height, data, children }) => (
        <AreaChart
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          width={width}
          height={height}
          data={data.data}
          // @ts-ignore
          baseValue={baseValue * 2}
        >
          {children}
          <defs>
            {bg && (
              <linearGradient
                id={`${id}_bg`}
                x1="0"
                y1="0"
                x2="0"
                y2={height * 0.9}
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0"
                  stopColor={bg.colorStart}
                  stopOpacity={bg.colorStartAlpha}
                />
                <stop
                  offset="1"
                  stopColor={bg.colorEnd}
                  stopOpacity={bg.colorEndAlpha}
                />
              </linearGradient>
            )}
            {line && (
              <linearGradient
                id={`${id}_line`}
                x1="0"
                y1="0"
                x2={width}
                y2={0}
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0"
                  stopColor={line.colorStart}
                  stopOpacity={line.colorStartAlpha}
                />
                <stop
                  offset="1"
                  stopColor={line.colorEnd}
                  stopOpacity={line.colorEndAlpha}
                />
              </linearGradient>
            )}
          </defs>
          <Area
            type="monotone"
            dataKey="value"
            stroke={line ? `url('#${id}_line')` : ''}
            strokeLinecap="round"
            strokeWidth={3}
            fill={bg ? `url('#${id}_bg')` : ''}
            animationDuration={data.duration}
            activeDot={{
              r: dot.r,
              style: {
                fill: dot.fill,
                stroke: dot.stroke,
                strokeWidth: dot.strokeWidth,
              },
            }}
          />
        </AreaChart>
      )}
    />
  );
};
