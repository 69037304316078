import React, { FC, useId } from 'react';
import { Check, Icon, IconWrapper, Input, Label } from './styles';
import { TProps } from './types';

export const Item: FC<TProps> = ({
  keyVal,
  isActive,
  onSelect,
  popupRef,
  ...network
}) => {
  const id = useId();
  const { name, preview, isDisabled } = network;

  return (
    <Label
      key={name}
      htmlFor={`${id}-${keyVal}`}
      isActive={isActive}
      isDisabled={isDisabled}
    >
      <IconWrapper>
        {preview && <Icon src={preview} alt={name} aria-hidden />}
      </IconWrapper>

      {name}

      <Check isActive={isActive} isDisabled={isDisabled} />

      <Input
        id={`${id}-${keyVal}`}
        type="radio"
        name={`${id}-network`}
        value={name}
        checked={isActive}
        disabled={isDisabled}
        onChange={(e) => {
          if (e.target.checked) {
            onSelect(network);
          }
          popupRef.current?.close();
        }}
      />
    </Label>
  );
};
