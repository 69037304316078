import { useCallback, useEffect, useRef } from 'react';

export interface IUseIntervalAPI {
  start: (delay: number) => () => void;
  stop: () => void;
}

export function useInterval(
  callback: () => void,
  initDelay?: number
): IUseIntervalAPI {
  const timerRef = useRef<number | undefined>(undefined);
  const callbackRef = useRef(callback);

  const stopInterval = (): void => {
    window.clearInterval(timerRef.current);
    timerRef.current = undefined;
  };

  const stop = useCallback(stopInterval, []);

  const startInterval = (time: number): (() => void) => {
    stop();
    timerRef.current = window.setInterval(() => callbackRef.current(), time);
    return stop;
  };

  const start = useCallback(startInterval, [stop, callbackRef]);

  const checkInitialDelay = (): (() => void) | void =>
    initDelay ? start(initDelay) : undefined;

  useEffect(checkInitialDelay, [initDelay, start]);

  return {
    start,
    stop,
  };
}
