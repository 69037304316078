import styled from 'styled-components';
import { LazyImage } from '@/internal/Image/Lazy';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  min-height: 64px;
  background-color: var(--color-grey-830);
  border: 1px solid var(--color-grey-800);
  box-sizing: border-box;
  padding: 10px 16px;
  border-radius: 8px;
`;

export const APYContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const APYLabel = styled.div`
  margin-right: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: var(--color-grey-600);
`;

export const CoinContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 0;
`;

export const CoinImg = styled(LazyImage).attrs({ pos: false })`
  display: block;
  width: 36px;
  height: 36px;
`;

export const CoinContent = styled.div`
  > * + * {
    margin-top: 4px;
  }
`;

export const CoinName = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
`;
