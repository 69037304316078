import { css } from 'styled-components';

export const resetButtonStyles = css`
  appearance: none;
  background: transparent;
  border: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  user-select: none;
`;
