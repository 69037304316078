import React, { forwardRef, HTMLAttributes } from 'react';

export interface IProps extends HTMLAttributes<SVGSVGElement> {}

export const IconError = forwardRef<SVGSVGElement, IProps>((tagProps, ref) => (
  <svg
    ref={ref}
    {...tagProps}
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16.5" cy="16" r="16" fill="#DC2C2C" />
    <path
      d="M18.1251 10L17.9035 18.1477H15.8239L15.5967 10H18.1251ZM16.8637 21.7841C16.4887 21.7841 16.1667 21.6515 15.8978 21.3864C15.6289 21.1174 15.4963 20.7955 15.5001 20.4205C15.4963 20.0492 15.6289 19.7311 15.8978 19.4659C16.1667 19.2008 16.4887 19.0682 16.8637 19.0682C17.2236 19.0682 17.5399 19.2008 17.8126 19.4659C18.0853 19.7311 18.2236 20.0492 18.2274 20.4205C18.2236 20.6705 18.1573 20.8996 18.0285 21.108C17.9035 21.3125 17.7387 21.4773 17.5342 21.6023C17.3296 21.7235 17.1061 21.7841 16.8637 21.7841Z"
      fill="white"
    />
  </svg>
));
