import styled, { css } from 'styled-components';
import { getTransition } from '@/utils/styles/getTransition';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { TSharedProps } from './types';

export const Link = styled.a<TSharedProps>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;

  padding-left: 40px;

  @media ${getMediaQuery('xs')} {
    @media (max-width: 360px) {
      padding-left: 30px;
    }
  }

  ${({ isAdaptive }) =>
    isAdaptive &&
    css`
      @media ${getMediaQuery('mdMax')} {
        padding-left: 8px;
        justify-content: center;
      }
    `}

  min-height: 56px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  color: var(--color-grey-200);

  @media ${getMediaQuery('xs')} {
    min-height: 49px;
    font-size: 14px;
  }

  ${getTransition('opacity 0.35s, color 0.35s, font-weight 0.35s')};

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-blue);
    border-radius: 0 8px 8px 0;
    transform: translate3d(-105%, 0, 0);
    ${getTransition('transform 0.75s')};
  }

  &::before {
    opacity: 0.15;
  }

  &::after {
    width: 8px;
  }

  ${({ isUnactive, isHovered }) =>
    isUnactive &&
    !isHovered &&
    css`
      opacity: 1;
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      color: var(--colro-white);
    `}

  ${({ isHovered, isActive }) =>
    (isHovered || isActive) &&
    css`
      &::before,
      &::after {
        transform: translate3d(0, 0, 0);
      }
      &::before {
        transition-delay: 0.1s;
      }
      &::after {
        transition-delay: 0.3s;
      }
    `}
`;

const IconImg = styled.img.attrs({
  'aria-hidden': true,
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${getTransition('opacity 0.35s')};
`;

export const IconImgStatic = styled(IconImg)``;

export const IconImgActive = styled(IconImg)`
  opacity: 0;
`;

export const IconWrapper = styled.span<TSharedProps>`
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 24px;
  height: 24px;

  ${({ isActive }) =>
    isActive &&
    css`
      ${IconImgStatic} {
        opacity: 0;
      }

      ${IconImgActive} {
        opacity: 1;
      }
    `}
`;

export const Name = styled.span<TSharedProps>`
  position: relative;
  z-index: 1;

  display: inline-block;
  margin-left: 16px;

  ${({ isAdaptive }) =>
    isAdaptive &&
    css`
      @media ${getMediaQuery('mdMax')} {
        display: none;
      }
    `}
`;
