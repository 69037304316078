import React, { forwardRef } from 'react';
import { Li, Link, List } from './styles';
import { TProps } from './types';

export const NavigationBreadcrumbs = forwardRef<HTMLElement, TProps>(
  ({ items, className, ...tagProps }, ref) => (
    <nav ref={ref} aria-label="Breadcrumbs" {...tagProps}>
      <List>
        {items.map(({ href, name }, index) => {
          const isActive = index === items.length - 1;
          return (
            <Li key={name} isActive={isActive}>
              <Link
                href={href}
                aria-current={isActive ? 'page' : undefined}
                isActive={isActive}
              >
                {name}
              </Link>
            </Li>
          );
        })}
      </List>
    </nav>
  )
);
NavigationBreadcrumbs.displayName = 'NavigationBreadcrumbs';
