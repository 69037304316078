import styled, { css } from 'styled-components';
import { getTransition } from '@/utils/styles/getTransition';
import { IProps } from './types';

export const Container = styled.div<IProps>`
  position: relative;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-color: var(--color-grey-1000);
  border: 1px solid var(--color-grey-800);
  border-radius: 8px;

  ${({ hasHover }) =>
    hasHover &&
    css`
      transition: background 0.5s;

      &:hover {
        background-color: var(--color-grey-900);
      }
    `}

  ${({ isAppearAnimation }) =>
    typeof isAppearAnimation === 'boolean' &&
    css`
      transform: scale(0.1);
      ${getTransition(
        isAppearAnimation
          ? 'opacity 1.25s, transform 1.25s, background 0.5s'
          : 'initial'
      )}
      opacity: ${isAppearAnimation ? 1 : 0};
      transform: scale(${isAppearAnimation ? 1 : 0.5});
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;
