import { RefObject, useEffect, useState } from 'react';
import { utils } from 'vevet';

interface IElement extends Element {
  observerInCallback?: () => void;
  observerOutCallback?: () => void;
}

const observer =
  typeof window !== 'undefined' &&
  utils.listeners.intersectionObserverSupported()
    ? new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const element = entry.target as IElement;
            if (entry.isIntersecting) {
              element.observerInCallback?.();
            } else {
              element.observerOutCallback?.();
            }
          });
        },
        {
          root: null,
          threshold: 0,
          rootMargin: '0px 0px 0px 0px',
        }
      )
    : null;

interface IProps {
  el: RefObject<any> | Element;
  in?: () => void;
  destroyOnIn?: boolean;
  out?: () => void;
}

/**
 * When the element is in/out of the viewport
 */
export function useViewportInOut({
  el,
  in: inCallback,
  destroyOnIn,
  out: outCallback,
}: IProps) {
  const [isIn, setIsIn] = useState(false);
  const [isOut, setIsOut] = useState(false);

  useEffect(() => {
    const element = 'current' in el ? el.current : el;
    if (!element) {
      return undefined;
    }

    element.observerInCallback = () => {
      setIsIn(true);
      setIsOut(false);
      inCallback?.();
      if (destroyOnIn) {
        observer?.unobserve(element);
      }
    };
    element.observerOutCallback = () => {
      setIsIn(false);
      setIsOut(true);
      outCallback?.();
    };
    observer?.observe(element);

    return () => {
      element.observerInCallback = undefined;
      element.observerOutCallback = undefined;
      observer?.unobserve(element);
    };
  }, [destroyOnIn, el, inCallback, outCallback]);

  return { isIn, isOut };
}
