import styled, { css } from 'styled-components';
import { ButtonSimple } from '@/general/button/simple';
import { ButtonSvgCircleFill } from '@/general/button/svg-circle-fill';
import { IContainerProps } from './types';

export const Container = styled.div<IContainerProps>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 0 7px 20px;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0.35s, opacity 0.35s;

  ${({ animationState }) =>
    (animationState === 'opening' || animationState === 'opened') &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

export const Title = styled.div`
  margin-top: 20px;
  width: 100%;

  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`;

export const Description = styled.div`
  margin-top: 11px;

  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 157%;
  color: var(--color-grey-200);

  a {
    text-decoration: none;
    font-weight: 600;
    color: var(--color-blue);
  }
`;

export const Buttons = styled.div`
  margin-top: 49px;
  width: 100%;

  > * + * {
    margin-top: 10px;
  }
`;

export const CancelButton = styled(ButtonSimple).attrs({
  variant: 'primary',
  size: 'large',
  fullWidth: true,
})`
  --button-size: 60px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const ContinueButton = styled(ButtonSvgCircleFill).attrs({
  variant: 'gradient',
  size: 'large',
  fullWidth: true,
  hasStaticFill: false,
  strokeWidth: 1,
})`
  --button-size: 60px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
