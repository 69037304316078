import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { Title } from './Title';
import { Slide } from '@/general/banner/slider/slide';
import {
  Content,
  Description,
  Button,
} from '@/general/banner/slider/slide/styles';

export const StyledSlide = styled(Slide)`
  ${Content} {
    @media ${getMediaQuery('mdMax')} {
      flex-direction: column-reverse;
    }

    > * {
      @media ${getMediaQuery('mdMax')} {
        margin: 0;

        + * {
          margin: 0 0 8px 0;
        }
      }
    }
  }

  ${Description} {
    display: block;
    max-width: initial;

    @media ${getMediaQuery('mdMax')} {
      font-size: 10px;
      line-height: 15px;
    }
  }

  ${Button} {
    min-width: 192px;
    --button-size: 38px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;

    @media ${getMediaQuery('xxl')} {
      min-width: 224px;
    }
  }
`;

export const StyledTitle = styled(Title)`
  display: block;
  height: auto;
  width: 225px;

  @media ${getMediaQuery('mdMax')} {
    width: 194px;
  }
`;
