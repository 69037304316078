import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { ButtonSimple } from '@/general/button/simple';

export const Container = styled.div`
  @media ${getMediaQuery('xlMin')} {
    background-color: var(--color-grey-1150);
    border: 1px solid var(--color-grey-800);
    border-radius: 8px;
    padding: 10px 10px 48px;
  }
`;

export const Button = styled(ButtonSimple).attrs({
  fullWidth: true,
  size: 'large',
})`
  @media ${getMediaQuery('xs')} {
    min-height: 46px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
  }
`;

export const FormsWrapper = styled.div`
  @media ${getMediaQuery('xlMin')} {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 8px;

    > * {
      box-sizing: border-box;
      width: 33.333%;

      &:not(:last-child) {
        padding-right: 24px;
      }
    }
  }
`;

export const FormContainer = styled.div`
  @media ${getMediaQuery('lgMax')} {
    background-color: var(--color-grey-1150);
    border: 1px solid var(--color-grey-800);
    border-radius: 8px;

    @media ${getMediaQuery('smMin')} {
      padding: 34px 24px 30px;
      margin-top: 16px;
    }

    @media ${getMediaQuery('xs')} {
      padding: 16px 10px 20px;
      margin-top: 10px;
    }
  }
`;
