import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { TProps } from './types';
import { useHover } from '@/utils/useHover';
import { Bg, Button, Content } from './styles';

export const ButtonOutline = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  TProps
>(({ variant = 'primary', isForceHover, children, ...tagProps }, ref) => {
  const domRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);
  useImperativeHandle(ref, () => domRef.current!);

  const isHovered = useHover(domRef);

  return (
    <Button
      ref={domRef}
      variant={variant}
      isHovered={isHovered || isForceHover}
      {...tagProps}
    >
      <Bg isHovered={isHovered || isForceHover} />
      <Content>{children}</Content>
    </Button>
  );
});
ButtonOutline.displayName = 'ButtonOutline';
