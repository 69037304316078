import React, { FC } from 'react';
import { LazyImage } from '@/internal/Image/Lazy';
import { IImageProps } from './types';
import { MediaBreakpointContent } from '@/internal/MediaBreakpointContent';

export const Image: FC<IImageProps> = ({ source }) => {
  if (typeof source === 'string') {
    return <LazyImage src={source} alt="banner" />;
  }

  return (
    <>
      {Object.entries(source).map(([breakpoint, src]) => (
        <MediaBreakpointContent key={breakpoint} breakpoint={breakpoint as any}>
          <LazyImage src={src} alt="banner" />
        </MediaBreakpointContent>
      ))}
    </>
  );
};
