import React, { forwardRef, HTMLAttributes } from 'react';

export interface IProps extends HTMLAttributes<SVGSVGElement> {}

export const IconInfo = forwardRef<SVGSVGElement, IProps>((tagProps, ref) => (
  <svg
    ref={ref}
    {...tagProps}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99999 6.23195e-06C10.1821 -0.00138681 11.3528 0.230775 12.4449 0.683162C13.537 1.13554 14.529 1.79922 15.3639 2.63607C16.2007 3.47095 16.8644 4.46294 17.3168 5.55506C17.7692 6.64718 18.0014 7.8179 18 8.99999C18.0014 10.1821 17.7692 11.3528 17.3168 12.4449C16.8645 13.5371 16.2008 14.529 15.3639 15.3639C14.529 16.2008 13.5371 16.8645 12.4449 17.3168C11.3528 17.7692 10.1821 18.0014 8.99998 18C7.81789 18.0014 6.64717 17.7692 5.55506 17.3168C4.46296 16.8644 3.47098 16.2008 2.6361 15.3639C1.79923 14.529 1.13555 13.5371 0.683162 12.4449C0.230774 11.3528 -0.00138811 10.1821 6.2437e-06 8.99998C-0.0013609 7.81789 0.230811 6.64717 0.683193 5.55506C1.13557 4.46295 1.79925 3.47098 2.6361 2.6361C3.47098 1.79925 4.46295 1.13557 5.55506 0.683193C6.64718 0.23081 7.8179 -0.00136223 8.99999 6.23195e-06ZM9 17.1818L9.00106 16.3636C9.96791 16.3649 10.9255 16.1751 11.8187 15.805C12.712 15.435 13.5233 14.8921 14.2061 14.2076L14.2076 14.2061C14.8921 13.5233 15.435 12.712 15.805 11.8187C16.1751 10.9255 16.3649 9.96791 16.3636 9.00106V8.99896C16.3649 8.03211 16.175 7.07454 15.805 6.18129C15.435 5.28804 14.8921 4.47671 14.2076 3.79393L14.2061 3.79242C13.5233 3.10785 12.712 2.56496 11.8187 2.19496C10.9255 1.82495 9.96791 1.63512 9.00106 1.63637H8.99896C8.03211 1.63514 7.07454 1.82498 6.18129 2.19499C5.28804 2.565 4.47671 3.10788 3.79393 3.79243L3.79243 3.79393C3.10788 4.47671 2.565 5.28804 2.19499 6.18129C1.82498 7.07454 1.63514 8.03211 1.63637 8.99896V9.00106C1.63512 9.96791 1.82495 10.9255 2.19496 11.8187C2.56496 12.712 3.10785 13.5233 3.79242 14.2061L3.79393 14.2076C4.47671 14.8921 5.28804 15.435 6.18129 15.805C7.07454 16.175 8.03211 16.3649 8.99896 16.3636L9 17.1818Z"
      fill="#00FFFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99999 14.3182C9.27123 14.3182 9.53137 14.2104 9.72317 14.0186C9.91497 13.8268 10.0227 13.5667 10.0227 13.2954C10.0227 13.0242 9.91497 12.7641 9.72317 12.5723C9.53137 12.3805 9.27123 12.2727 8.99999 12.2727C8.72875 12.2727 8.46861 12.3805 8.27681 12.5723C8.08502 12.7641 7.97726 13.0242 7.97726 13.2954C7.97726 13.5667 8.08502 13.8268 8.27681 14.0186C8.46861 14.2104 8.72875 14.3182 8.99999 14.3182Z"
      fill="#00FFFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99999 3.27271C9.45186 3.27271 9.81817 3.63902 9.81817 4.09089V10.6363C9.81817 11.0882 9.45186 11.4545 8.99999 11.4545C8.54812 11.4545 8.18181 11.0882 8.18181 10.6363V4.09089C8.18181 3.63902 8.54812 3.27271 8.99999 3.27271Z"
      fill="#00FFFF"
    />
  </svg>
));
