import styled, { css } from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { TContainerProps, TItemProps } from './types';

const getContainerSpacings = (props: TContainerProps) => {
  const base = 4;

  return css`
    ${typeof props.spacing !== 'undefined' &&
    css`
      --spacing: ${props.spacing * base}px;
    `}

    ${typeof props.xs !== 'undefined' &&
    css`
      @media ${getMediaQuery('xs')} {
        --spacing: ${props.xs * base}px;
      }
    `}
  ${typeof props.sm !== 'undefined' &&
    css`
      @media ${getMediaQuery('sm')} {
        --spacing: ${props.sm * base}px;
      }
    `}
  ${typeof props.md !== 'undefined' &&
    css`
      @media ${getMediaQuery('md')} {
        --spacing: ${props.md * base}px;
      }
    `}
  ${typeof props.lg !== 'undefined' &&
    css`
      @media ${getMediaQuery('lg')} {
        --spacing: ${props.lg * base}px;
      }
    `}
  ${typeof props.xl !== 'undefined' &&
    css`
      @media ${getMediaQuery('xl')} {
        --spacing: ${props.xl * base}px;
      }
    `}
  ${typeof props.xxl !== 'undefined' &&
    css`
      @media ${getMediaQuery('xxl')} {
        --spacing: ${props.xxl * base}px;
      }
    `}
  `;
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  ${getContainerSpacings}
  width: calc(100% + var(--spacing));
  margin-left: calc(-1 * var(--spacing));
  margin-top: calc(-1 * var(--spacing));
`;

const getItemSizes = (props: TItemProps) => css`
  ${typeof props.xs !== 'undefined' &&
  css`
    @media ${getMediaQuery('xs')} {
      width: ${(props.xs / 12) * 100}%;
    }
  `}
  ${typeof props.sm !== 'undefined' &&
  css`
    @media ${getMediaQuery('sm')} {
      width: ${(props.sm / 12) * 100}%;
    }
  `}
  ${typeof props.md !== 'undefined' &&
  css`
    @media ${getMediaQuery('md')} {
      width: ${(props.md / 12) * 100}%;
    }
  `}
  ${typeof props.lg !== 'undefined' &&
  css`
    @media ${getMediaQuery('lg')} {
      width: ${(props.lg / 12) * 100}%;
    }
  `}
  ${typeof props.xl !== 'undefined' &&
  css`
    @media ${getMediaQuery('xl')} {
      width: ${(props.xl / 12) * 100}%;
    }
  `}
  ${typeof props.xxl !== 'undefined' &&
  css`
    @media ${getMediaQuery('xxl')} {
      width: ${(props.xxl / 12) * 100}%;
    }
  `}
`;

export const Item = styled.div<TItemProps>`
  box-sizing: border-box;
  padding-left: var(--spacing);
  padding-top: var(--spacing);

  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;

  ${({ size }) =>
    typeof size !== 'undefined' &&
    css`
      width: ${(size / 12) * 100}%;
    `}

  ${getItemSizes}
`;
