import React, { FC } from 'react';
import { NumberFormat } from '@/general/number/format';
import { Container } from './styles';
import styles from './styles.module.scss';
import { IProps } from './types';

export const FinanceStats: FC<IProps> = ({ items, numberFormat }) => (
  <Container>
    <div className={styles.items}>
      {items.map((item) => (
        <div key={item.name} className={styles.item}>
          <div>
            <div className={styles.name}>{item.name}</div>
            <div className={styles.value}>
              <NumberFormat value={item.value} {...numberFormat} />
            </div>
          </div>
        </div>
      ))}
    </div>
  </Container>
);
