import React, { FC } from 'react';
import {
  Button,
  ButtonWrapper,
  Banner,
  Background,
  Container,
  Title,
  Content,
  Description,
} from './styles';
import { IProps } from './types';

export const BannerMedia: FC<IProps> = ({
  kind = 'default',
  header,
  description,
  href,
  media,
}) => (
  <Banner kind={kind}>
    {media && (
      <Background>
        {media.mp4 && (
          <video
            disablePictureInPicture
            playsInline
            preload="auto"
            autoPlay
            loop
            muted
          >
            <source src={media.mp4} type="video/mp4" />
          </video>
        )}
        {media.img && <img src={media.img} alt="banner" />}
      </Background>
    )}

    <Container kind={kind}>
      {header && <Title kind={kind}>{header}</Title>}
      <Content kind={kind}>
        <Description kind={kind}>{description}</Description>
        <ButtonWrapper>
          {href && (
            <Button
              tag="a"
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              variant="light"
              size="small"
            >
              Learn More
            </Button>
          )}
        </ButtonWrapper>
      </Content>
    </Container>
  </Banner>
);
