import React, { FC } from 'react';
import { IProps } from './types';
import { formatNumber } from '@/utils/number';

/**
 * Formatted and animated number
 */
export const NumberFormat: FC<IProps> = ({ value, ...options }) => (
  <>{formatNumber(value, options)}</>
);
