import React, { forwardRef, SVGAttributes } from 'react';

export const Logo = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>(
  (props, ref) => (
    <svg width="167" height="40" viewBox="0 0 167 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_104_86)">
    <path d="M22.6831 9.04297V32.0002H14.618V23.075C14.618 22.9733 14.5698 22.8778 14.4882 22.8183L11.4635 20.6106C11.3907 20.5574 11.2924 20.5574 11.2196 20.6106L8.19493 22.8183C8.11341 22.8778 8.06511 22.9733 8.06511 23.075V32.0002H0V9.04651H8.06511V17.569H14.618V9.04297H22.6831Z" fill="#00FFFF"/>
    <path d="M24.5073 24.0711C24.5073 34.6315 42.3816 34.6315 42.3816 24.0711C42.3816 13.4802 24.5073 13.4802 24.5073 24.0711ZM31.1611 24.0711C31.1611 20.5817 35.7279 20.5817 35.7279 24.0711C35.7279 27.53 31.1611 27.53 31.1611 24.0711Z" fill="white"/>
    <path d="M56.3345 31.2952H61.5063V16.2046H54.8526V22.7551C54.8526 27.775 50.316 27.1935 50.4974 23.9489V16.2046H43.8436C43.8436 19.0207 43.8436 20.8267 43.8436 23.6427C43.8134 31.8461 53.0984 33.9276 55.9413 28.5403H55.9111C56.0018 28.4484 56.1228 28.3872 56.2438 28.4178C56.3648 28.4484 56.4555 28.5709 56.4252 28.6933L56.3345 31.2952Z" fill="white"/>
    <path d="M71.5976 26.1218H63.7039V31.3254H73.8962C76.709 31.3254 79.31 29.3664 79.31 26.6116C79.31 22.9996 73.8962 22.7854 71.3557 22.4486C70.5089 22.3262 70.4787 21.4691 71.386 21.4691H79.31V16.2349H69.0874C66.2747 16.2349 63.7039 18.2245 63.7039 20.9793C63.7039 24.5913 69.0874 24.8056 71.628 25.1423C72.4748 25.2647 72.505 26.1218 71.5976 26.1218Z" fill="white"/>
    <path d="M88.8841 27.2853C87.9163 27.1629 87.8559 25.9997 88.8841 26.0303C101.677 27.469 102.101 15.8067 89.6402 16.1434C78.1172 16.4495 76.7864 32.091 91.2734 31.9992C93.7534 31.9992 96.2335 31.5095 97.9574 31.0809V26.2752C94.9935 27.4078 91.848 27.622 88.8841 27.2853ZM87.4929 22.2348C87.4627 20.3675 92.0295 19.9084 92.09 21.8368C92.1202 23.5203 87.5231 23.4591 87.4929 22.2348Z" fill="white"/>
    <path d="M107.005 16.9696C106.582 17.2145 104.888 18.347 104.586 18.5307C103.769 19.1429 102.922 18.0103 104.071 17.3063C104.223 17.2145 105.917 16.1125 106.068 16.0207C107.247 15.133 107.852 13.9086 107.852 12.7761V9.95996C105.462 9.95996 103.073 9.95996 100.684 9.95996C100.684 17.092 100.684 24.2241 100.684 31.3867H107.307V29.3359C107.307 29.2134 107.398 29.091 107.519 29.0604C107.64 29.0298 107.761 29.091 107.821 29.2134C110.635 34.3559 118.377 31.4786 118.377 23.6119C118.377 17.7042 112.086 14.0004 107.005 16.9696ZM111.693 23.7038C111.421 27.9584 107.307 27.0095 107.338 23.8567V23.214C107.338 20.3979 111.905 20.3979 111.693 23.7038Z" fill="white"/>
    <path d="M127.658 27.2853C126.691 27.1629 126.63 25.9997 127.658 26.0303C140.451 27.469 140.875 15.8067 128.414 16.1434C116.892 16.4495 115.56 32.091 130.047 31.9992C132.527 31.9992 135.007 31.5095 136.732 31.0809V26.2752C133.768 27.4078 130.622 27.622 127.658 27.2853ZM126.266 22.2348C126.236 20.3675 130.803 19.9084 130.864 21.8368C130.894 23.5203 126.297 23.4591 126.266 22.2348Z" fill="white"/>
    <path d="M145.779 16.2353V9.92969H139.125C139.125 15.4394 139.125 18.7453 139.125 22.4184V23.7041C139.125 24.1633 139.125 24.5917 139.185 25.0203C139.67 28.6016 142.12 31.3259 146.021 31.3259H149.439V26.1223H147.715C146.535 26.1223 146.081 25.6018 145.9 25.0203C145.779 24.6223 145.779 24.1938 145.779 23.8571V21.4695H149.439V16.2353H145.779Z" fill="white"/>
    <path d="M159.288 26.1218H151.395V31.3254H161.587C164.399 31.3254 167 29.3664 167 26.6116C167 22.9996 161.587 22.7854 159.046 22.4486C158.2 22.3262 158.169 21.4691 159.076 21.4691H167V16.2349H156.778C153.965 16.2349 151.395 18.2245 151.395 20.9793C151.395 24.5913 156.778 24.8056 159.318 25.1423C160.165 25.2647 160.195 26.1218 159.288 26.1218Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_104_86">
    <rect width="167" height="23" fill="white" transform="translate(0 9)"/>
    </clipPath>
    </defs>
    </svg>
  )
);
Logo.displayName = 'Logo';