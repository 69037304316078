import styled from 'styled-components';

export const Layout = styled.div`
  position: relative;
  width: max-content;
  min-width: 106px;

  box-sizing: border-box;
  padding: 8px;
  border: 1px solid rgba(var(--color-white--rgb), 0.12);
  border-radius: 8px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1),
    inset 0 0 1px #000;
  background-color: var(--color-grey-900);

  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--color-white);

  > * {
    + * {
      margin-top: 8px;
    }
  }
`;

export const Info = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  > * {
    + * {
      margin-left: 4px;
    }
  }
`;

export const Color = styled.span`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-black);
`;
