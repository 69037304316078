import { useMemo } from 'react';
import { TColors, TVariant } from '../types';

interface IProps {
  variant?: TVariant;
  variantColors?: TColors;
  hasStaticFill?: boolean;
}

export function useVariantColors({
  variant,
  variantColors,
  hasStaticFill,
}: IProps): TColors {
  const colors = useMemo(() => {
    if (variantColors) {
      return variantColors;
    }

    if (variant === 'primary') {
      return {
        staticColor1: '#00FFF4',
        staticColor2: '#00FFF4',
        hoverColor1: hasStaticFill ? '#14161A' : '#00FFF4',
        hoverColor2: hasStaticFill ? '#14161A' : '#00FFF4',
        contentColor: hasStaticFill ? '#000' : '#fff',
        contentColorHover: hasStaticFill ? '#fff' : '#000',
      };
    }

    if (variant === 'primary_unactive') {
      return {
        staticColor1: '#114444',
        staticColor2: '#114444',
        hoverColor1: hasStaticFill ? '#14161A' : '#114444',
        hoverColor2: hasStaticFill ? '#14161A' : '#114444',
        contentColor: '#fff',
        contentColorHover: '#fff',
      };
    }

    return {
      staticColor1: '#0280EF',
      staticColor2: '#2DE4B6',
      hoverColor1: hasStaticFill ? '#14161A' : '#0280EF',
      hoverColor2: hasStaticFill ? '#14161A' : '#2DE4B6',
      contentColor: hasStaticFill ? '#fff' : '#fff',
      contentColorHover: hasStaticFill ? '#fff' : '#fff',
    };
  }, [variantColors, variant, hasStaticFill]);

  return colors;
}
