import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { ButtonSimple } from '@/general/button/simple';
import { Button as SubmitButton } from '../styles';

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${getMediaQuery('xlMin')} {
    justify-content: space-between;
    height: 54px;
    padding-right: 36px;

    > * {
      width: 100%;

      + * {
        @media ${getMediaQuery('xxl')} {
          margin-left: 40px;
        }
        @media ${getMediaQuery('xl')} {
          margin-left: 20px;
        }
      }
    }
  }

  @media ${getMediaQuery('lgMax')} {
    @media ${getMediaQuery('smMin')} {
      justify-content: center;

      > * + * {
        margin-left: 20px;
      }
    }

    @media ${getMediaQuery('xs')} {
      justify-content: space-between;

      > * {
        width: 100%;

        + * {
          margin-left: 10px;
        }
      }
    }

    > * {
      @media ${getMediaQuery('smMin')} {
        min-width: 135px;
      }
    }
  }
`;

export const Button = styled(ButtonSimple).attrs({
  size: 'medium',
})`
  font-size: 12px;
`;

export const Value = styled.div`
  @media ${getMediaQuery('xlMin')} {
    margin-top: 30px;

    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: var(--color-grey-450);

    b {
      font-weight: 600;
      font-size: 36px;
      color: var(--color-white);
    }

    i {
      font-style: normal;
      font-size: 20px;
      color: var(--color-blue);
    }
  }

  @media ${getMediaQuery('lgMax')} {
    @media ${getMediaQuery('smMin')} {
      margin-top: 60px;
      font-size: 24px;
    }
    @media ${getMediaQuery('xs')} {
      margin-top: 40px;
      font-size: 16px;
    }

    text-align: center;
    font-weight: 500;
    line-height: 24px;
    color: var(--color-grey-450);

    b {
      font-weight: 600;
      font-size: 44px;
      color: var(--color-white);
    }

    i {
      font-style: normal;
      font-size: 24px;
      font-weight: 600;
      color: var(--color-blue);
    }
  }
`;

export const Submit = styled(SubmitButton)`
  @media ${getMediaQuery('xlMin')} {
    margin-top: 25px;
  }
  @media ${getMediaQuery('lgMax')} {
    margin-top: 36px;
  }
`;
