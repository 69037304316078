import styled from 'styled-components';
import { ButtonBase } from '../__base/Base';

export const Button = styled(ButtonBase)`
  position: relative;
  padding: 4px 32px;
  color: var(--color-grey-100);
`;

export const Content = styled.span`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
