import styled, { css } from 'styled-components';
import { getTransition } from '@/utils/styles/getTransition';
import { ISlideProps } from './types';

export const Layout = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
  transform: translateZ(0);
  user-select: none;
`;

export const Slide = styled.div<ISlideProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${getTransition('opacity 0.25s, visibility 0.25s')};
  opacity: 0;
  visibility: hidden;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
      visibility: visible;
      z-index: 1;
    `}
`;
