import styled, { css } from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { ISharedProps } from './types';
import { ButtonSimple } from '@/general/button/simple';

export const Banner = styled.section<ISharedProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  box-sizing: border-box;
  overflow: hidden;

  border: 1px solid var(--color-grey-750);
  border-radius: 8px;
  background-color: var(--color-grey-1000);

  color: var(--color-white);

  ${({ kind }) =>
    kind === 'large' &&
    css`
      min-height: 284px;

      @media ${getMediaQuery('xxl')} {
        min-height: 330px;
      }
    `}
`;

export const Background = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img,
  video {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Container = styled.div<ISharedProps>`
  position: relative;
  width: 100%;
  z-index: 1;

  ${({ kind }) =>
    kind === 'default' &&
    css`
      @media ${getMediaQuery('xlMin')} {
        padding: 100px 55px 26px;
      }
      @media ${getMediaQuery('lg')} {
        padding: 100px 40px 23px;
      }
      @media ${getMediaQuery('lg')} {
        padding: 100px 40px 23px;
      }
      @media ${getMediaQuery('md')} {
        padding: 100px 28px 22px 34px;
      }
      @media ${getMediaQuery('md')} {
        padding: 90px 28px 22px 34px;
      }
      @media ${getMediaQuery('sm')} {
        padding: 72px 17px 16px 17px;
      }
      @media ${getMediaQuery('xs')} {
        padding: 146px 17px 15px 17px;
      }
    `}

  ${({ kind }) =>
    kind === 'large' &&
    css`
      @media ${getMediaQuery('mdMin')} {
        padding: 20px;
      }
      @media ${getMediaQuery('smMax')} {
        padding: 16px;
      }
    `}

  @media ${getMediaQuery('smMax')} {
    padding: 16px;
    padding-top: 130px;
  }
`;

export const Title = styled.h2<ISharedProps>`
  margin: 0;
  font-weight: 600;

  ${({ kind }) =>
    kind === 'default' &&
    css`
      @media ${getMediaQuery('mdMin')} {
        font-size: 28px;
        line-height: 34px;
      }
      @media ${getMediaQuery('sm')} {
        font-size: 22px;
        line-height: 26px;
      }
      @media ${getMediaQuery('xs')} {
        font-size: 20px;
        line-height: 24px;
      }
    `}

  ${({ kind }) =>
    kind === 'large' &&
    css`
      @media ${getMediaQuery('smMin')} {
        font-size: 32px;
        line-height: 38px;
      }
      @media ${getMediaQuery('xs')} {
        font-size: 20px;
        line-height: 24px;
      }
    `}
`;

export const ButtonWrapper = styled.div`
  flex-shrink: 0;
`;

export const Content = styled.div<ISharedProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${ButtonWrapper} {
    margin-left: 15px;
  }

  ${({ kind }) =>
    kind === 'default' &&
    css`
      @media ${getMediaQuery('xs')} {
        margin-top: 11px;
      }
    `}

  ${({ kind }) =>
    kind === 'large' &&
    css`
      @media ${getMediaQuery('smMin')} {
        margin-top: 16px;
      }

      @media ${getMediaQuery('xs')} {
        margin-top: 20px;
        flex-direction: column;
        align-items: flex-start;

        ${ButtonWrapper} {
          margin-left: 0;
          margin-top: 14px;
        }
      }
    `}
`;

export const Button = styled(ButtonSimple)`
  font-weight: 500;

  @media ${getMediaQuery('xlMin')} {
    --button-size: 40px;
    padding: 0 56px;
    font-size: 16px;
  }
  @media ${getMediaQuery('lg')} {
    --button-size: 40px;
    padding: 0 24px;
    font-size: 16px;
  }
  @media ${getMediaQuery('md')} {
    --button-size: 34px;
    --button-border-radius: 7px;
    padding: 0 32px;
    font-size: 14px;
  }
  @media ${getMediaQuery('sm')} {
    --button-size: 28px;
    --button-border-radius: 4px;
    padding: 0 28px;
    font-size: 12px;
  }
  @media ${getMediaQuery('xs')} {
    --button-size: 28px;
    --button-border-radius: 4px;
    padding: 0 16px;
    font-weight: 600;
    font-size: 12px;
  }
`;

export const Description = styled.div<ISharedProps>`
  color: var(--color-grey-300);

  @media ${getMediaQuery('smMin')} {
    max-width: 50em;
  }

  ${({ kind }) =>
    kind === 'default' &&
    css`
      @media ${getMediaQuery('lgMin')} {
        font-size: 16px;
        line-height: 22px;
      }
      @media ${getMediaQuery('md')} {
        font-size: 14px;
        line-height: 19px;
      }
      @media ${getMediaQuery('sm')} {
        font-size: 11px;
        line-height: 15px;
      }
      @media ${getMediaQuery('xs')} {
        font-size: 10px;
        line-height: 14px;
      }
    `}

  ${({ kind }) =>
    kind === 'large' &&
    css`
      max-width: 40em;

      @media ${getMediaQuery('mdMin')} {
        font-size: 14px;
        line-height: 22px;
      }
      @media ${getMediaQuery('sm')} {
        font-size: 11px;
        line-height: 16px;
      }
      @media ${getMediaQuery('xs')} {
        font-size: 8px;
        line-height: 14px;
      }
    `}

  b {
    color: var(--color-blue);
  }
`;
