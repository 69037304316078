import React, { FC, useEffect, useState } from 'react';
import { BoxOutline } from '@/general/box/outline';
import { RechartsLinear } from '@/internal/charts/linear';
import { NumberFormat } from '@/general/number/format';
import { TabButtons } from '@/general/tab/buttons';
import { IPeriod, IProps } from './types';
import { ChartWrapper, Container, Content, Title, Value } from './styles';

export const FinancePeriodsChart: FC<IProps> = ({
  title,
  periods,
  chartProps,
  numberFormat,
}) => {
  const [tabKey, setTabKey] = useState(periods[0].key);
  const [period, setPeriod] = useState<IPeriod | null>(null);
  const value = period?.value;

  useEffect(() => {
    if (tabKey) {
      setPeriod(periods.find(({ key }) => key === tabKey) || null);
    }
  }, [periods, tabKey]);

  return (
    <BoxOutline aria-label={title}>
      <Container>
        <Content>
          {typeof value === 'number' && (
            <Value>
              <NumberFormat value={value} {...numberFormat} />
            </Value>
          )}
          {periods && periods.length > 1 && (
            <TabButtons
              styles="group"
              items={periods}
              selectedKey={tabKey}
              onSelect={setTabKey}
            />
          )}
          {title && <Title>{title}</Title>}
        </Content>

        <ChartWrapper>
          {period && (
            <RechartsLinear
              data={period?.data}
              grid={{ x: false, y: false }}
              axis={{ x: false, y: false }}
              {...chartProps}
              numberFormat={numberFormat}
            />
          )}
        </ChartWrapper>
      </Container>
    </BoxOutline>
  );
};
