import styled, { css } from 'styled-components';
import { getTransition } from '@/utils/styles/getTransition';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { ILayoutProps } from './types';

export const Layout = styled.div<ILayoutProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${getTransition('opacity 0.5s')}
  ${({ isUnactive }) =>
    isUnactive &&
    css`
      opacity: 0.25;
    `}

  > * + * {
    margin-left: 40px;
  }
`;

const textStyles = css`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--color-white);

  @media ${getMediaQuery('sm')} {
    font-size: 10px;
    line-height: 18px;
  }

  @media ${getMediaQuery('xs')} {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  ${textStyles}

  > * + * {
    margin-left: 8px;
  }
`;

export const Color = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #000;
`;

export const Value = styled.div`
  ${textStyles}
  text-align: right;
`;
