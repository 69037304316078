import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const Row = styled.div`
  display: table-row;
`;

export const Column = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding: 0 16px 0 0;

  text-overflow: ellipsis;
  box-sizing: border-box;
`;

export const ColumnPool = styled(Column)`
  @media ${getMediaQuery('lgMin')} {
    padding-left: 18px;
  }
  @media ${getMediaQuery('md')} {
    padding-left: 12px;
  }
  @media ${getMediaQuery('smMax')} {
    padding-left: 10px;
  }
`;

export const ColumnMyLiquidity = styled(Column)`
  @media ${getMediaQuery('xs')} {
    @media (max-width: 370px) {
      display: none;
    }
  }
`;

export const ColumnLiquidity = styled(Column)`
  @media ${getMediaQuery('smMax')} {
    display: none;
  }
`;

export const ColumnApy = styled(Column)``;

export const ColumnDeposit = styled(Column)`
  width: 0;

  @media ${getMediaQuery('xs')} {
    display: none;
  }
`;

export const ColumnNav = styled(Column)`
  width: 50px;
  padding-right: 0;

  @media ${getMediaQuery('smMin')} {
    display: none;
  }
`;
