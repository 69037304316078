import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useHover } from '@/utils/useHover';
import { TProps } from './types';
import { Bg, BgHover, Button, Content } from './styles';

export const ButtonSimple = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  TProps
>(
  (
    { variant = 'primary', hasBorder, isForceHover, children, ...tagProps },
    ref
  ) => {
    const domRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);
    useImperativeHandle(ref, () => domRef.current!);

    const isHovered = useHover(domRef);

    return (
      <Button
        ref={domRef as any}
        variant={variant}
        {...tagProps}
        isHovered={isHovered}
      >
        <>
          <Bg
            isDisabled={tagProps.disabled}
            isHovered={isHovered || !!isForceHover}
          >
            <BgHover
              hasBorder={hasBorder}
              isHovered={isHovered || !!isForceHover}
            />
          </Bg>
          <Content>{children}</Content>
        </>
      </Button>
    );
  }
);
ButtonSimple.displayName = 'ButtonSimple';
