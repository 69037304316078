import React, { FC, useEffect } from 'react';
import { ButtonSimple } from '@/general/button/simple';
import { useMediaSize } from '@/utils/media';
import { NumberFormat } from '@/general/number/format';
import { LottieBackground } from './LottieBackground';
import { ITokenRewardProps } from './types';
import {
  BackgroundImage,
  Head,
  Icon,
  Name,
  Wrapper,
  Token,
  Rewards,
  Title,
  Value,
  Metric,
  Buttons,
  Box,
  MainImage,
  Submit,
  StatusButtonWrapper,
  StatusContainer,
  StatusConfirm,
  StatusPending,
  StatusButton,
} from './styles';

export const TokenReward: FC<ITokenRewardProps> = ({
  value,
  numberFormat,
  status,
  buttons,
  onSuccess,
}) => {
  const statusCodes = {
    confirm: 1,
    pending: 2,
    success: 3,
  };
  const statusCode = status ? statusCodes[status] || 0 : 0;
  const size = useMediaSize();

  useEffect(() => {
    if (status === 'success') {
      onSuccess?.();
    }
  }, [status, onSuccess]);

  return (
    <Box>
      <BackgroundImage />
      <LottieBackground pos="left" isActive={status === 'success'} />
      <LottieBackground pos="right" isActive={status === 'success'} />
      <Wrapper>
        <MainImage />

        <Head>
          <Token>
            <Icon />
            <Name>LFI</Name>
          </Token>
          <Rewards>
            <Title>Total Rewards:</Title>
            <Value>
              {numberFormat?.prefix && <Metric>{numberFormat.prefix}</Metric>}
              <NumberFormat
                {...numberFormat}
                value={value}
                prefix={undefined}
                suffix={undefined}
              />
              {numberFormat?.suffix && <Metric>{numberFormat.suffix}</Metric>}
            </Value>
          </Rewards>
        </Head>

        {buttons && (
          <Buttons isHide={status === 'success'}>
            <Submit isHide={!!status}>
              <ButtonSimple
                tag="button"
                variant="gold"
                hasBorder
                size={size === 'xs' ? 'small' : 'large'}
                fullWidth
                isForceHover={!!status}
                onClick={() => {
                  if (!status) {
                    buttons.onClick?.();
                  }
                }}
              >
                {buttons.submit || 'Claim'}
              </ButtonSimple>
            </Submit>

            <StatusButtonWrapper isShow={!!status}>
              <StatusButton
                tag="button"
                size={size === 'xs' ? 'small' : 'large'}
                disabled
              >
                <StatusContainer>
                  <StatusConfirm isActive={statusCode <= 1}>
                    {buttons.confirm || 'Confirm in MetaMask'}
                  </StatusConfirm>
                  <StatusPending isActive={statusCode >= 2}>
                    {buttons.pending || 'Claiming...'}
                  </StatusPending>
                </StatusContainer>
              </StatusButton>
            </StatusButtonWrapper>
          </Buttons>
        )}
      </Wrapper>
    </Box>
  );
};
