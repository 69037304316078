import styled from 'styled-components';
import { resetUl } from '@/utils/styles/resetUl';

export const List = styled.ul`
  ${resetUl}

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: -4px;

  > * {
    + * {
      margin-left: 8px;
    }
  }
`;

export const Link = styled.a`
  display: inline-block;
  padding: 4px;

  color: var(--color-grey-600);
  transition: color 0.35s;

  &:hover {
    color: var(--color-blue);
  }

  svg {
    width: auto;
    height: 12px;

    path {
      fill: currentColor;
    }
  }
`;
