import styled, { css } from 'styled-components';
import { ButtonBase } from '../__base/Base';
import { TProps, TStylesProps, TSharedProps } from './types';

const settings = css`
  --button-border-radius: 8px;
`;

const getVariantStyles = ({ variant }: TProps) => css`
  ${variant === 'primary' &&
  css`
    --button-color-text: #00fff4;
    --button-color-text-hover: #000;
    --button-color-outline: #00fff4;
  `}

  ${variant === 'secondary' &&
  css`
    --button-color-text: #144;
    --button-color-text-hover: #fff;
    --button-color-outline: #144;
  `}

  ${variant === 'success' &&
  css`
    --button-color-text: #0e6330;
    --button-color-text-hover: #fff;
    --button-color-outline: #0e6330;
  `}

  ${variant === 'danger' &&
  css`
    --button-color-text: #5b1d1d;
    --button-color-text-hover: #fff;
    --button-color-outline: #5b1d1d;
  `}

  ${variant === 'light' &&
  css`
    --button-color-text: #fff;
    --button-color-text-hover: #000;
    --button-color-outline: #fff;
  `}

  ${variant === 'dark' &&
  css`
    --button-color-text: #ced4da;
    --button-color-text-hover: #ced4da;
    --button-color-outline: #404040;
  `}
`;

export const Button = styled(ButtonBase)<TStylesProps>`
  ${settings}

  position: relative;
  border-radius: var(--button-border-radius);
  overflow: hidden;
  padding: 4px 32px;

  transition: color 0.5s, opacity 0.25s;
  color: var(--button-color-text);
  text-align: center;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `}

  ${({ isHovered }) =>
    isHovered &&
    css`
      color: var(--button-color-text-hover);
    `}

  ${getVariantStyles}
`;

export const Bg = styled.span<TSharedProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: var(--button-border-radius);
  border: 1px solid var(--button-color-outline);

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: -5px;
    border-radius: calc(var(--button-size) / 2 + 5px);
    width: calc(50% + var(--button-size) / 2 + 10px);
    height: calc(100% + 10px);
    transition: transform 0.5s;
    background: var(--button-color-outline);
  }

  &::before {
    left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transform: translate(-101%, 0);
  }

  &::after {
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transform: translate(101%, 0);
  }

  ${({ isHovered }) =>
    isHovered &&
    css`
      &::before {
        transform: translate(-10px, 0);
      }
      &::after {
        transform: translate(10px, 0);
      }
    `}
`;

export const Content = styled.span`
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
