import styled, { css } from 'styled-components';
import { LazyImage } from '@/internal/Image/Lazy';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { ILabelProps } from './types';

export const Label = styled.label<ILabelProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;

  transition: background-color 0.25s;

  &:hover {
    background: rgba(var(--color-white--rgb), 0.11);
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: rgba(var(--color-white--rgb), 0.11);
    `}
`;

export const Input = styled.input`
  display: none;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(var(--color-white--rgb), 0.1);
`;

export const Image = styled(LazyImage).attrs({ pos: 'contain' })`
  border-radius: 50%;
`;

export const Name = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  text-align: center;

  @media ${getMediaQuery('smMin')} {
    font-size: 14px;
    line-height: 22px;
  }

  @media ${getMediaQuery('xs')} {
    font-size: 12px;
    line-height: 20px;
  }
`;
