import React, { FC } from 'react';
import { IFarmItemRewardsProps } from './types';
import { Button, ButtonsWrapper, Submit, Value } from './styles';

export const FarmItemRewards: FC<IFarmItemRewardsProps> = ({
  value,
  onAddLiquidity,
  onRemoveLiquidity,
  onClaimRewards,
}) => (
  <div aria-label="Rewards">
    <ButtonsWrapper>
      <Button
        tag="button"
        variant="success"
        onClick={() => {
          onAddLiquidity();
        }}
      >
        Add Liquidity
      </Button>
      <Button
        tag="button"
        variant="danger"
        onClick={() => {
          onRemoveLiquidity();
        }}
      >
        Remove Liquidity
      </Button>
    </ButtonsWrapper>

    <Value>
      Rewards: <b>{value}</b> <i>LFI</i>
    </Value>

    <Submit
      tag="button"
      variant="secondary"
      disabled={!value}
      onClick={() => {
        onClaimRewards();
      }}
    >
      Claim Rewards
    </Submit>
  </div>
);
