import styled, { css } from 'styled-components';
import { getTransition } from '@/utils/styles/getTransition';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { getShowStyles } from '../generalStyles';
import { TSharedProps } from '../types';

export const Container = styled.div<TSharedProps>`
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  width: var(--aside-width);
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--top-height);
  padding-bottom: calc(7 * var(--vh));
  box-sizing: border-box;

  @media ${getMediaQuery('xs')} {
    display: none;
  }

  > * {
    width: 100%;
  }

  ${({ appearAnimation }) =>
    appearAnimation.use
      ? css`
          ${getTransition('background-color 0.5s', '0.25s')}
          background-color: ${appearAnimation.on && 'var(--color-grey-1200)'};
        `
      : css`
          background-color: var(--color-grey-1200);
        `}

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: var(--color-grey-800);

    ${({ appearAnimation }) =>
      appearAnimation.use &&
      css`
        transform: scale(1, ${appearAnimation.on ? 1 : 0});
        transform-origin: top left;
        ${getTransition('transform 1s')}
      `}
  }
`;

export const Menu = styled.div`
  padding-right: 24px;
  box-sizing: border-box;

  @media ${getMediaQuery('mdMax')} {
    padding-right: 8px;
  }
`;

export const Content = styled.div<TSharedProps>`
  ${(props) => getShowStyles({ ...props, delay: '1s' })}

  margin-top: 24px;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;

  @media ${getMediaQuery('mdMax')} {
    display: none;
  }

  > * + * {
    margin-top: 16px;

    @media ${getMediaQuery('xs')} {
      margin-top: 120px;
    }
  }
`;
