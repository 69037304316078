import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { ColumnPool, Row } from './global';

export const Container = styled.div`
  display: table;
  width: 100%;
  box-sizing: border-box;

  @media ${getMediaQuery('smMin')} {
    border-spacing: 0 10px;
    background: var(--color-black);
    border: 1px solid var(--color-grey-800);
    border-radius: 8px;
    padding: 0 16px;
  }

  @media ${getMediaQuery('xs')} {
    border-spacing: 0 3px;
    margin-top: -4px;
  }
`;

export const Head = styled(Row)`
  font-weight: 500;
  color: rgba(var(--color-white--rgb), 0.48);

  @media ${getMediaQuery('smMin')} {
    height: 42px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
  }

  @media ${getMediaQuery('xs')} {
    height: 40px;
    font-size: 10px;
    line-height: 18px;
  }

  ${ColumnPool} {
    @media ${getMediaQuery('lgMin')} {
      padding-left: 74px;
    }
    @media ${getMediaQuery('md')} {
      padding-left: 54px;
    }
    @media ${getMediaQuery('sm')} {
      padding-left: 50px;
    }
    @media ${getMediaQuery('xs')} {
      padding-left: 48px;
    }
  }
`;

export const ColumnPoolText = styled.div`
  position: relative;

  @media ${getMediaQuery('xs')} {
    left: -39px;
  }
`;
