import React, { FC, useContext, useEffect, useId, useState } from 'react';
import { Modal } from '@/internal/modal';
import { Context } from '@/store/context';
import { IProps } from './types';
import {
  Checkbox,
  Step,
  StepContent,
  StepNum,
  Steps,
  Title,
  Wallets,
} from './styles';
import { WalletItem } from './item';

export const ConnectWallet: FC<IProps> = ({ isForceOpen }) => {
  const ctx = useContext(Context);
  const { wallets, onSelect, selectedKey } = ctx.connectWallet;
  const { links } = ctx;
  const { shown, setShown } = ctx.system.connectWallet;
  const id = useId();

  const [acceptPolicy, setAcceptPolicy] = useState(false);

  // hide modal when wallet is selected
  useEffect(() => {
    if (typeof selectedKey !== 'undefined' && selectedKey !== null) {
      setShown(false);
    }
  }, [selectedKey, setShown]);

  return (
    <Modal isOpen={!!(shown || isForceOpen)} onClose={() => setShown(false)}>
      <Title>Connect Wallet</Title>
      <Steps>
        <Step>
          <StepNum>1</StepNum>
          <StepContent>
            Accept the{' '}
            <a href={links.terms} target="_blank" rel="noopener noreferrer">
              Terms & Conditions
            </a>{' '}
            and{' '}
            <a href={links.privacy} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            <br />
            <Checkbox
              label="I read and accept"
              id={`${id}-accept`}
              isReversed
              onChange={(e) => {
                setAcceptPolicy(e.target.checked);
              }}
              checked={acceptPolicy}
            />
          </StepContent>
        </Step>

        <Step isDisabled={!acceptPolicy}>
          <StepNum>2</StepNum>
          <StepContent>
            Choose Wallet
            <Wallets>
              {wallets?.map((wallet) => (
                <WalletItem
                  key={wallet.key}
                  {...wallet}
                  keyVal={wallet.key}
                  isSelected={wallet.key === selectedKey}
                  onSelect={() => onSelect(wallet)}
                />
              ))}
            </Wallets>
          </StepContent>
        </Step>
      </Steps>
    </Modal>
  );
};
