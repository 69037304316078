import styled, { css } from 'styled-components';
import { TValueProps } from './types';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 48px;

  &:nth-child(odd) {
    background-color: var(--color-grey-825);
  }

  &:last-child {
    background: linear-gradient(
      225deg,
      rgba(45, 228, 182, 0.3) 0%,
      rgba(2, 128, 239, 0.3) 100%
    );
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: var(--color-grey-500);

  > * {
    + * {
      margin-left: 8px;
    }
  }
`;

export const Value = styled.div<TValueProps>`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: var(--color-white);

  ${({ color }) =>
    color === 'red' &&
    css`
      color: var(--color-danger);
    `}

  ${({ color }) =>
    color === 'green' &&
    css`
      color: var(--color-success);
    `}
`;
