import styled, { keyframes } from 'styled-components';
import { ButtonSimple } from '@/general/button/simple';
import { BubbleText } from './bubble-text';
import { LetterText } from './letter-text';
import { AnimatedContent } from './animated-content';

export const Container = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
`;

export const Content = styled.div`
  margin-top: 32px;
`;

const titleShiningKeyframes = keyframes`
  to {
    background-position: -300% center;
  }
`;

export const Title = styled(BubbleText)`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.41px;
  color: var(--color-blue);

  span {
    background: linear-gradient(
      150deg,
      #00fff4 20%,
      #00d5ff 40%,
      #00d5ff 60%,
      #00fff4 80%
    );
    background-size: 300% auto;
    background-clip: text;
    color: var(--color-blue);
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: ${titleShiningKeyframes} 2s linear infinite;
  }
`;

export const Description = styled(LetterText)`
  margin-top: 3px;
  max-width: 14.3125em;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.38px;
`;

const amountShiningKeyframes = keyframes`
  0% {
    text-shadow: 0 0 0 #fff;
  }
  50% {
    text-shadow: 0 0 8px #fff;
  }
  100% {
    text-shadow: 0 0 0 #fff;
  }
`;

export const Amount = styled(BubbleText)`
  margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.39px;

  animation-name: ${amountShiningKeyframes};
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 0.6s;
`;

export const TrailingContent = styled(AnimatedContent)`
  width: 100%;
  margin-top: 106px;
`;

export const AdditionalText = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: var(--color-grey-400);

  + * {
    margin-top: 20px;
  }
`;

export const ButtonDone = styled(ButtonSimple).attrs({
  fullWidth: true,
})`
  --button-size: 60px;
  font-weight: 600;
  font-size: 18px;
`;
