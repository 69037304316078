import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { getFontFamily } from '@/utils/styles/getFontFamily';

export const Head = styled.div`
  @media ${getMediaQuery('smMin')} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media ${getMediaQuery('xs')} {
    > * {
      + * {
        margin-top: 18px;
      }
    }
  }

  @media ${getMediaQuery('lgMin')} {
    margin: 0 0 24px;
  }
  @media ${getMediaQuery('mdMax')} {
    margin: 0 0 18px;
  }
`;

export const Heading = styled.h2`
  margin: 0;

  font-family: ${getFontFamily('default')};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  @media ${getMediaQuery('smMin')} {
    font-size: 18px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 16px;
  }
`;

export const Nav = styled.div`
  flex-shrink: 0;
`;
