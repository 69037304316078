import { RefObject, useEffect, useState } from 'react';
import { addEventListener, IAddEventListener } from 'vevet-dom';

interface IProps {
  ref: RefObject<HTMLElement>;
  disabled?: boolean;
}

export function useDOMRect({ ref, disabled }: IProps): DOMRect | undefined {
  const [rect, setRect] = useState<DOMRect>();

  useEffect(() => {
    const element = ref.current;
    if (disabled) {
      return undefined;
    }

    const updatePosition = (): void => {
      const el = ref.current;
      if (!el) {
        return;
      }
      setRect(el.getBoundingClientRect());
    };
    updatePosition();

    const listeners: IAddEventListener[] = [];
    let resizeObserver: ResizeObserver | null;

    listeners.push(addEventListener(window, 'scroll', updatePosition));
    listeners.push(addEventListener(window, 'resize', updatePosition));
    if (element) {
      resizeObserver = new ResizeObserver(updatePosition);
      resizeObserver.observe(element);
    }

    return () => {
      listeners.forEach(({ remove }) => remove());
      resizeObserver?.disconnect();
    };
  }, [ref, disabled]);

  return rect;
}
