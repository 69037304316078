import React, { FC } from 'react';
import { formatNumber } from '@/utils/number';
import { Like } from './like';
import {
  AdditionalText,
  Amount,
  ButtonDone,
  Container,
  Content,
  Description,
  Title,
  TrailingContent,
} from './styles';
import { IProps } from './types';

export const Success: FC<IProps> = ({
  isShown,
  title,
  description,
  amount,
  additionalText,
  onDoneClick,
}) => {
  const amountValue = amount && formatNumber(amount.value, amount.numberFormat);

  return (
    <Container>
      <Like isShown={isShown} />

      <Content>
        {title && <Title text={title} isShown={isShown} />}
        {description && <Description text={description} isShown={isShown} />}
        {amountValue && <Amount text={amountValue} isShown={isShown} />}
      </Content>

      <TrailingContent isShown={isShown}>
        {additionalText && <AdditionalText>{additionalText}</AdditionalText>}
        {onDoneClick && (
          <ButtonDone tag="button" type="button" onClick={onDoneClick}>
            Done
          </ButtonDone>
        )}
      </TrailingContent>
    </Container>
  );
};
