import React, { FC } from 'react';
import { RechartsBar } from '@/internal/charts/bar';
import { Container } from './styles';
import styles from './styles.module.scss';
import { IFinanceStatsChartProps } from './types';

export const FinanceStatsChart: FC<IFinanceStatsChartProps> = ({
  title,
  data,
  numberFormat,
}) => (
  <Container aria-label={title}>
    <div className={styles.container}>
      {title && <div className={styles.title}>{title}</div>}

      <div className={styles.chart}>
        <RechartsBar
          data={data}
          tooltip={{
            text: '',
            fill: '#8730CB',
          }}
          grid={false}
          axis={{
            x: { hasLine: false },
            y: { hasLine: false },
          }}
          numberFormat={numberFormat}
        />
      </div>
    </div>
  </Container>
);
