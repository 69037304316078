import styled from 'styled-components';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  @media ${getMediaQuery('smMin')} {
    width: max-content;
  }

  @media ${getMediaQuery('xs')} {
    flex-wrap: wrap;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  @media ${getMediaQuery('xs')} {
    width: 100%;

    @media (max-width: 374px) {
      flex-wrap: wrap;
    }
  }
`;

export const CoinWrapper = styled.div`
  @media ${getMediaQuery('xs')} {
    width: 100%;

    > *,
    > * > button {
      width: 100%;
    }
  }
`;

export const NetworkWrapper = styled.div`
  @media ${getMediaQuery('xs')} {
    flex-shrink: 0;

    @media (max-width: 374px) {
      width: 100%;

      > *,
      > * button {
        width: 100%;
      }
    }
  }
`;

export const UserWrapper = styled.div`
  @media ${getMediaQuery('xs')} {
    width: 100%;

    > * {
      width: 100%;
    }
  }
`;
