import styled from 'styled-components';
import { LazyImage } from '@/internal/Image/Lazy';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { resetUl } from '@/utils/styles/resetUl';
import { ButtonOutline } from '@/general/button/outline';

export const List = styled.ul`
  ${resetUl}
  box-sizing: border-box;
  background-color: var(--color-grey-900);
  border-radius: 8px;
  border: 1px solid rgba(var(--color-blue--rgb), 0.4);

  padding-top: 15px;
  padding-bottom: 15px;

  @media ${getMediaQuery('smMin')} {
    padding-left: 22px;
    padding-right: 22px;
  }

  @media ${getMediaQuery('xs')} {
    padding-left: 18px;
    padding-right: 18px;
  }
`;

export const Li = styled.li`
  + li {
    margin-top: 10px;
  }
`;

export const Button = styled(ButtonOutline).attrs({
  fullWidth: true,
})`
  --button-color-text: var(--color-white);
`;

export const ButtonIcon = styled(LazyImage).attrs({ pos: false })`
  display: block;
  width: 28px;
  height: 28px;
  margin-right: 6px;
`;
