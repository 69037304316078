import styled, { css } from 'styled-components';
import { LazyImage } from '@/internal/Image/Lazy';
import { PreviewNetworkLabel } from '@/general/preview/network-label';
import { ISharedProps } from './types';

export const Wrapper = styled.div`
  color: var(--color-white);
  text-decoration: none;
`;

export const Container = styled.div<ISharedProps>`
  box-sizing: border-box;

  ${({ size }) =>
    size === 'large' &&
    css`
      padding: 20px 24px;
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      padding: 20px;
    `}
  
  ${({ size }) =>
    size === 'small' &&
    css`
      padding: 16px;
    `}
`;

export const Head = styled.div<ISharedProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${({ hasValue }) =>
    hasValue &&
    css`
      align-items: flex-start;
    `}
`;

export const Children = styled.div`
  margin-top: 18px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;

export const Image = styled(LazyImage).attrs({ pos: false })<ISharedProps>`
  display: block;
  flex-shrink: 0;

  ${({ size }) =>
    size === 'large' &&
    css`
      width: 50px;
      height: 50px;
      margin-right: 12px;
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      width: 44px;
      height: 44px;
      margin-right: 10px;
    `}

  ${({ size, hasValue }) =>
    size === 'small' &&
    css`
      width: 30px;
      height: 30px;
      margin-right: 10px;

      ${hasValue &&
      css`
        width: 36px;
        height: 36px;
      `}
    `}

  ${({ isAppearAnimation }) =>
    typeof isAppearAnimation === 'boolean' &&
    css`
      transform: scale(0, 0);

      ${isAppearAnimation &&
      css`
        transition: transform 1s;
        transform: scale(1, 1);
      `}
    `}
`;

export const Content = styled.div`
  overflow: hidden;
`;

export const Name = styled.div<ISharedProps>`
  font-weight: 600;
  line-height: 100%;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ size }) =>
    (size === 'large' || size === 'medium') &&
    css`
      font-size: 20px;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 18px;
    `}
`;

export const Value = styled.div<ISharedProps>`
  margin-top: 6px;
  font-weight: 500;
  line-height: 110%;
  text-transform: capitalize;
  color: var(--color-grey-600);

  ${({ size }) =>
    (size === 'large' || size === 'medium') &&
    css`
      font-size: 16px;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 14px;
    `}
`;

export const Network = styled(PreviewNetworkLabel)`
  margin-left: 8px;
`;
