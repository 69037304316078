import home from './icons/home.svg';
import homeActive from './icons/home_active.svg';

import pools from './icons/pools.svg';
import poolsActive from './icons/pools_active.svg';

import farms from './icons/farms.svg';
import farmsActive from './icons/farms_active.svg';

import staking from './icons/staking.svg';
import stakingActive from './icons/staking_active.svg';

import ido from './icons/ido.svg';
import idoActive from './icons/ido_active.svg';

export const icons = {
  home,
  home_active: homeActive,
  pools,
  pools_active: poolsActive,
  farms,
  farms_active: farmsActive,
  staking,
  staking_active: stakingActive,
  ido,
  ido_active: idoActive,
};
