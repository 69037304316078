import React, { FC, useContext } from 'react';
import { CoinLFI } from '@/composed/coin/lfi';
import { Context } from '@/store/context';
import { ConnectNetwork } from '../network';
import { ConnectSubmit } from '../submit';
import { ConnectUser } from '../user';
import { TProps } from './types';
import { CoinWrapper, Grid, NetworkWrapper, Row, UserWrapper } from './styles';

export const ConnectGrid: FC<TProps> = ({ isAppearAnimation }) => {
  const { authorized } = useContext(Context);

  return (
    <Grid>
      <Row>
        <CoinWrapper>
          <CoinLFI isAppearAnimation={isAppearAnimation} />
        </CoinWrapper>
        <NetworkWrapper>
          <ConnectNetwork isAppearAnimation={isAppearAnimation} />
        </NetworkWrapper>
      </Row>
      <UserWrapper>
        {authorized ? (
          <ConnectUser isAppearAnimation={isAppearAnimation} />
        ) : (
          <ConnectSubmit isAppearAnimation={isAppearAnimation} />
        )}
      </UserWrapper>
    </Grid>
  );
};
