import React, { FC } from 'react';
import { TabButtons } from '@/general/tab/buttons';
import styles from './styles.module.scss';
import { IProps } from './types';

export const AsideTabButtons: FC<IProps> = ({
  selectedKey,
  tablist,
  onSelect,
  ...props
}) => (
  <TabButtons
    styles={styles}
    items={tablist}
    selectedKey={selectedKey}
    onSelect={onSelect as any}
    {...props}
  />
);
