import styled from 'styled-components';
import { ButtonSvgCircleFill } from '@/general/button/svg-circle-fill';

export const Button = styled(ButtonSvgCircleFill).attrs({
  size: 'medium',
})`
  font-size: 15px;
  padding-left: 40px;
  padding-right: 40px;
`;
