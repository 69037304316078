import gsap from 'gsap';
import React, { FC, useEffect, useState } from 'react';
import { toNumber } from '@/utils/number';
import { NumberFormat } from '../format/index';
import { Container, DynamicWrapper, StaticWrapper } from './styles';
import { IProps } from './types';

/**
 * Formatted and animated number
 */
export const NumberCounter: FC<IProps> = ({
  value,
  animation,
  animationDuration = 1250,
  ...formatOptions
}) => {
  const [dynamicRef, setDynamicRef] = useState<HTMLSpanElement | null>(null);
  const [dynamicValue, setDynamicValue] = useState(0);

  const rightFixed =
    formatOptions.rightFixed ?? `${value}`.split('.')[1]?.length;

  useEffect(() => {
    if (!animation || !dynamicRef) {
      setDynamicValue(0);
      return undefined;
    }
    const startValue = toNumber(dynamicRef.innerHTML);
    const endValue = value;

    const timeline = gsap.timeline({
      duration: animationDuration / 1000,
      onUpdate: () => {
        const progress = timeline.progress();
        const current = gsap.utils.interpolate(startValue, endValue, progress);
        setDynamicValue(current);
      },
    });
    return () => {
      timeline.kill();
    };
  }, [animation, animationDuration, dynamicRef, value]);

  return (
    <Container>
      <StaticWrapper>
        <NumberFormat
          value={value}
          {...formatOptions}
          rightFixed={rightFixed}
        />
      </StaticWrapper>
      <DynamicWrapper ref={setDynamicRef} aria-hidden>
        <NumberFormat
          value={dynamicValue}
          {...formatOptions}
          rightFixed={rightFixed}
        />
      </DynamicWrapper>
    </Container>
  );
};
