import styled from 'styled-components';
import { resetScrollBars } from '@/utils/styles/resetScrollBars';
import { ButtonSvgCircleFill } from '@/general/button/svg-circle-fill';
import { resetButtonStyles } from '@/utils/styles/resetButtonStyles';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { AsideTabButtons } from '../tab-buttons';
import closeIcon from './close.svg';

export const Popup = styled.div`
  position: sticky;
  z-index: 99;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--shrink-height);
  overflow: hidden;

  --shrink-height: 120px;
  --expand-height: calc(100 * var(--vh) - var(--top-height));
  --expand-duration: 0.5s;
`;

export const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  height: var(--shrink-height);
  box-sizing: border-box;
  border-radius: 20px 20px 0 0;

  background: linear-gradient(
    to top,
    rgba(var(--color-black--rgb), 1) 30%,
    rgba(var(--color-black--rgb), 0.25)
  );
  border: 1px solid var(--color-grey-800);
  border-bottom: 0;

  @media ${getMediaQuery('smMin')} {
    padding: 0 8px;
  }
  @media ${getMediaQuery('xs')} {
    padding: 0 4px;
  }
`;

export const HeadThumb = styled.button`
  ${resetButtonStyles}
  display: block;
  position: absolute;
  top: 14px;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: #4a4b4c;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: -20px;
    left: -40px;
    right: -40px;
    bottom: -20px;
  }
`;

export const HeadButton = styled(ButtonSvgCircleFill).attrs({
  size: 'large',
  borderRadius: 'arc',
})`
  width: 50%;
  font-weight: 600;
  font-size: 14px;

  @media ${getMediaQuery('smMin')} {
    margin: 0 16px;
  }
  @media ${getMediaQuery('xs')} {
    margin: 0 8px;
  }
`;

export const Body = styled.div`
  ${resetScrollBars}
  position: relative;
  background-color: var(--color-black);
  overflow: auto;
  height: var(--expand-height);
  top: 0;

  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
  box-sizing: border-box;

  @media ${getMediaQuery('xs')} {
    padding: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;

  > * {
    width: 100%;
  }

  @media ${getMediaQuery('smMin')} {
    background-color: var(--color-grey-1250);
    border-radius: 8px;
  }
`;

export const ContainerNav = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px 40px 20px 24px;

  @media ${getMediaQuery('xs')} {
    padding: 12px;
  }
`;

export const NavButtons = styled(AsideTabButtons)`
  > * {
    @media ${getMediaQuery('smMin')} {
      min-width: 170px;
    }
    @media ${getMediaQuery('xs')} {
      min-width: 100px;
    }
  }
`;

export const NavClose = styled.button`
  ${resetButtonStyles}
  width: 40px;
  height: 40px;
  border-radius: 8px;

  background-color: var(--color-grey-850);
  background-image: url('${closeIcon}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 22px auto;

  transition: background-color 0.2s;
  &:hover {
    background-color: var(--color-grey-700);
  }
`;

export const ContainerBody = styled.div`
  height: 100%;
  border-radius: 8px;
  background-color: var(--color-grey-1000);
  box-sizing: border-box;

  @media ${getMediaQuery('smMin')} {
    padding: 20px 24px 32px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  @media ${getMediaQuery('xs')} {
    padding: 18px 12px 32px;
  }
`;
