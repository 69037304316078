import styled, { css } from 'styled-components';
import { resetButtonStyles } from '@/utils/styles/resetButtonStyles';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { resetScrollBars } from '@/utils/styles/resetScrollBars';
import { TSharedProps } from './types';
import closeIcon from './close.svg';

export const Modal = styled.div<TSharedProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(var(--color-black--rgb), 0.9);

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;

  ${({ isShown }) =>
    isShown &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export const ScrollContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ${resetScrollBars}
`;

export const Container = styled.div<TSharedProps>`
  position: relative;
  z-index: 0;
  background-color: var(--color-grey-1100);
  max-width: 77.3%;

  transform: translate(-110%, 0);
  transition: transform 0.5s;

  ${({ isShown }) =>
    isShown &&
    css`
      transform: translate(0, 0);
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: calc(100 * var(--vh));
  padding: calc(8.9 * var(--vh)) 0 calc(10 * var(--vh));
  box-sizing: border-box;
`;

export const LinksWrapper = styled.div`
  box-sizing: border-box;
  padding-right: 24px;
  width: 100%;
`;

export const ChildrenWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  padding: 0 24px 0 40px;
  box-sizing: border-box;

  @media ${getMediaQuery('xs')} {
    @media (max-width: 360px) {
      padding-left: 30px;
    }
  }

  > * {
    + * {
      margin-top: 12px;
    }
  }
`;

export const CloseButton = styled.button`
  ${resetButtonStyles}

  position: absolute;
  z-index: 1;
  top: 6px;
  right: 18px;
  width: 48px;
  height: 48px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  background-image: url('${closeIcon}');
`;
