import React, { forwardRef } from 'react';
import { Image, Label } from './styles';
import { IProps } from './types';

export const PreviewNetworkLabel = forwardRef<HTMLDivElement, IProps>(
  ({ name, img, color, size = 'medium', ...tagProps }, ref) => (
    <Label
      ref={ref}
      size={size}
      {...tagProps}
      style={{
        ...tagProps.style,
        backgroundColor: color,
      }}
      aria-label="Network"
    >
      {img && <Image size={size} src={img} alt={name} aria-hidden />}
      <span>{name}</span>
    </Label>
  )
);
