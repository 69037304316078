import React, { FC } from 'react';
import { PreviewNetworkLabel } from '@/general/preview/network-label';
import { PoolApy } from '../Apy';
import {
  APYContainer,
  APYLabel,
  CoinContainer,
  CoinContent,
  CoinImg,
  CoinName,
  Container,
} from './styles';
import { IPoolFormHeaderProps } from './types';

export const PoolFormHeader: FC<IPoolFormHeaderProps> = ({
  img,
  name,
  network,
  apy,
}) => (
  <Container>
    <CoinContainer>
      {img && <CoinImg src={img} alt={name} />}
      <CoinContent>
        <CoinName>{name}</CoinName>
        {network && <PreviewNetworkLabel {...network} size="small" />}
      </CoinContent>
    </CoinContainer>

    {apy && (
      <APYContainer>
        <APYLabel>APY</APYLabel>
        <PoolApy {...apy} />
      </APYContainer>
    )}
  </Container>
);
