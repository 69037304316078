import React, { forwardRef } from 'react';
import { Container } from './styles';
import { IProps } from './types';

export const LayoutWrapper = forwardRef<HTMLDivElement, IProps>(
  ({ children, ...props }, ref) => (
    <Container ref={ref} {...props}>
      {children}
    </Container>
  )
);
LayoutWrapper.displayName = 'LayoutWrapper';
