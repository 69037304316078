import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { DraggerMove, utils } from 'vevet';
import gsap from 'gsap';
import { IProps } from './types';
import {
  Body,
  Container,
  ContainerBody,
  ContainerNav,
  Head,
  HeadButton,
  HeadThumb,
  NavButtons,
  NavClose,
  Popup,
} from './styles';

export const AsidePopup: FC<PropsWithChildren<IProps>> = ({
  contentBelowRef,
  tabs,
  children,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const headRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLButtonElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  const animateProgress = useRef(0);
  const timelineRef = useRef<gsap.core.Tween | null>(null);

  /**
   * Get drag progress
   */
  const getMoveProgress = useCallback((dragger: DraggerMove) => {
    const headHeight = headRef.current?.offsetHeight || 0;
    const bodyHeight = bodyRef.current?.offsetHeight || 0;
    return (
      -(dragger.coords.y - dragger.startCoords.y) / (bodyHeight - headHeight)
    );
  }, []);

  /**
   * Render drag
   */
  const render = useCallback(
    (progress: number) => {
      const parent = parentRef.current;
      const head = headRef.current;
      const body = bodyRef.current;
      const contentBelow = contentBelowRef.current;
      if (!parent || !head || !body) {
        return;
      }
      parent.style.height = `calc(var(--shrink-height) + (var(--expand-height) - var(--shrink-height)) * ${progress} + 2px)`;
      head.style.opacity = (
        1 - utils.math.clampScope(progress, [0.8, 1])
      ).toString();
      body.style.top = `calc(var(--shrink-height) * ${progress} * -1)`;
      if (contentBelow) {
        contentBelow.style.opacity = `${1 - progress}`;
      }
    },
    [contentBelowRef]
  );

  // animate progress
  useEffect(() => {
    render(animateProgress.current);
  }, [render]);

  /**
   * Animate render
   */
  const animate = useCallback(
    (progress: number, duration = 150) => {
      timelineRef.current = gsap.to(animateProgress, {
        current: progress,
        duration: duration / 1000,
        onUpdate: () => render(animateProgress.current),
      });
    },
    [render]
  );

  // reset on unmount
  useEffect(
    () => () => {
      timelineRef.current?.kill();
      const contentBelow = contentBelowRef.current;
      if (contentBelow) {
        contentBelow.style.opacity = '';
      }
    },
    [contentBelowRef]
  );

  /**
   * Show the popup
   */
  const expand = useCallback(() => {
    animate(1, 350);
  }, [animate]);

  /**
   * Hide the popup
   */
  const shrink = useCallback(() => {
    animate(0, 350);
  }, [animate]);

  // add thumb drag
  useEffect(() => {
    if (!thumbRef.current) {
      return undefined;
    }
    const dragger = new DraggerMove({
      container: thumbRef.current,
    });
    dragger.addCallback('start', () => {
      document.documentElement.classList.add('disable-select');
    });
    dragger.addCallback('move', (data) => {
      data.evt.preventDefault();
      data.evt.stopPropagation();
      const progress = getMoveProgress(dragger);
      animate(progress);
    });
    dragger.addCallback('end', () => {
      document.documentElement.classList.remove('disable-select');
      const progress = getMoveProgress(dragger);
      if (progress > 0.5) {
        expand();
      } else {
        shrink();
      }
    });
    return () => {
      dragger.destroy();
    };
  }, [animate, getMoveProgress, expand, shrink]);

  return (
    <Popup ref={parentRef}>
      <Head ref={headRef}>
        <HeadThumb
          ref={thumbRef}
          type="button"
          aria-label="Expand"
          onClick={() => expand()}
        />
        {tabs.tablist.map((tab, index) => (
          <HeadButton
            key={tab.key}
            tag="button"
            type="button"
            variant={index % 2 === 0 ? 'primary' : 'gradient'}
            hasStaticFill={index % 2 === 0}
            onClick={() => {
              tabs.onSelect(tab.key);
              expand();
            }}
          >
            {tab.name}
          </HeadButton>
        ))}
      </Head>

      <Body ref={bodyRef}>
        <Container>
          <ContainerNav>
            <NavButtons {...tabs} />
            <NavClose
              type="button"
              aria-label="close"
              onClick={() => {
                shrink();
              }}
            />
          </ContainerNav>
          <ContainerBody>{children}</ContainerBody>
        </Container>
      </Body>
    </Popup>
  );
};
