import React, { FC, useRef, useState } from 'react';
import { useOnElementResize } from '@/utils/resize';
import {
  ColumnNav,
  ColumnApy,
  ColumnDeposit,
  ColumnLiquidity,
  ColumnMyLiquidity,
  ColumnPool,
} from './global';
import { PoolsListItem } from './item/Item';
import { Container, Head, ColumnPoolText } from './styles';
import { IPoolListProps } from './types';

export const PoolsList: FC<IPoolListProps> = ({ items }) => {
  const [width, setWidth] = useState(0);

  const rowRef = useRef<HTMLDivElement>(null);
  useOnElementResize(
    rowRef,
    () => {
      if (rowRef.current) {
        setWidth(rowRef.current.clientWidth);
      }
    },
    []
  );

  return (
    <Container
      style={{
        // @ts-ignore
        '--row-width': `${width}px`,
      }}
    >
      <Head ref={rowRef} aria-hidden>
        <ColumnPool>
          <ColumnPoolText>Pool</ColumnPoolText>
        </ColumnPool>
        <ColumnMyLiquidity>My Liquidity</ColumnMyLiquidity>
        <ColumnLiquidity>Liquidity</ColumnLiquidity>
        <ColumnApy>APY</ColumnApy>
        <ColumnDeposit />
        <ColumnNav />
      </Head>

      {items.map((item) => (
        <PoolsListItem {...item} key={item.key} />
      ))}
    </Container>
  );
};
